import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import {
    useRefresh,
    useDataProvider,
    useNotify,
    useUpdate,
    useRecordContext,
} from 'ra-core';
import { Button } from 'ra-ui-materialui';
import { FieldProps, RaRecord } from 'react-admin';

export const MatchPlayerTransportNeededIcon: React.FC<{
    value: string;
    source: string;
}> = ({ value, source }) => {
    const classes = {
        green: {
            0: { color: 'grey' },
            1: { color: '#006534' },
        },
    };

    return <AirportShuttleIcon sx={classes.green[value]} />;
};

type MatchPlayerTransportNeededFieldProps = {
    match?: RaRecord;
};

export const MatchPlayerTransportNeedField: React.FC<
    FieldProps & MatchPlayerTransportNeededFieldProps
> = ({ source, match }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    const [update] = useUpdate();
    const [submitting, setSubmitting] = useState(false);
    const dataProvider = useDataProvider();
    const handleSubmit = useCallback(
        async () => {
            setSubmitting(true);
            const transport_needed = record.transport_needed == 1 ? 0 : 1;
            const newValues = {
                transport_needed: transport_needed,
            };
            console.log('transport_needed', { record, newValues });
            update('matches_players', {
                id: record.id,
                data: newValues,
                previousData: record,
            });
            refresh();
            setSubmitting(false);
            notify(
                'resources.matches.notification.match_player_status_update',
                {
                    type: 'info',
                    undoable: false,
                }
            );
        },
        [dataProvider, record, source] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return (
        <Button onClick={handleSubmit} disabled={submitting}>
            <MatchPlayerTransportNeededIcon
                value={get(record, source)}
                source={source}
            />
        </Button>
    );
};

MatchPlayerTransportNeedField.propTypes = {
    match: PropTypes.any,
};
