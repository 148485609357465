import moment from "moment";
import React, { useCallback } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  DateInput,
  ReferenceInput,
  SelectInput,
  RaRecord,
  BooleanInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";
import { LinkedTeamInput } from "../../components/inputs/LinkedTeamInput";
import { choiceVenues } from "../../types";

export const MatchCreate: React.FC = (props) => {
  const transform = useCallback((data: RaRecord) => {
    data.isHome = data.isHome ? 1 : 0;
    delete data.club_id;
    delete data.venueChoice;
    return {
      ...data,
      source: "web",
      date: moment(
        `${data.date} ${data.matchTime}`,
        "YYYY-MM-DD HH:mm"
      ).toDate(),
      matchDate: moment(data.date).format("DD.MM.YYYY"),
    };
  }, []);
  return (
    <Create transform={transform} redirect="list" {...props}>
      <SimpleForm>
        <ReferenceInput
          label="resources.matches.fields.team_id"
          source="team_id"
          reference="teams"
          filter={{ currentSeason: true }}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="resources.matches.fields.opponent"
          source="club_id"
          reference="clubs"
          filterToQuery={(searchText: any) => ({ q: searchText })}
          perPage={5}
        >
          <AutocompleteInput
            fullWidth
            noOptionsText="-"
            shouldRenderSuggestions={(val: any) => {
              return val.trim().length > 2;
            }}
            optionText="name"
          />
        </ReferenceInput>
        <DateInput label="resources.matches.fields.date" source="date" />
        <TextInput
          label="resources.matches.fields.matchTime"
          source="matchTime"
        />
        <BooleanInput label="resources.matches.fields.isHome" source="isHome" />
        <NumberInput
          label="resources.matches.fields.gameTime"
          source="gameTime"
        />
        <SelectInput
          label="resources.matches.fields.venue"
          source="venueChoice"
          choices={choiceVenues}
        />
        <LinkedTeamInput source="team" />
      </SimpleForm>
    </Create>
  );
};
