import { Card, CardContent } from '@mui/material';
import { useGetIdentity, useShowController } from 'react-admin';

export const CoachShow = (props: any) => {
  const { record } = useShowController(props);
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if(isLoading || !allowedAccess || !record || !record.comments) return null;
  return (
    <Card sx={{ width: 'auto', margin: 'auto' }}>
      <CardContent>
        {record.comments.split('\n').map((item, idx) => {
          return (
            <span key={idx}>
              {item}
              <br />
            </span>
          );
        })}
      </CardContent>
    </Card>
  );
};
