import { get } from "lodash";
import React from "react";
import { ChipField, useRecordContext, useTranslate } from "react-admin";

export const WeekdayField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const translate = useTranslate();
  const wd = get(record, source);
  if (wd) {
    const weekdays = wd.map(w => {
      return { id: w, name: translate(`resources.practices.daysOfWeek.${w}`) };
    });
    return weekdays.map(record => <ChipField key={record.id} source="name" record={record} />);
  }
  return null;
};
