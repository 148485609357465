import vCardsJS from 'vcards-js';

export default (contact, type) => {
  // console.log('contactData', contact );
  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  var vCard = vCardsJS();
  // vCard.version = '4.0'
  vCard.uid = contact.id;
  if (contact.birthdate && type === 'player') {
    const [year, month, day] = contact.birthdate.split('-');
    vCard.birthday = new Date(year, month - 1, day);
  }
  const lastName = contact.name.substring(contact.name.lastIndexOf(' ') + 1);
  vCard.lastName = lastName;
  if (!type || type === 'player') {
    vCard.firstName = contact.name.replace(lastName, '');
    if (contact.mobile) {
      vCard.cellPhone = contact.mobile;
    }
    if (contact.photo && contact.photo.src) {
      // vCard.photo.attachFromUrl(contact.photo.src, 'PNG');
      toDataURL(contact.photo.src).then(dataUrl => {
        vCard.photo.embedFromString(dataUrl, 'image/png');
      });
    }
    vCard.gender = 'M';
  } else if (type === 'father') {
    vCard.firstName = contact.fatherName ? contact.fatherName : 'Vater';
    if (contact.fatherMobile) {
      vCard.cellPhone = contact.fatherMobile;
    }
    vCard.gender = 'M';
    vCard.title = `Vater von ${contact.name}`;
  } else if (type === 'mother') {
    vCard.firstName = contact.motherName ? contact.motherName : 'Mutter';
    if (contact.motherMobile) {
      vCard.cellPhone = contact.motherMobile;
    }
    vCard.gender = 'F';
    vCard.title = `Mutter von ${contact.name}`;
  }
  if (contact.adress) {
    vCard.homeAddress.street = contact.adress;
  }
  if (contact.city) {
    vCard.homeAddress.city = contact.city;
  }
  if (contact.postal) {
    vCard.homeAddress.postalCode = contact.postal;
  }
  if (contact.phone) {
    vCard.homePhone = contact.phone;
  }
  return vCard.getFormattedString();
};
