import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, useUpdateMany, useNotify, useRefresh, useUnselectAll } from 'react-admin';

export const BulkApplicationWorkedOn: React.FC<{
  selectedIds: any;
}> = ({ selectedIds }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll('applications');
  const [updateMany, { isLoading }] = useUpdateMany();
  const handleOnClick = () => {
    console.log('handleOnClick', {
      selectedIds
    }) 
    updateMany(
    'applications', {
      ids: selectedIds,
      data: {
        workedOn: true,
      },
    },
    {
      mutationMode: "pessimistic",
      onSettled: () => {
        unselectAll();
        notify(`resources.applications.notification.worked_on`);
        refresh();
      },
      onError: () => {
        notify("resources.applications.notification.error", {
          type: "warning",
        });
      },
    });
  }

  return (
    <Button label="resources.applications.actions.worked_on" onClick={handleOnClick} disabled={isLoading}>
      <CheckCircleOutlineIcon />
    </Button>
  );
};

BulkApplicationWorkedOn.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};
