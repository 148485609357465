import { defaultTheme } from "react-admin";

export default {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiChip: {
      styleOverrides: {
        root: {
          margin: 0
        },
        filled: {
          color: "#FFF",
          background: '#00945e'
        },
      },
    },
    RaSingleFieldList: {
      styleOverrides: {
        root: {
            flexWrap: "nowrap",
            gap: 0
        },
      }
    }, 
    RaMultiLevelMenu: {
      root: {
        width: "none",
      },
    },
  },
  sidebar: {
    width: 180, // The default value is 240
    closedWidth: 55, // The default value is 55
  },
  overrides: {
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#00945e",
      contrastText: "#ffffff",
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
};
