import {
    Datagrid,
    IfCanAccess,
    List,
    ListActions,
    useAuthenticated,
} from '@s-graber/ra-rbac';
import React from 'react';
import {
    BulkDeleteButton,
    EditButton,
    Filter,
    TextField,
    TextInput,
    DateField,
} from 'react-admin';

const ClubFilter: React.FunctionComponent = props => (
    <Filter {...props}>
        <TextInput
            label="resources.clubs.search_name"
            source="name@ilike"
            alwaysOn
        />
    </Filter>
);

const BulkActionButtons = props => {
    return (
        <IfCanAccess action="delete">
            <BulkDeleteButton {...props} />
        </IfCanAccess>
    );
};

export const ClubList: React.FC = () => {
    useAuthenticated();
    return (
        <List
            // disableAuthentication
            actions={<ListActions />}
            bulkActionButtons={<BulkActionButtons />}
            filters={<ClubFilter />}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <Datagrid>
                <TextField source="name" />
                {/* <DateField source="createdate" label="pos.fields.createdate" />
        <TextField source="createdby" label="pos.fields.createdby" /> */}
                <DateField source="lastupdate" label="pos.fields.lastupdate" />
                <TextField source="updatedby" label="pos.fields.updatedby" />
                <EditButton />
            </Datagrid>
        </List>
    );
};
