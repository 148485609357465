import React from "react";
import {
  Filter,
  Datagrid,
  EditButton,
  List,
  TextField,
  usePermissions,
  DateField,
  ReferenceField,
  SearchInput,
} from "react-admin";
import { ReferenceInput, SelectInput } from "ra-ui-materialui";
import { choicesDaysOfWeek, sanitizeRestListProps } from "../../types";
import { WeekdayField } from "../../components/fields/WeekdayField";

export const PracticeFilter: React.FC = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <ReferenceInput
        label="resources.squadplayers.fields.squad"
        source="team_id"
        reference="teams"
        sort={{ field: "name", order: "ASC" }}
        filter={{ currentSeason: true }}
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export const PracticeList = (props) => {
  const { isLoading } = usePermissions();
  if (isLoading) return null;
  return (
    <List
      {...props}
      filters={<PracticeFilter />}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid rowClick="edit" {...sanitizeRestListProps(props)}>
        <TextField label="resources.practices.fields.name" source="name" />
        <ReferenceField source="team_id" reference="teams">
          <TextField label="resources.practices.fields.team_id" source="name" />
        </ReferenceField>
        <WeekdayField
          label="resources.practices.fields.daysOfWeek"
          source="daysOfWeek"
          choices={choicesDaysOfWeek}
        />
        <TextField
          label="resources.practices.fields.startTime"
          source="startTime"
        />
        <TextField
          label="resources.practices.fields.endTime"
          source="endTime"
        />
        <DateField
          label="resources.practices.fields.startRecur"
          source="startRecur"
          options={{ year: "numeric", month: "long", day: "numeric" }}
        />
        <DateField
          label="resources.practices.fields.endRecur"
          source="endRecur"
          options={{ year: "numeric", month: "long", day: "numeric" }}
        />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
