import { get } from "lodash";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  DateInput,
  TextInput,
  Datagrid,
  FormTab,
  ReferenceManyField,
  TabbedForm,
  TextField,
  DateField,
  DeleteButton,
  FormDataConsumer,
  Pagination,
  FunctionField,
  linkToRecord,
  Link,
  Toolbar,
  SaveButton,
  ToolbarProps,
  useNotify,
  usePermissions,
  useUpdate,
  Confirm,
  useDataProvider,
  useTranslate,
  useGetRecordId,
  useRecordContext,
  ReferenceField,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { LinkedNameFromPracticeTypeInput } from "../../components/inputs/LinkedNameFromPracticeTypeInput";
import {
  choicesDaysOfWeek,
  choicesPracticeTypes,
  choiceVenues,
  choiceShareOfSpace,
  sanitizeRestListProps,
  validateTime,
} from "../../types";
import { AddTrainingsButton } from "./AddTrainingsButton";
import { DeleteTrainingsButton } from "./DeleteTrainingsButton";

const PracticeEditToolbar: React.FC<ToolbarProps> = ({}) => {
  const { isLoading } = usePermissions();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [linkedTrainings, setLinkedTrainings] = useState({
    ids: [],
    data: [],
    count: 0,
  });
  const [update] = useUpdate();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const translate = useTranslate();
  const today = new Date().toISOString();
  const record = useRecordContext();
  const id = useGetRecordId();
  const dataProvider = useDataProvider();
  const getData = useCallback(
    async (isSubscribed) => {
      const { data, total } = (await dataProvider.getManyReference(
        "trainings",
        {
          target: "practice_id",
          id: id,
          pagination: { page: 1, perPage: 100 },
          sort: { field: "date", order: "DESC" },
          filter: { "date@gte": today },
        }
      )) || { data: [], total: 0 };
      const ids = data.map((record) => record.id);
      if (isSubscribed) setLinkedTrainings({ ids, data, count: total });
    },
    [dataProvider, id] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(() => {
    let isSubscribed = true;
    if (id) {
      getData(isSubscribed);
    }
    return () => {
      isSubscribed = false;
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDialogClose = (e) => {
    setConfirmOpen(false);
    e.stopPropagation();
  };

  const handleConfirm = useCallback(
    (event) => {
      event.stopPropagation();
      const updates = linkedTrainings.data.map((training) =>
        update(
          "trainings",
          {
            id: training.id,
            data: {
              date: moment(
                `${moment(training.date).format("YYYY-MM-DD")} ${
                  record.startTime
                }`,
                "YYYY-MM-DD HH:mm"
              ),
              meetingTime: record.meetingTime,
              startTime: record.startTime,
              endTime: record.endTime,
            },
            previousData: record,
          },
          {
            mutationMode: "optimistic",
          }
        )
      );
      Promise.all(updates);
      setConfirmOpen(false);
      notify("ra.notification.updated", {
        type: "info",
        messageArgs: { smart_count: linkedTrainings.count },
      });
    },
    [record, linkedTrainings, update, notify]
  );

  const handleSave = useCallback(
    (e) => {
      const { id, ...values } = getValues();
      update("practices", {
        id,
        data: {
          type: values.type,
          name: values.name,
          meetingTime: values.meetingTime,
          startTime: values.startTime,
          endTime: values.endTime,
          startRecur: values.startRecur,
          endRecur: values.endRecur,
          daysOfWeek: values.daysOfWeek,
        },
      });
      if (
        values.meetingTime !== record.meetingTime ||
        values.startTime !== record.startTime ||
        values.endTime !== record.endTime
      ) {
        setConfirmOpen(true);
      } else {
        notify("ra.notification.updated", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
      }
    },
    [notify, update, record]
  );

  if (!record || isLoading) return null;
  return (
    <Toolbar
      style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
    >
      <Fragment>
        <SaveButton type="button" onClick={handleSave} />
        <Confirm
          isOpen={confirmOpen}
          // loading={loading}
          title="resources.practices.notification.time_update_title"
          content={translate(
            "resources.practices.notification.time_update_content",
            {
              count: linkedTrainings.count,
            }
          )}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
        <DeleteButton mutationMode="pessimistic" />
      </Fragment>
    </Toolbar>
  );
};

export const PracticeEdit = (props) => {
  const today = new Date().toISOString();
  const { pathname } = useLocation();
  return (
    <Edit title="resources.practices.actions.edit" {...props}>
      <TabbedForm
        toolbar={<PracticeEditToolbar />}
        {...sanitizeRestListProps(props)}
      >
        <FormTab label="resources.practices.tabs.details">
          <ReferenceInput
            label="resources.practices.fields.team_id"
            source="team_id"
            reference="teams"
            filter={{ currentSeason: true }}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            label="resources.practices.fields.type"
            source="type"
            choices={choicesPracticeTypes}
          />
          <LinkedNameFromPracticeTypeInput
            label="resources.practices.fields.name"
            source="name"
            {...sanitizeRestListProps(props)}
          />
          <TextInput
            label="resources.practices.fields.meetingTime"
            source="meetingTime"
            validate={validateTime}
          />
          <TextInput
            label="resources.practices.fields.startTime"
            source="startTime"
            validate={validateTime}
          />
          <TextInput
            label="resources.practices.fields.endTime"
            source="endTime"
            validate={validateTime}
          />
          <DateInput
            label="resources.practices.fields.startRecur"
            source="startRecur"
          />
          <DateInput
            label="resources.practices.fields.endRecur"
            source="endRecur"
          />
          <SelectArrayInput
            label="resources.practices.fields.daysOfWeek"
            source="daysOfWeek"
            choices={choicesDaysOfWeek}
          />
          <SelectInput
            label="resources.practices.fields.venue"
            source="venue"
            choices={choiceVenues}
          />
          <SelectInput
            label="resources.practices.fields.shareOfSpace"
            source="shareOfSpace"
            choices={choiceShareOfSpace}
          />
        </FormTab>
        <FormTab label="resources.practices.tabs.upcoming" path="upcoming">
          <ReferenceManyField
            sort={{ field: "date", order: "ASC" }}
            filter={{ "date@gte": today }}
            perPage={10}
            pagination={<Pagination />}
            reference="trainings"
            source="id"
            target="practice_id"
          >
            <Datagrid>
              <FunctionField
                render={(record: any) =>
                  record ? (
                    <Link
                      to={`${linkToRecord("/trainings", get(record, "id"))}`}
                    >
                      {get(record, "name")}
                    </Link>
                  ) : (
                    ""
                  )
                }
              />
              <DateField
                label="resources.trainings.fields.date"
                source="date"
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }}
              />
              <TextField
                label="resources.trainings.fields.meetingTime"
                source="meetingTime"
              />
              <TextField
                label="resources.trainings.fields.startTime"
                source="startTime"
              />
              <TextField
                label="resources.trainings.fields.endTime"
                source="endTime"
              />
              <DeleteButton label="" redirect={pathname} />
            </Datagrid>
          </ReferenceManyField>
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }) =>
              formData && (
                <Fragment>
                  <AddTrainingsButton record={formData} {...props} />
                  <DeleteTrainingsButton
                    type="upcoming"
                    record={formData}
                    {...props}
                  />
                </Fragment>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="resources.practices.tabs.past" path="past">
          <ReferenceManyField
            sort={{ field: "date", order: "DESC" }}
            perPage={10}
            filter={{ "date@lt": today }}
            pagination={<Pagination />}
            reference="trainings"
            source="id"
            target="practice_id"
          >
            <Datagrid>
              <FunctionField
                label="resources.trainings.fields.name"
                source="name"
                render={(record: any) =>
                  record ? (
                    <Link
                      to={`${linkToRecord("/trainings", get(record, "id"))}`}
                    >
                      {get(record, "name")}
                    </Link>
                  ) : (
                    ""
                  )
                }
              />
              <DateField
                label="resources.trainings.fields.date"
                source="date"
                options={{
                  weekday: "long",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }}
              />
              <TextField
                label="resources.trainings.fields.meetingTime"
                source="meetingTime"
              />
              <TextField
                label="resources.trainings.fields.startTime"
                source="startTime"
              />
              <TextField
                label="resources.trainings.fields.endTime"
                source="endTime"
              />
              <DeleteButton label="" redirect={pathname} />
            </Datagrid>
          </ReferenceManyField>
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }) =>
              formData && (
                <Fragment>
                  <AddTrainingsButton record={formData} {...props} />
                  <DeleteTrainingsButton
                    type="past"
                    record={formData}
                    {...props}
                  />
                </Fragment>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
