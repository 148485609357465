import { get } from "lodash";
import { useCallback, useEffect } from "react";
import {
  TextInput,
  sanitizeInputRestProps,
  TextInputProps,
  useDataProvider,
} from "react-admin";
import { useFormContext } from "react-hook-form";

const LinkedTimeInput = ({ source, label, ...rest }: TextInputProps) => {
  const { getValues } = useFormContext();
  const { practice_id, ...values } = getValues();

  const dataProvider = useDataProvider();
  const getData = useCallback(
    async () => {
      const { data } = (await dataProvider.getOne("practices", {
        id: practice_id,
      })) || { data: null };
      const name = `${get(data, source)}`;
      values[source] = name;
    },
    [dataProvider, practice_id] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(() => {
    if (practice_id) {
      getData();
    }
  }, [practice_id]); // eslint-disable-line react-hooks/exhaustive-deps
  // if (!practice_id) return null;
  return (
    <TextInput
      source={source}
      label={label}
      {...sanitizeInputRestProps(rest)}
    />
  );
};

export default LinkedTimeInput;
