import React from 'react';
import {
  DateField,
  Datagrid,
  List,
  Filter,
  TextInput,
  FunctionField,
  ReferenceField,
  useTranslate,
  DeleteButton,
  TextField,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { FullNameField } from '../../components/fields/FullNameField';
import { TypeField } from '../../components/fields/TypeField';

const AbsenceFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput label="resources.absences.fields.player" source="q" alwaysOn />
  </Filter>
);

export const AbsenceList: React.FC = props => {
  const { pathname } = useLocation();
  const translate = useTranslate();
  return (
    <List {...props} filters={<AbsenceFilter />} sort={{ field: 'startDate', order: 'DESC' }}>
      <Datagrid>
        <ReferenceField label="resources.absences.fields.name" source="player_id" reference="players" link={false}>
          <FullNameField source="name" playerid="id" sourceRating="rating" sourceAvatar="photo.src" />
        </ReferenceField>
        <DateField label="resources.absences.fields.startDate" source="startDate" />
        <DateField label="resources.absences.fields.endDate" source="endDate" />
        <TypeField label="resources.absences.fields.type" source="type" resource='absences' />
        <FunctionField
          label="resources.absences.fields.type"
          source="type"
          render={(record: any) => (record.type ? translate(`resources.absences.type.${record.type}`) : '-')}
        />
        <TextField label="resources.absences.fields.reason" source="reason" />
        {/* <EditButton /> */}
        <DeleteButton label="" redirect={pathname} />
      </Datagrid>
    </List>
  );
};
