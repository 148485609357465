import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import DeleteSquadplayersIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router";
import {
  useTranslate,
  useNotify,
  useDataProvider,
  useRefresh,
} from "react-admin";

export const DeleteMatchPlayersButton: React.FC<{
  record: any;
}> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const [, , match_id] = location.pathname.split("/");
  const dataProvider = useDataProvider();
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const { data: matches_players, total } = await dataProvider.getList(
      "matches_players",
      {
        pagination: { page: 1, perPage: 25 },
        sort: { field: "name", order: "ASC" },
        filter: {
          match_id: match_id,
        },
      }
    );
    console.log(`found ${total} squadplayers`);
    const ids = matches_players.flatMap((x) => x.id);
    await dataProvider.deleteMany("matches_players", { ids: ids });
    refresh();
    notify("resources.matches.notification.squadplayers_deleted", {
      type: "info",
      undoable: false,
    });
  }, [dataProvider]);

  return (
    <Button onClick={handleSubmit} disabled={submitting}>
      <DeleteSquadplayersIcon />
      {translate("resources.matches.actions.squadplayers_delete")}
    </Button>
  );
};
