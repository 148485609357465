import * as React from 'react';
import { useCallback, useState } from 'react';
import {
    useRecordContext,
    useCreate,
    useNotify,
    useGetIdentity,
    useTranslate,
    useUpdate,
    useGetRecordId,
    RaRecord,
} from 'react-admin';
import { Box, TextField as TextInput, Button } from '@mui/material';
import { TyepSelectorField } from '../../components/fields/TyepSelectorField';
import { resolveNoteTypeAttributes } from '../../types';
import { RatingSelectField } from '../../components/fields/RatingSelectField';

export const NoteCreate = ({
    refetch,
    player,
}: {
    refetch?: any
    player?: RaRecord
}) => {
    const { identity } = useGetIdentity();
    const record = useRecordContext();
    const player_id = useGetRecordId();
    const translate = useTranslate();
    // const location = useLocation();
    // const [, , player_id] = location.pathname.split('/');
    const [date, setDate] = useState(getCurrentDate());
    const [create, { isLoading }] = useCreate();
    const [update] = useUpdate();
    const notify = useNotify();

    const [text, setText] = useState('');
    const [type, setType] = useState('info');
    const [rating, setRating] = useState('');
    const [showRating, setShowRating] = useState(false);
    
    if (!record || !identity) return null;
    const { resource } = resolveNoteTypeAttributes(type);

    const handleSubmit = useCallback((event) => {
        var intRating = Number.parseInt(rating) || 0
        const data: any = {
            player_id,
            comments: text,
            rating: intRating,
        };
        let playerDataForUpdate = {
            lastupdate: new Date().toISOString(),
            updatedby: identity.fullName,
        }
        if(resource === 'scoutings' && rating !== ''){
            data.scoutingReview = intRating;
            data.report = text;
            data.date = new Date(date).toISOString();
            data.type = type;
            delete data.comments;
            delete data.rating;
        }
        else if(resource === 'reviews'){
            data.name = record.name;
            playerDataForUpdate['rating'] = rating;
        } else if(resource === 'treatments') {
            data.date = new Date(date).toISOString();
            delete data.rating;
        } else {
            data.date = new Date(date).toISOString();
            data.type = type;
            delete data.rating;
        }
        console.log('NoteCreate.handleSubmit', {data, type, playerDataForUpdate, rating, intRating})
        update('players', { id: player_id, data: playerDataForUpdate });
        create(
            resource,
            { data },
            {
                onSuccess: () => {
                    setText('');
                    notify('resources.players.actions.add_note');
                    refetch();
                },
            }
        );
        return false;
    }, [type, text, rating]);
    return (
        <Box mt={0} mb={1}>
            <TextInput
                label={translate('resources.players.page.note')}
                variant="filled"
                size="small"
                fullWidth
                multiline
                value={text}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setText(event.target.value)
                }
                rows={3}
            />
            <Box display="flex" justifyContent="space-between" mt={1}>
                {text ? (
                    <>
                        {(
                            <TyepSelectorField
                                type={type}
                                setType={setType}
                                setShowRating={setShowRating}
                                sx={{
                                    marginRight: '1em',
                                    '& .MuiFilledInput-input': {
                                        paddingTop: '10px',
                                    },
                                    width: 150,
                                }}
                            />
                        )}
                        {showRating && (
                            <RatingSelectField
                                type={type}
                                player={player}
                                setRating={setRating}
                                sx={{
                                    marginRight: '1em',
                                    '& .MuiFilledInput-input': {
                                        paddingTop: '10px',
                                    },
                                    width: 150,
                                }}
                            />
                        )}
                        <TextInput
                            type="datetime-local"
                            variant="filled"
                            size="small"
                            value={date}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setDate(event.target.value);
                            }}
                            sx={{
                                marginTop: 0,
                                marginRight: '1em',
                                '& .MuiFilledInput-input': {
                                    paddingTop: '10px',
                                },
                            }}
                        />
                    </>
                ) : null}
                </Box>
                <Box sx={{justifyContent: "flex-end"}} mt={1}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!text || isLoading}
                    >
                        {translate('resources.players.page.add_note')}
                    </Button>
            </Box>
        </Box>
    );
};

const getCurrentDate = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0, -8);
};
