import { Fragment } from 'react';
import { BulkDeleteButton, useGetIdentity } from 'react-admin';
import { BulkConvertApplication } from './BulkConvertApplication';
import { BulkApplicationWorkedOn } from './BulkApplicationWorkedOn';
import { BulkApplicationNotWorkedOn } from './BulkApplicationNotWorkedOn';

const BulkApplicationActionButtons = props => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  const BulkApplicationNotWorkedOnField =
  allowedAccess ? <BulkApplicationNotWorkedOn {...props} /> : null;
  const BulkDeleteButtonFragment = allowedAccess ? <BulkDeleteButton {...props} /> : null;
  if (isLoading) return null;
  return (
    <Fragment>
      <BulkConvertApplication {...props} />
      <BulkApplicationWorkedOn {...props} />
      {BulkApplicationNotWorkedOnField}
      {BulkDeleteButtonFragment}
    </Fragment>
  );
};
export default BulkApplicationActionButtons;
