import React from 'react';
import {
    Filter,
    List,
    ReferenceInput,
    SearchInput,
    SelectInput,
    useNotify,
} from 'react-admin';
import { Calendar } from '@s-graber/ra-calendar';
import { DatesSetArg, EventInput } from '@fullcalendar/react';
import moment from 'moment';
import {
    getMatchDuration,
    getEventColorForSquad,
    choicesCalendarTypes,
    choiceVenues,
    sanitizeRestListProps,
    getEventShort,
    choicesGameType,
} from '../../types';
import deLocale from '@fullcalendar/core/locales/de';

const customGetFilterValues = (
    dateInfo?: DatesSetArg,
    filterValues: any = {}
): any => {
    const lowerBound = moment().subtract(1, 'years').toDate();
    const upperBound = moment().add(1, 'years').toDate();
    return !dateInfo ||
        (dateInfo.start > lowerBound && dateInfo.end < upperBound)
        ? {
              ...filterValues,
              'start@gte': lowerBound.toISOString(),
              'start@lte': upperBound.toISOString(),
          }
        : {
              ...filterValues,
              'start@gte': dateInfo.startStr,
              'start@lte': dateInfo.endStr,
          };
};

const getEventTitle = (event: any) => {
    let [home, away] = event.title ? event.title.split('#') : ['', ''];
    event.short = event.short === null ? 'Profis' : event.short;
    return event.isHome === 1
        ? `H: ${event.short} - ${away}`
        : `A: ${home} - ${event.short}`;
};

const convertToEvent = (event: any): EventInput => {
    return event.source === 'trainings'
        ? {
              id: String(event.id),
              title: getEventShort(event.type, event.short, event.venue),
              start: moment(
                  `${moment(event.start).format('YYYY-MM-DD')} ${
                      event.startTime
                  }`,
                  'YYYY-MM-DD HH:mm'
              ).format('YYYY-MM-DD HH:mm:ss'),
              end: moment(
                  `${moment(event.start).format('YYYY-MM-DD')} ${
                      event.endTime
                  }`,
                  'YYYY-MM-DD hh:mm'
              ).format('YYYY-MM-DD HH:mm:ss'),
              backgroundColor: getEventColorForSquad(event.team_id, event.type),
              borderColor: '#00945e',
              textColor: 'primary',
              editable: false,
              url: `/trainings/${event.id}`,
              overlap: false,
          }
        : {
              id: String(event.id),
              title: getEventTitle(event),
              start: moment(event.start).utc().format('YYYY-MM-DD HH:mm:ss'),
              end: moment(event.start)
                  .add(getMatchDuration(event.teamType) + event.gameTime, 'm')
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'),
              backgroundColor: getEventColorForSquad(event.team_id, event.type),
              textColor: 'primary',
              borderColor: event.isHome === 1 ? '#006534' : 'grey',
              editable: false,
              url: `/matches/${event.id}`,
              overlap: false,
          };
};

export const MatchFilter: React.FC = props => {
    return (
        <Filter {...props}>
            <ReferenceInput
                label="resources.squadplayers.fields.squad"
                source="team_id"
                reference="teams"
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ currentSeason: true }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput
                label="resources.calendar.fields.isHome"
                source="isHome"
                choices={choicesGameType}
            />
            <SelectInput
                label="resources.calendar.fields.venue"
                source="venue"
                choices={choiceVenues}
            />
            <SelectInput
                label="resources.calendar.fields.type"
                source="type"
                choices={choicesCalendarTypes}
            />
        </Filter>
    );
};

export const CalendarList: React.FC = props => {
    const notify = useNotify();
    const handleSuccess = () => {
        notify('Successful update', { type: 'success' });
    };

    const handleFailure = () => {
        notify('Failed update', { type: 'error' });
    };
    return (
        <>
            <List
                {...props}
                filterDefaultValues={customGetFilterValues()}
                perPage={1000}
                filters={<MatchFilter />}
                pagination={false}
            >
                <Calendar
                    locale={deLocale}
                    onFailure={handleFailure}
                    onSuccess={handleSuccess}
                    getFilterValueFromInterval={customGetFilterValues}
                    convertToEvent={convertToEvent}
                    slotMinTime={'09:00:00'}
                    slotMaxTime={'23:00:00'}
                    defaultView="timeGridWeek"
                    {...sanitizeRestListProps(props)}
                />
            </List>
        </>
    );
};
