import {
  Datagrid,
  IfCanAccess,
  List,
  useAuthenticated,
} from "@s-graber/ra-rbac";
import React, { Fragment } from "react";
import {
  TextField,
  DateField,
  Filter,
  FilterProps,
  SelectInput,
  TextInput,
  useTranslate,
  usePermissions,
  BulkDeleteButton,
  FunctionField,
  EditButton,
  DeleteButton,
  CreateButton,
  TopToolbar,
} from "react-admin";
import { choicesTextCategories } from "../../types";

const ListActions = () => (
  <TopToolbar>
    <IfCanAccess action="create" resource="texts">
      <CreateButton />
    </IfCanAccess>
  </TopToolbar>
);

const BulkTextActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} />
  </Fragment>
);

const TextFilter: React.FC<Omit<FilterProps, "children">> = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="resources.texts.fields.category"
        source="category"
        choices={choicesTextCategories}
        alwaysOn
      />
      <TextInput
        label="resources.texts.fields.search"
        source="content@ilike"
        alwaysOn
      />
    </Filter>
  );
};

export const TextList: React.FC = (props) => {
  useAuthenticated();
  const { isLoading } = usePermissions();
  const translate = useTranslate();
  if (isLoading) return null;
  return (
    <List
      actions={<ListActions />}
      bulkActionButtons={<BulkTextActionButtons />}
      filters={<TextFilter />}
      {...props}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <FunctionField
          source="category"
          label="resources.texts.fields.category"
          render={(record) =>
            record && record.category
              ? translate(`resources.texts.categories.${record.category}`)
              : "-"
          }
        />
        <FunctionField
          source="access"
          label="resources.texts.fields.access"
          render={(record) =>
            record && record.access
              ? translate(`resources.texts.access.${record.access}`)
              : "-"
          }
        />
        <DateField
          label="resources.texts.fields.lastupdate"
          source="lastupdate"
        />
        <TextField
          label="resources.texts.fields.updatedby"
          source="updatedby"
        />
        <IfCanAccess action="edit" resource="texts">
          <EditButton />
        </IfCanAccess>
        <IfCanAccess action="delete" resource="texts">
          <DeleteButton />
        </IfCanAccess>
      </Datagrid>
    </List>
  );
};
