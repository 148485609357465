import { FC } from "react";
import get from "lodash/get";
import { styled } from "@mui/material";
import { Link, FieldProps, useRecordContext } from "react-admin";
import { AvatarField } from "./AvatarField";

const FullName = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
}));

export const FullNameField: FC<
  FieldProps & {
    source: string;
    label?: string;
    playerid?: string;
    sourceRating?: string;
    sourceAvatar?: string;
    sourceJersey?: string;
  }
> = (props) => {
  const record = useRecordContext();
  const { source, playerid, sourceRating, sourceAvatar, sourceJersey } = props;
  const recordId = playerid || "playerid";
  const recordSourceAvatar = sourceAvatar || "photo.src";
  const recordSourceJersey = sourceJersey || "jerseyNo";
  const id = get(record, recordId);
  const name = get(record, source);
  if (!record) {
    return null;
  }
  return (
    <FullName>
      <AvatarField
        record={record}
        recordSourceRating={sourceRating}
        recordSourceAvatar={recordSourceAvatar}
        recordSourceJersey={recordSourceJersey}
      />
      {id ? <Link sx={{marginLeft: 1}} to={`/players/${id}`}>{`${name}`}</Link> : `${name}`}
    </FullName>
  );
};
