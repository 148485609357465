import { get } from "lodash";
import React, { useCallback } from "react";
import {
  Create,
  ReferenceInput,
  AutocompleteInput,
  required,
  SelectInput,
  SimpleForm,
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import {
  convertBirthdateToAge,
  choicesScoutingStatus,
  choicesLineupStatus,
} from "../../types";

export const SquadplayerCreate: React.FC = (props) => {
  const [create] = useCreate();
  const redirectTo = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const handleSave = useCallback(
    (values, redirect) => {
      create(
        "teams_players",
        {
          data: values,
        },
        {
          onSettled: ({ id }, error) => {
            redirectTo(
              `/squadplayers?order=DESC&sort=rating&filter=${encodeURI(
                JSON.stringify({
                  team_id: values.team_id,
                  scoutingstatus: values.scoutingstatus,
                })
              )}`
            );
            notify("resources.squadplayers.notification.added", {
              type: "info",
              messageArgs: { smart_count: 1, id },
            });
            refresh();
          },
          onError: (error: any) => {
            const { message } = error;
            notify(`Could not edit post: ${message}`);
            redirectTo("/squadplayers");
            refresh();
          },
        }
      );
    },
    [create, refresh, notify, redirectTo]
  );
  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSave}>
        <ReferenceInput
          label="resources.squadplayers.fields.player"
          source="player_id"
          reference="players"
          validate={[required()]}
          filterToQuery={(searchText: any) => ({ q: searchText })}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            optionValue="id"
            filterToQuery={(searchText) => ({
              "name@ilike": `%${searchText}%`,
            })}
            noOptionsText="-"
            shouldRenderSuggestions={(val: any) => {
              return val.trim().length > 2;
            }}
            optionText={(choice) => {
              const birthdate = convertBirthdateToAge(
                choice,
                "birthdate",
                "agegroup"
              );
              return `${get(choice, "name")} - ${birthdate}`;
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          label="resources.squadplayers.fields.squad"
          source="team_id"
          reference="teams"
          validate={[required()]}
          sort={{ field: "name", order: "ASC" }}
          filter={{ currentPlanning: true }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          label="resources.squadplayers.fields.scoutingstatus"
          source="scoutingstatus"
          defaultValue="scouted"
          choices={choicesScoutingStatus}
        />
        <SelectInput
          label="resources.squadplayers.fields.lineupstatus"
          source="lineupstatus"
          choices={choicesLineupStatus}
          defaultValue="open"
        />
      </SimpleForm>
    </Create>
  );
};
