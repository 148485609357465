import {
    MenuItemCategory,
    MultiLevelMenu,
    IconMenu,
} from '@s-graber/ra-navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ClubIcon from '@mui/icons-material/SupervisedUserCircle';
import { CardContent } from '@mui/material';
import { PlayerIcon } from '../pages/players';
import { SeasonIcon } from '../pages/seasons';
import { AgeclassIcon } from '../pages/ageclasses';
import { RepteamIcon } from '../pages/repteams';
import { LeagueIcon } from '../pages/leagues';
import { PositionIcon } from '../pages/positions';
import { TextIcon } from '../pages/texts';
import { IfCanAccess } from '@s-graber/ra-rbac';
import { TeamIcon } from '../pages/teams';
import { RaRecord, useDataProvider } from 'react-admin';
import { useState, useEffect } from 'react';
import { SquadplayerIcon } from '../pages/squadplayers';
import { PracticeIcon } from '../pages/practices';
import { TrainingIcon } from '../pages/trainings';
import { MatchIcon } from '../pages/matches';
import { TransferIcon } from '../pages/transfers';
import { DfbnetEmailIcon } from '../pages/dfbnet_emails';
import { CoachIcon } from '../pages/coaches';
import { ApplicationIcon } from '../pages/applications';
import { CalendarIcon } from '../pages/calendar';
import { LineupIcon } from '../pages/lineups';
import { ConsultancyIcon } from '../pages/consultancies';

export const ScpMenu = () => {
    const dataProvider = useDataProvider();
    const [seasons, setSeasons] = useState<RaRecord[]>();
    const [teams, setTeams] = useState<RaRecord[]>();

    useEffect(() => {
        const fetchSeasons = async () => {
            const { data: seasons } = await dataProvider.getList('seasons', {
                pagination: { perPage: 10, page: 1 },
                sort: { field: 'name', order: 'DESC' },
                filter: {},
            });
            console.log('Menu - fetchSeasons', { seasons });
            setSeasons(seasons);
        };
        const fetchTeams = async () => {
            const { data: teams } = await dataProvider.getList('teams', {
                pagination: { perPage: 50, page: 1 },
                sort: { field: 'name', order: 'DESC' },
                filter: {},
            });
            console.log('Menu - fetchTeams', { teams });
            setTeams(teams);
        };
        fetchSeasons();
        fetchTeams();
    }, [dataProvider]);
    return (
        <CardContent
            sx={{
                '& .RaMenuRoot-navWithCategories': {
                    backgroundColor: '#424242',
                    color: 'white',
                },
            }}
        >
            <IconMenu variant="default" initialOpen={true}>
                <MenuItemCategory
                    name="dashboard"
                    to="/"
                    label="Dashboard"
                    icon={<DashboardIcon />}
                />
                <MenuItemCategory
                    name="players"
                    to="/players"
                    label="resources.players.name"
                    icon={<PlayerIcon />}
                />
                <MenuItemCategory
                    name="practices"
                    to="/practices"
                    label="resources.practices.name"
                    icon={<PracticeIcon />}
                />
                <MenuItemCategory
                    name="trainings"
                    to="/trainings"
                    label="resources.trainings.name"
                    icon={<TrainingIcon />}
                />
                <MenuItemCategory
                    name="matches"
                    to="/matches"
                    label="resources.matches.name"
                    icon={<MatchIcon />}
                />
                <MenuItemCategory
                    name="lineups"
                    to="/lineups"
                    label="resources.lineups.name"
                    icon={<LineupIcon />}
                />
                <MenuItemCategory
                    name="calendar"
                    to="/calendar"
                    label="resources.calendar.name"
                    icon={<CalendarIcon />}
                />
                <IfCanAccess resource="applications" action="list">
                    <MenuItemCategory
                        name="applications"
                        to="/applications"
                        label="resources.applications.name"
                        icon={<ApplicationIcon />}
                    />
                </IfCanAccess>
                <MenuItemCategory
                    name="squadplayers"
                    label="resources.squadplayers.name"
                    icon={<SquadplayerIcon />}
                >
                    <CardContent>
                        <MultiLevelMenu>
                            {seasons &&
                                seasons.map(
                                    ({ id: seasonId, name: seasonName }) => (
                                        <MultiLevelMenu.Item
                                            name={`squadplayers.s${seasonId}`}
                                            to={{
                                                pathname: '/teams',
                                                search: `order=ASC&sort=name&filter=${encodeURI(
                                                    JSON.stringify({
                                                        season_id: seasonId,
                                                    })
                                                )}`,
                                            }}
                                            label={`${seasonName}`}
                                            icon={<SeasonIcon />}
                                            key={`resources.seasons.seasons.s${seasonName.replace(
                                                '/',
                                                '_'
                                            )}`}
                                        >
                                            {teams &&
                                                teams.map(
                                                    ({
                                                        id: team_id,
                                                        name: squadName,
                                                        season_id,
                                                    }) => {
                                                        // console.log("Menu - teams", { teams });
                                                        if (
                                                            season_id ===
                                                            seasonId
                                                        ) {
                                                            return (
                                                                <MultiLevelMenu.Item
                                                                    name={`squadplayers.s${seasonId}.s${team_id}`}
                                                                    to={{
                                                                        pathname:
                                                                            '/squadplayers',
                                                                        search: `order=DESC&sort=lineupstatus&filter=${encodeURI(
                                                                            JSON.stringify(
                                                                                {
                                                                                    team_id:
                                                                                        team_id,
                                                                                    scoutingstatus:
                                                                                        'member',
                                                                                }
                                                                            )
                                                                        )}`,
                                                                    }}
                                                                    label={`${squadName.replace(
                                                                        seasonName,
                                                                        ''
                                                                    )}`}
                                                                    icon={
                                                                        <SquadplayerIcon />
                                                                    }
                                                                    key={
                                                                        team_id
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    }
                                                )}
                                        </MultiLevelMenu.Item>
                                    )
                                )}
                        </MultiLevelMenu>
                    </CardContent>
                </MenuItemCategory>
                <MenuItemCategory
                    name="additional"
                    label="pos.menu.additional"
                    icon={<ExpandMoreIcon />}
                >
                    <CardContent>
                        <MultiLevelMenu>
                            <MultiLevelMenu.Item
                                name="clubs"
                                to="/clubs"
                                label="resources.clubs.name"
                                icon={<ClubIcon />}
                            />
                            <IfCanAccess resource="ageclasses" action="list">
                                <MultiLevelMenu.Item
                                    name="ageclasses"
                                    to="/ageclasses"
                                    label="resources.ageclasses.name"
                                    icon={<AgeclassIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="seasons" action="list">
                                <MultiLevelMenu.Item
                                    name="seasons"
                                    to="/seasons"
                                    label="resources.seasons.name"
                                    icon={<SeasonIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="repteams" action="list">
                                <MultiLevelMenu.Item
                                    name="repteams"
                                    to="/repteams"
                                    label="resources.repteams.name"
                                    icon={<RepteamIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="consultancies" action="list">
                                <MultiLevelMenu.Item
                                    name="consultancies"
                                    to="/consultancies"
                                    label="resources.consultancies.name"
                                    icon={<ConsultancyIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="leagues" action="list">
                                <MultiLevelMenu.Item
                                    name="leagues"
                                    to="/leagues"
                                    label="resources.leagues.name"
                                    icon={<LeagueIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="positions" action="list">
                                <MultiLevelMenu.Item
                                    name="positions"
                                    to="/positions"
                                    label="resources.positions.name"
                                    icon={<PositionIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="texts" action="list">
                                <MultiLevelMenu.Item
                                    name="texts"
                                    to="/texts"
                                    label="resources.texts.name"
                                    icon={<TextIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="teams" action="list">
                                <MultiLevelMenu.Item
                                    name="teams"
                                    to="/teams"
                                    label="resources.teams.name"
                                    icon={<TeamIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="transfers" action="list">
                                <MultiLevelMenu.Item
                                    name="transfers"
                                    to="/transfers"
                                    label="resources.transfers.name"
                                    icon={<TransferIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="dfbnet_emails" action="list">
                                <MultiLevelMenu.Item
                                    name="dfbnet_emails"
                                    to="/dfbnet_emails"
                                    label="resources.dfbnet_emails.name"
                                    icon={<DfbnetEmailIcon />}
                                />
                            </IfCanAccess>
                            <IfCanAccess resource="coaches" action="list">
                                <MultiLevelMenu.Item
                                    name="coaches"
                                    to="/coaches"
                                    label="resources.coaches.name"
                                    icon={<CoachIcon />}
                                />
                            </IfCanAccess>
                        </MultiLevelMenu>
                    </CardContent>
                </MenuItemCategory>
            </IconMenu>
        </CardContent>
    );
};
