import React from 'react';
import {
    BulkExportButton,
    Datagrid,
    FormTab,
    FunctionField,
    Link,
    NumberField,
    NumberFieldProps,
    ReferenceManyField,
    TopToolbar,
    WithListContext,
    useGetIdentity,
    useGetManyReference,
    useGetOne,
    useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { Box } from '@mui/material';
import { ResponsiveParallelCoordinates } from '@nivo/parallel-coordinates';
import TableViewIcon from '@mui/icons-material/TableView';
import SpeedIcon from '@mui/icons-material/Speed';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import InsightsIcon from '@mui/icons-material/Insights';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ResponsiveRaderTopSpeed } from './charts/ResponsiveRaderTopSpeed';
import { ResponsiveRadarDistance } from './charts/ResponsiveRadarDistance';

const StatsTopToolbar = props => {
    const {
        isLoading,
        data: {
            idTokenClaims: { roles },
        },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading) return null;
    const { className } = props;
    return (
        <TopToolbar className={className}>
            {allowedAccess && <BulkExportButton />}
        </TopToolbar>
    );
};

const ColoredField = (props: NumberFieldProps) => {
    const record = useRecordContext();
    return (
        <NumberField
            sx={
                {
                    // "& .RaDatagrid-rowCell": {
                    // backgroundColor: record[props.source] > 5 ? 'red' : 'green',
                    // }
                }
            }
            {...props}
        />
    );
};

export const MatchesPlayersStatsFormTab: React.FC = props => {
    const location = useLocation();
    const [, , match_id] = location.pathname.split('/');
    const { data: match, isLoading } = useGetOne('matches', { id: match_id });
    const { data: matches_players, isLoading: isLoadingMatchesPlayers } =
        useGetManyReference('matches_players', {
            target: 'match_id',
            id: match_id,
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'DESC' },
        });
    const [alignment, setAlignment] = React.useState<string | null>('table');
    const [visibilityParallelCoordinates, setVisibilityParallelCoordinates] =
        React.useState<boolean | null>(false);
    const [visibilityRadarSpeet, setVisibilityRadarSpeet] = React.useState<
        boolean | null
    >(false);
    const [visibilityRadarDistance, setVisibilityRadarDistance] =
        React.useState<boolean | null>(false);
    const [visibilityTable, setVisibilityTable] = React.useState<
        boolean | null
    >(true);

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment === 'table') {
            setVisibilityTable(true);
            setVisibilityRadarSpeet(false);
            setVisibilityRadarDistance(false);
            setVisibilityParallelCoordinates(false);
        } else if (newAlignment === 'speed') {
            setVisibilityTable(false);
            setVisibilityRadarSpeet(true);
            setVisibilityRadarDistance(false);
            setVisibilityParallelCoordinates(false);
        } else if (newAlignment === 'distance') {
            setVisibilityTable(false);
            setVisibilityRadarSpeet(false);
            setVisibilityRadarDistance(true);
            setVisibilityParallelCoordinates(false);
        } else if (newAlignment === 'parallel') {
            setVisibilityTable(false);
            setVisibilityRadarSpeet(false);
            setVisibilityRadarDistance(false);
            setVisibilityParallelCoordinates(true);
        }
        setAlignment(newAlignment);
    };
    const postRowSx = (record, index) => ({
        backgroundColor: record.distance_km >= 5 ? '#efe' : 'white',
    });

    if (isLoading) return null;
    if (isLoadingMatchesPlayers) return null;
    return (
        <FormTab
            {...props}
            label="resources.matches.tabs.matches_players_stats"
            path="matches_players"
        >
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
            >
                <ToggleButton value="table" aria-label="Table">
                    <TableViewIcon />
                </ToggleButton>
                <ToggleButton value="speed" aria-label="Radar Chart Speed">
                    <SpeedIcon />
                </ToggleButton>
                <ToggleButton
                    value="distance"
                    aria-label="Radar Chart Distance"
                >
                    <DirectionsRunIcon />
                </ToggleButton>
                <ToggleButton value="parallel" aria-label="Parallel Chart">
                    <InsightsIcon />
                </ToggleButton>
            </ToggleButtonGroup>
            {visibilityParallelCoordinates && (
                <Box height={500} width={800}>
                    <WithListContext
                        render={({ data }) => (
                            <ResponsiveParallelCoordinates
                                data={matches_players.map(matches_player => {
                                    return {
                                        id: matches_player.name,
                                        distance_km: matches_player.distance_km,
                                        distance_hi_km:
                                            matches_player.distance_hi_km,
                                        sprints: matches_player.sprints,
                                        top_speed: matches_player.top_speed,
                                        avg_top_speed:
                                            matches_player.avg_top_speed,
                                    };
                                })}
                                layout="vertical"
                                variables={[
                                    {
                                        id: 'Sprints',
                                        value: 'sprints',
                                        min: 0,
                                        max: 'auto',
                                        legendPosition: 'start',
                                        legendOffset: -20,
                                    },
                                    {
                                        id: 'Top Speed (Max)',
                                        value: 'top_speed',
                                        min: 23.0,
                                        max: 'auto',
                                        ticksPosition: 'before',
                                        legendPosition: 'start',
                                        legendOffset: 20,
                                    },
                                    {
                                        id: 'Top Speed (Avg.)',
                                        value: 'avg_top_speed',
                                        min: 23.0,
                                        max: 'auto',
                                        ticksPosition: 'before',
                                        legendPosition: 'start',
                                        legendOffset: 20,
                                    },
                                    {
                                        id: 'Distanz',
                                        value: 'distance_km',
                                        min: 'auto',
                                        max: 'auto',
                                        legendPosition: 'start',
                                        legendOffset: -20,
                                    },
                                    {
                                        id: 'Distanz (HI)',
                                        value: 'distance_hi_km',
                                        min: 0.8,
                                        max: 'auto',
                                        legendPosition: 'start',
                                        legendOffset: -20,
                                    },
                                ]}
                                margin={{
                                    top: 40,
                                    right: 160,
                                    bottom: 30,
                                    left: 20,
                                }}
                                lineWidth={4}
                                colors={{ scheme: 'spectral' }}
                                legends={[
                                    {
                                        anchor: 'right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 2,
                                        itemWidth: 60,
                                        itemHeight: 15,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.5,
                                        symbolSize: 14,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1,
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            />
                        )}
                    />
                </Box>
            )}
            {visibilityRadarSpeet && (
                <Box height={400} width={800}>
                    <WithListContext
                        render={({ data }) => (
                            <ResponsiveRaderTopSpeed
                                matches_players={matches_players}
                            />
                        )}
                    />
                </Box>
            )}
            {visibilityRadarDistance && (
                <Box height={400} width={800}>
                    <WithListContext
                        render={({ data }) => (
                            <ResponsiveRadarDistance
                                matches_players={matches_players}
                            />
                        )}
                    />
                </Box>
            )}
            {visibilityTable && (
                <ReferenceManyField
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={50}
                    reference="matches_players_stats_tracktics"
                    source="id"
                    target="match_id"
                >
                    <Datagrid bulkActionButtons={<StatsTopToolbar />}>
                        {' '}
                        {/* rowSx={postRowSx} */}
                        <FunctionField
                            label="resources.matches.fields.name"
                            source="name"
                            render={record => (
                                <Link
                                    to={`/players/${get(
                                        record,
                                        'player_id'
                                    )}/2`}
                                >
                                    {get(record, 'name')}
                                </Link>
                            )}
                        />
                        <NumberField
                            label="resources.matches.fields.time_on_pitch"
                            source="time_on_pitch"
                        />
                        <ColoredField
                            label="resources.matches.fields.distance_km"
                            source="distance_km"
                        />
                        <NumberField
                            label="resources.matches.fields.distance_km_normalized"
                            source="distance_km_normalized"
                        />
                        <FunctionField
                            label="resources.matches.fields.distance_km_expected"
                            render={record =>
                                record.time_on_pitch && match.gameTime
                                    ? new Intl.NumberFormat('de-DE', {
                                          maximumFractionDigits: 2,
                                      }).format(
                                          (record.distance_km /
                                              record.time_on_pitch) *
                                              match.gameTime
                                      )
                                    : ''
                            }
                        />
                        <NumberField
                            label="resources.matches.fields.distance_hi_km"
                            source="distance_hi_km"
                        />
                        <NumberField
                            label="resources.matches.fields.distance_hi_km_normalized"
                            source="distance_hi_km_normalized"
                        />
                        <FunctionField
                            label="resources.matches.fields.distance_hi_km_expected"
                            render={record =>
                                record.time_on_pitch && match.gameTime
                                    ? new Intl.NumberFormat('de-DE', {
                                          maximumFractionDigits: 2,
                                      }).format(
                                          (record.distance_hi_km /
                                              record.time_on_pitch) *
                                              match.gameTime
                                      )
                                    : ''
                            }
                        />
                        <NumberField
                            label="resources.matches.fields.sprints"
                            source="sprints"
                        />
                        <NumberField
                            label="resources.matches.fields.top_speed"
                            source="top_speed"
                        />
                        <NumberField
                            label="resources.matches.fields.avg_top_speed"
                            source="avg_top_speed"
                        />
                    </Datagrid>
                </ReferenceManyField>
            )}
            {/* </AccordionFormPanel>
            </AccordionForm> */}
        </FormTab>
    );
};
