import React from "react";
import {
  FormTab,
  ReferenceManyField,
  Datagrid,
  DateField,
  DeleteButton,
  FunctionField,
} from "react-admin";
import { useLocation } from "react-router-dom";

export const PhysicalFormTab: React.FC = (props) => {
  const { pathname } = useLocation();
  return (
    <FormTab {...props} label="resources.players.tabs.physical" path="physical">
      <ReferenceManyField
        sort={{ field: "date", order: "DESC" }}
        reference="physicals"
        source="id"
        target="player_id"
      >
        <Datagrid>
          <DateField label="resources.physicals.fields.date" source="date" />
          <FunctionField
            source="height"
            render={(record: any) =>
              record.height ? `${record.height}cm`.replace(".", ",") : "-"
            }
          />
          <FunctionField
            source="heightSitting"
            render={(record: any) =>
              record.heightSitting
                ? `${record.heightSitting}cm`.replace(".", ",")
                : "-"
            }
          />
          <FunctionField
            source="weight"
            render={(record: any) =>
              record.weight ? `${record.weight}kg`.replace(".", ",") : "-"
            }
          />
          <FunctionField
            label="resources.physicals.fields.bmi"
            source="bmi"
            render={(record: any) =>
              record.bmi
                ? parseFloat(record.bmi).toFixed(2).toLocaleString()
                : "-"
            }
          />
          <FunctionField
            label="resources.physicals.fields.age"
            source="age"
            render={(record: any) =>
              record.age
                ? parseFloat(record.age).toFixed(2).toLocaleString()
                : "-"
            }
          />
          <FunctionField
            label="resources.physicals.fields.aphv"
            source="aphv"
            render={(record: any) =>
              record.aphv
                ? parseFloat(record.aphv).toFixed(2).toLocaleString()
                : "-"
            }
          />
          <DeleteButton label="" redirect={pathname} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  );
};
