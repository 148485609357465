/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { makeStyles, Theme, Box, useTheme } from "@mui/material";
import {
  FormTab,
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
  Pagination,
} from "react-admin";
import { AccordionForm, AccordionFormPanel } from "@s-graber/ra-form-layout";

export const StatsFormTab: React.FC = (props) => {
  const theme = useTheme();
  return (
    <FormTab
      label="resources.players.tabs.stats"
      path="stats"
      sx={{
        '& .RaDatagrid-headerCell': {
          padding: theme.spacing(0.5),
        },
      }}
      {...props}
    >
      <Box display={{ sm: "block", md: "flex" }}>
        <AccordionForm autoClose toolbar={null}>
          <AccordionFormPanel label="Meisterschaftsspiele (kumuliert)" defaultExpanded>
            <ReferenceManyField
              sort={{ field: "team_id", order: "DESC" }}
              filter={{ isOfficial: 1 }}
              reference="match_stats_by_team"
              source="id"
              target="player_id"
            >
              <Datagrid bulkActionButtons={false}>
                <ReferenceField
                  label="resources.matches.fields.team_id"
                  source="team_id"
                  reference="teams"
                  sortBy=""
                  link={(record, reference) =>
                    `/${reference}/${record.team_id}/match_stats`
                  }
                >
                  <TextField source="name" />
                </ReferenceField>
                <NumberField
                  label="resources.matches.fields.assists"
                  source="assists"
                />
                <NumberField
                  label="resources.matches.fields.goals"
                  source="goals"
                />
                <NumberField
                  label="resources.matches.fields.yellow"
                  source="yellow"
                />
                <NumberField
                  label="resources.matches.fields.time_played"
                  source="time_played"
                />
                <NumberField
                  label="resources.matches.fields.starting"
                  source="starting"
                />
                <NumberField
                  label="resources.matches.fields.ill"
                  source="ill"
                />
                <NumberField
                  label="resources.matches.fields.injured"
                  source="injured"
                />
                <NumberField
                  label="resources.matches.fields.substituted_in"
                  source="substituted_in"
                />
                <NumberField
                  label="resources.matches.fields.substituted_out"
                  source="substituted_out"
                />
                <NumberField
                  label="resources.matches.fields.avg_rating"
                  source="avg_rating"
                  options={{ maximumFractionDigits: 2 }}
                />
                <NumberField
                  label="resources.matches.fields.min_rating"
                  source="min_rating"
                />
                <NumberField
                  label="resources.matches.fields.max_rating"
                  source="max_rating"
                />
              </Datagrid>
            </ReferenceManyField>
          </AccordionFormPanel>
          <AccordionFormPanel label="Pokalspiele (kumuliert)" defaultExpanded>
            <ReferenceManyField
              sort={{ field: 'team_id', order: 'DESC' }}
              filter={{ isOfficial: 2 }}
              reference="match_stats_by_team"
              source="id"
              target="player_id"
            >
              <Datagrid bulkActionButtons={false}>
                <ReferenceField
                  label="resources.matches.fields.team_id"
                  source="team_id"
                  reference="teams"
                  link={(record, reference) => `/${reference}/${record.team_id}/match_stats`}
                >
                  <TextField source="name" />
                </ReferenceField>
                <NumberField label="resources.matches.fields.assists" source="assists" />
                <NumberField label="resources.matches.fields.goals" source="goals" />
                <NumberField label="resources.matches.fields.yellow" source="yellow" />
                <NumberField label="resources.matches.fields.time_played" source="time_played" />
                <NumberField label="resources.matches.fields.starting" source="starting" />
                <NumberField label="resources.matches.fields.ill" source="ill" />
                <NumberField label="resources.matches.fields.injured" source="injured" />
                <NumberField label="resources.matches.fields.substituted_in" source="substituted_in" />
                <NumberField label="resources.matches.fields.substituted_out" source="substituted_out" />
                <NumberField
                  label="resources.matches.fields.avg_rating"
                  source="avg_rating"
                  options={{ maximumFractionDigits: 2 }}
                />
                <NumberField label="resources.matches.fields.min_rating" source="min_rating" />
                <NumberField label="resources.matches.fields.max_rating" source="max_rating" />
              </Datagrid>
            </ReferenceManyField>
          </AccordionFormPanel>
          <AccordionFormPanel label="Testspiele (kumuliert)">
            <ReferenceManyField
              sort={{ field: "team_id", order: "DESC" }}
              filter={{ isOfficial: 0 }}
              reference="match_stats_by_team"
              source="id"
              target="player_id"
            >
              <Datagrid bulkActionButtons={false}>
                <ReferenceField
                  label="resources.matches.fields.team_id"
                  source="team_id"
                  reference="teams"
                  link={(record, reference) =>
                    `/${reference}/${record.team_id}/match_stats`
                  }
                >
                  <TextField source="name" />
                </ReferenceField>
                <NumberField
                  label="resources.matches.fields.assists"
                  source="assists"
                />
                <NumberField
                  label="resources.matches.fields.goals"
                  source="goals"
                />
                <NumberField
                  label="resources.matches.fields.yellow"
                  source="yellow"
                />
                <NumberField
                  label="resources.matches.fields.time_played"
                  source="time_played"
                />
                <NumberField
                  label="resources.matches.fields.starting"
                  source="starting"
                />
                <NumberField
                  label="resources.matches.fields.ill"
                  source="ill"
                />
                <NumberField
                  label="resources.matches.fields.injured"
                  source="injured"
                />
                <NumberField
                  label="resources.matches.fields.substituted_in"
                  source="substituted_in"
                />
                <NumberField
                  label="resources.matches.fields.substituted_out"
                  source="substituted_out"
                />
                <NumberField
                  label="resources.matches.fields.avg_rating"
                  source="avg_rating"
                  options={{ maximumFractionDigits: 2 }}
                />
                <NumberField
                  label="resources.matches.fields.min_rating"
                  source="min_rating"
                />
                <NumberField
                  label="resources.matches.fields.max_rating"
                  source="max_rating"
                />
              </Datagrid>
            </ReferenceManyField>
          </AccordionFormPanel>
          <AccordionFormPanel label="Alle Spiele">
            <ReferenceManyField
              sort={{ field: "date", order: "DESC" }}
              perPage={10}
              pagination={<Pagination />}
              reference="matches_players_with_date"
              source="id"
              target="player_id"
            >
              <Datagrid bulkActionButtons={false}>
                <ReferenceField
                  label="resources.matches.fields.date"
                  source="match_id"
                  reference="matches"
                  link={(record, reference) =>
                    `/${reference}/${record.match_id}`
                  }
                >
                  <DateField
                    source="date"
                    options={{
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }}
                  />
                </ReferenceField>
                <ReferenceField
                  label="resources.matches.fields.match"
                  source="match_id"
                  reference="matches"
                  sortable={false}
                  link={(record, reference) =>
                    `/${reference}/${record.id}`
                  }
                >
                  <FunctionField
                    source="id"
                    render={(record: any) =>
                      record.homeTeam
                        ? `${record.homeTeam} - ${record.guestTeam}`
                        : "-"
                    }
                  />
                </ReferenceField>
                <ReferenceField
                  label="resources.matches.fields.team_id"
                  source="team_id"
                  reference="teams"
                  link={(record, reference) =>
                    `/${reference}/${record.team_id}/match_stats`
                  }
                >
                  <TextField source="name" />
                </ReferenceField>
                <NumberField
                  label="resources.matches.fields.assists"
                  source="assists"
                />
                <NumberField
                  label="resources.matches.fields.goals"
                  source="goals"
                />
                <NumberField
                  label="resources.matches.fields.yellow"
                  source="yellow"
                />
                <NumberField
                  label="resources.matches.fields.time_played"
                  source="time_played"
                />
                <NumberField
                  label="resources.matches.fields.starting"
                  source="starting"
                />
                <NumberField
                  label="resources.matches.fields.ill"
                  source="ill"
                />
                <NumberField
                  label="resources.matches.fields.injured"
                  source="injured"
                />
                <NumberField
                  label="resources.matches.fields.substituted_in"
                  source="substituted_in"
                />
                <NumberField
                  label="resources.matches.fields.substituted_out"
                  source="substituted_out"
                />
                <NumberField
                  label="resources.matches.fields.rating"
                  source="rating"
                />
              </Datagrid>
            </ReferenceManyField>
          </AccordionFormPanel>
          <AccordionFormPanel label="Trainingseinheiten">
            <ReferenceManyField
              sort={{ field: "team_name", order: "DESC" }}
              perPage={50}
              reference="training_stats_by_team"
              source="id"
              target="player_id"
            >
              <Datagrid bulkActionButtons={false}>
                <ReferenceField
                  label="resources.matches.fields.team_id"
                  source="team_id"
                  reference="teams"
                  link={(record, reference) =>
                    `/${reference}/${record.team_id}/training_stats`
                  }
                >
                  <TextField source="name" />
                </ReferenceField>
                <NumberField
                  label="resources.trainings.fields.present"
                  source="present"
                />
                <NumberField
                  label="resources.trainings.fields.ill"
                  source="ill"
                />
                <NumberField
                  label="resources.trainings.fields.injured"
                  source="injured"
                />
                <NumberField
                  label="resources.trainings.fields.away"
                  source="away"
                />
                <NumberField
                  label="resources.trainings.fields.light"
                  source="light"
                />
                <NumberField
                  label="resources.trainings.fields.rate_presence"
                  source="rate_presence"
                  options={{ style: 'percent' }}
                />
              </Datagrid>
            </ReferenceManyField>
          </AccordionFormPanel>
        </AccordionForm>
      </Box>
    </FormTab>
  );
};
