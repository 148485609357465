import { useAuthenticated } from "@s-graber/ra-rbac";
import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  BooleanInput,
} from "react-admin";

export const SeasonCreate: React.FC = (props) => {
  useAuthenticated();
  return (
    <Create redirect="list" {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <BooleanInput source="isCurrent" />
      </SimpleForm>
    </Create>
  );
};
