import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import LineupIcon from '@mui/icons-material/FolderShared';
import SquadplayersIcon from '@mui/icons-material/PermContactCalendar';
import PlayersIcon from '@mui/icons-material/Person';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import {
    WithListContext,
    useDataProvider,
    useGetList,
    useTranslate,
} from 'react-admin';
import { EventRecord, Timeline } from '@s-graber/ra-audit-log';
import _ from 'lodash';
import { CalendarIcon } from '../calendar';
import { MatchIcon } from '../matches';
import { TrainingIcon } from '../trainings';
import { TransferIcon } from '../transfers';
import {
    Theme,
    Card,
    CardContent,
    Typography,
    ListItem,
    ListItemIcon,
    ListItemText,
    CardActions,
    useMediaQuery,
    Box,
    Button,
} from '@mui/material';

const Updates: FC = () => {
    const data = [
        '24. Oktober 2024: Anpassungen Talenttag 2024',
        '24. August 2024: Vorbereitung Talenttag 2024, "Signature Skill" und Leistungsdatenanzeige je Kalenderwoche ergänzt',
        '17. Juni 2024: Saisonumstellung, Tracktics-Import ergänzt',
        '24. Februar 2024: Deeplinks gefixt, "Berater kontaktiert" ergänzt, Berater im Spieler-Profil ergänzt',
        '7. Februar 2024: Umstellung Teams',
        '3. Janur 2024: Anbindung EntraID SCP',
        '27. Dezember 2023: Zusammenlegung Sichtungen/Bewertungen',
        '3. Oktober 2023: Import des DFBNet Spielbericht CSV-Exports',
        '5. September 2023: Import der SpielerPLus Trainingsbeteiligung',
        '19. August 2023: Vorbereitung Talenttag 2023',
        '10. Juni 2023: IBAN verschlüsselt',
        '18. Mai 2023: Anpassungen fürs Passwesen'
    ];
    return (
        <Fragment>
            <Card sx={{ margin: 1 }}>
                <CardContent>
                    <Typography variant="h5" component="h3">
                        Updates
                    </Typography>
                    <ul>
                        {data.map((text, idx) => (
                            <>
                                <ListItem key={`text_${idx}`}>
                                    <ListItemIcon>
                                        <AnnouncementIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${text}`} />
                                </ListItem>
                            </>
                        ))}
                    </ul>
                </CardContent>
                <CardActions
                    style={{ justifyContent: 'flex-end' }}
                ></CardActions>
            </Card>
        </Fragment>
    );
};

const Info: FC = () => {
    const data = [
        'Jeder Trainer bewertet seinen aktuellen Kader und pflegt die Stammdaten der jeweiligen Spieler.',
        'Die Reiter "In Sichtung" / "Nicht in Sichtung" pflegt der Trainer, der für die Kaderplanung des Teams in der kommenden Saison zuständig ist, ebenso die Einschätzung für den aktuellen Kader: geplant / nicht geplant / offen.',
        'Spieler, die insbesondere im Reiter "Nicht in Sichtung" stehen und nicht mehr relevant sind, könne aus der Kaderplanung entfernt werden.',
        'Bewertungen im Bereich U12/U13 richten sich NICHT nach der Spielzeit.',
    ];

    return (
        <Fragment>
            <Card sx={{ margin: 1 }}>
                <CardContent>
                    <Typography variant="h5" component="h3">
                        Infos
                    </Typography>
                    <ul>
                        {data.map((text, idx) => (
                            <>
                                <ListItem key={`text_${idx}`}>
                                    <ListItemIcon>
                                        <AnnouncementIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${text}`} />
                                </ListItem>
                            </>
                        ))}
                    </ul>
                </CardContent>
                <CardActions
                    style={{ justifyContent: 'flex-end' }}
                ></CardActions>
            </Card>
        </Fragment>
    );
};

const Welcome: FC = () => {
    return (
        <>
            <Card sx={{ margin: 1 }}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        SC Preußen Münster - Youngstars - DB
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
};

const DashboardLinks: FC = () => {
    const translate = useTranslate();
    return (
        <Card sx={{ margin: 1 }}>
            <CardContent>
                <Button href="/players">
                    <PlayersIcon style={{ paddingRight: '0.5em' }} />
                    {translate('resources.players.name')}
                </Button>
                <Button href="/squadplayers">
                    <SquadplayersIcon style={{ paddingRight: '0.5em' }} />
                    {translate('resources.squadplayers.name')}
                </Button>
                <Button href="/lineups">
                    <LineupIcon style={{ paddingRight: '0.5em' }} />
                    {translate('resources.lineups.name')}
                </Button>
                <Button href="/calendar">
                    <CalendarIcon style={{ paddingRight: '0.5em' }} />
                    {translate('resources.calendar.name')}
                </Button>
                <Button href="/matches">
                    <MatchIcon style={{ paddingRight: '0.5em' }} />
                    {translate('resources.matches.name')}
                </Button>
                <Button href="/trainings">
                    <TrainingIcon style={{ paddingRight: '0.5em' }} />
                    {translate('resources.trainings.name')}
                </Button>
                <Button href="/transfers">
                    <TransferIcon style={{ paddingRight: '0.5em' }} />
                    {translate('resources.transfers.name')}
                </Button>
            </CardContent>
        </Card>
    );
};

export const Home: React.FC = props => {
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const { data, isLoading, total } = useGetList('events', {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'date', order: 'DESC' },
    });
    const dataProvider = useDataProvider();
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState<EventRecord[]>([]);

    const handleLoadMore = useCallback(
        () => {
            setPage(page + 1);
            getData();
        },
        [page] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const getData = useCallback(
        async () => {
            const { data: fetchedEvents } = await dataProvider.getList(
                'events',
                {
                    pagination: { page, perPage: 10 },
                    sort: { field: 'date', order: 'DESC' },
                    filter: {},
                }
            );
            const fetchedEventsIds = fetchedEvents.map(event => event.id);
            const events = fetchedEventsIds.map((id, idx) => {
                return {
                    id: id,
                    date: fetchedEvents[idx].date,
                    author: fetchedEvents[idx].author,
                    resource: fetchedEvents[idx].resource,
                    action: fetchedEvents[idx].action,
                    payload: fetchedEvents[idx].payload,
                };
            });
            const mergedEvents = _.unionBy(records, events, 'id');
            const sortedEvents = mergedEvents.sort((a, b) => {
                var keyA = new Date(a.date),
                    keyB = new Date(b.date);
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });
            setRecords(sortedEvents);
        },
        [dataProvider, page, records] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        getData();
    }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

    return isSmall ? (
        <Box sx={{ flex: 1, marginRight: '0.5em' }}>
            <Welcome />
            <Updates />
            <Info />
            <Timeline records={records} />
            {/* <TimelineList basePath="/home" resource="journal" /> */}
        </Box>
    ) : (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1, marginRight: '0.5em' }}>
                <Box sx={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Welcome />
                </Box>
                <Box sx={{ marginTop: '1em', marginBottom: '1em' }}>
                    <DashboardLinks />
                </Box>
                <Box sx={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Updates />
                </Box>
                <Box sx={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Info />
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: '0.5em' }}>
                <Box sx={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Card sx={{ margin: 1 }}>
                        <CardContent>
                            <Typography variant="h5" component="h3">
                                letzte Änderungen
                            </Typography>
                            <Timeline records={records} />
                            {records && records.length < total && (
                                <Button
                                    variant="contained"
                                    onClick={handleLoadMore}
                                >
                                    <>weitere Einträge laden</>
                                </Button>
                            )}
                        </CardContent>
                        <CardActions
                            style={{ justifyContent: 'flex-end' }}
                        ></CardActions>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};
