import { Box } from "@mui/material";
import { MarkdownInput } from "@s-graber/ra-markdown";
import { FormTab } from "react-admin";

export const TrainingPlanningFormTab = (props) => {
  return (
    <FormTab
      {...props}
      label="resources.trainings.tabs.planning"
      path="planning"
    >
      <Box display={{ sm: "block", md: "flex" }}>
        <MarkdownInput
          label="resources.trainings.fields.content"
          source="content"
        />
      </Box>
    </FormTab>
  );
};
