import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import get from "lodash/get";
import { Fab, Tooltip } from "@mui/material";
import { useScoutingReviewStyles } from "../../types";
import { Stars, ThumbDown, ThumbUp, ThumbsUpDown } from "@mui/icons-material";

export const RatingIcon: React.FC<{
  rating: number;
}> = ({ rating }) => {
  const translate = useTranslate();
  if (!rating) {
    return null;
  }
  const translated = translate(`resources.scoutingReview.${rating}`);
  const ratingColor = useScoutingReviewStyles[rating];
  let icon = null;
  if (rating === 1) {
    icon = <Stars />;
  } else if (rating === 2) {
    icon = <ThumbUp />;
  } else if (rating === 3) {
    icon = <ThumbsUpDown />;
  } else if (rating === 4) {
    icon = <ThumbDown />;
  }
  return rating ? (
    <Tooltip title={translated} aria-label={translated}>
      <Fab sx={ratingColor} size="small">
        {icon}
      </Fab>
    </Tooltip>
  ) : (
    <span />
  );
};

export const RatingField: React.FC<{
  source: string;
  label?: string;
}> = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const rating: number = get(record, source);
  return <RatingIcon rating={rating} />;
};
