import { SimpleForm, useAuthenticated } from "@s-graber/ra-rbac";
import React from "react";
import {
  Create,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  BooleanInput,
} from "react-admin";

export const TeamCreate: React.FC = (props) => {
  useAuthenticated();
  const transform = (data) => {
    return {
      ...data,
      current: data.currentPlanning || data.currentSeason,
    };
  };
  return (
    <Create redirect="list" transform={transform} {...props}>
      <SimpleForm>
        <TextInput
          label="resources.teams.fields.name"
          source="name"
          validate={required()}
        />
        <TextInput
          label="resources.teams.fields.short"
          source="short"
          validate={required()}
        />
        <TextInput
          label="resources.teams.fields.dfbnetTeamName"
          source="dfbnetTeamName"
          validate={required()}
        />
        <ReferenceInput
          source="ageclass_id"
          reference="ageclasses"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            label="resources.teams.fields.ageclass"
            optionText="name"
            translateChoice={false}
          />
        </ReferenceInput>
        <ReferenceInput
          source="season_id"
          reference="seasons"
          sort={{ field: "name", order: "DESC" }}
        >
          <SelectInput
            label="resources.teams.fields.season"
            optionText="name"
            translateChoice={false}
          />
        </ReferenceInput>
        <BooleanInput
          label="resources.teams.fields.currentSeason"
          source="currentSeason"
        />
        <BooleanInput
          label="resources.teams.fields.currentPlanning"
          source="currentPlanning"
        />
      </SimpleForm>
    </Create>
  );
};
