/* eslint-disable @typescript-eslint/no-use-before-define */
import { useNotify, useUnselectAll } from 'ra-core';
import React, { useState } from 'react';
import { Button, useGetIdentity, useRefresh } from 'react-admin';
import { TransferIcon } from '../../transfers';
import { useAuthProvider } from 'ra-core';
import { useEffect } from 'react';
import { fetchFromSupabseFunction } from '../../../types';
import { useMsal } from '@azure/msal-react';

interface IBulkConvertApplication {
  selectedIds: any;
}

export const BulkTransferCreate: React.FC<IBulkConvertApplication> = ({ selectedIds }) => {
  const unselect = useUnselectAll('squadplayers');
  const notify = useNotify();
  const refreshView = useRefresh();
  const authProvider = useAuthProvider();
  const [jwtToken, setJwtToken] = useState();
  const { identity } = useGetIdentity();
  const [loading, setLoading] = useState(false);
  const { instance: msalInstance } = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await authProvider.getAccessToken(msalInstance)
      setJwtToken(accessToken);
    };
    fetchData();
  }, [authProvider]);

  const handleSave = () => {
    setLoading(true);
    fetchFromSupabseFunction('transfer-create', { ids: selectedIds, identity, referrer: window.location.hostname }, jwtToken)
      .then(response => response.json())
      .then(data => {
        for (const elem of data.names) {
          if (elem.type === 'update') {
            notify('resources.squadplayers.notification.transfer_updated', { type: 'info', messageArgs: { name: elem.name } }, );
          } else if (elem.type === 'create') {
            notify('resources.squadplayers.notification.transfer_added', {type: 'info', messageArgs: { name: elem.name }}, );
          } else if (elem.type === 'error') {
            notify('resources.squadplayers.notification.transfer_not_added', {type: 'error',messageArgs:  {
              name: elem.name,
              error: elem.error,
            }});
          }
        }
      })
      .catch(e => {
        notify(e.message, { type: 'error'} );
      })
      .finally(() => {
        setLoading(false);
        unselect();
        refreshView();
      });
  };
  return (
    <Button label="resources.squadplayers.actions.create_transfer" onClick={handleSave} disabled={loading}>
      <TransferIcon />
    </Button>
  );
};
