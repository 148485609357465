import { msalAuthProvider } from "ra-auth-msal";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, getPermissionsFromAccount, getIdentityFromAccount, tokenRequest, ScpEntraIdentity } from "./authConfig";
import { AuthProvider } from "react-admin";

export const myMSALObj = new PublicClientApplication(msalConfig);

export const authProvider = msalAuthProvider({
  msalInstance: myMSALObj,
  getPermissionsFromAccount,
  getIdentityFromAccount,
  redirectOnCheckAuth: true,
  enableDeepLinkRedirect: true
});


export const wrappedAuthProvider: AuthProvider = {
  ...authProvider,
  logout(params) {
    console.log('logging out')
    return authProvider.logout(params)
  },
  checkAuth(params) {
    return authProvider.checkAuth({
      ...params
    })
  },
  async getAccessToken(msalInstance) {
    const { accessToken } = await msalInstance.acquireTokenSilent(tokenRequest)
    return accessToken
  },
  getIdentity(): Promise<ScpEntraIdentity> {
    return authProvider.getIdentity();
  }
}