import React from 'react';
import {
    DateField,
    SelectInput,
    Datagrid,
    List,
    DeleteButton,
    useTranslate,
    Filter,
    TextInput,
    TextField,
    BooleanField,
    FunctionField,
    useGetIdentity,
    BooleanInput,
} from 'react-admin';
import { choicesApplicationType } from '../../types';
import BulkApplicationActionButtons from './BulkApplicationActionButtons';

const createChoicesAgegroup = (type = 'start') => {
    const choices: Array<{}> = [];
    const date = new Date();
    const startYear: number = date.getFullYear() - 8;
    const endYear: number = date.getFullYear() - 20;
    const startOrEnd =
        type === 'start' ? '-01-01T00:00:00Z' : '-12-31T23:59:00Z';
    for (let i = startYear; i > endYear; i -= 1) {
        let y = i;
        choices.push({
            id: `${i}`,
            name: `${y}`,
            value: `${y}${startOrEnd}`,
        });
    }
    return choices;
};

const ApplicationFilter: React.FC = props => (
    <Filter {...props}>
        <TextInput
            label="resources.applications.fields.name"
            source="name@ilike"
            alwaysOn
        />
        <SelectInput
            label="resources.applications.fields.agegroup_start"
            source="birthdate@gte"
            choices={createChoicesAgegroup('start')}
            optionValue="value"
            alwaysOn
        />
        <SelectInput
            label="resources.applications.fields.agegroup_end"
            source="birthdate@lte"
            choices={createChoicesAgegroup('end')}
            optionValue="value"
            alwaysOn
        />
        <SelectInput
            label="resources.applications.fields.type"
            source="type"
            choices={choicesApplicationType}
            fullWidth
            alwaysOn
        />
        <BooleanInput
            label="resources.applications.fields.workedOn"
            source="workedOn"
            alwaysOn
        />
    </Filter>
);

export const ApplicationList = props => {
    const translate = useTranslate();
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading) return null;
    const createdByField = allowedAccess ? (
        <TextField
            label="resources.applications.fields.createdby"
            source="updatedby"
        />
    ) : null;
    const deleteButtonField = allowedAccess ? <DeleteButton /> : null;
    if (isLoading) return null;
    return (
        <List
            {...props}
            filters={<ApplicationFilter />}
            bulkActionButtons={<BulkApplicationActionButtons />}
            sort={{ field: 'createdate', order: 'DESC' }}
        >
            <Datagrid rowClick="show">
                <TextField source="name" />
                <DateField source="birthdate" sortBy="birthdate" />
                <DateField source="createdate" sortBy="createdate" />
                <FunctionField
                    sortBy="type"
                    label="resources.applications.fields.type"
                    render={(record: any) =>
                        record.type
                            ? translate(
                                  `resources.applications.types.${record.type}`
                              )
                            : ''
                    }
                />
                <BooleanField source="workedOn" />
                {createdByField}
                {deleteButtonField}
            </Datagrid>
        </List>
    );
};
