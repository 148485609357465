import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import AddSquadplayersIcons from "@mui/icons-material/GroupAdd";
import { useLocation } from "react-router";
import {
  useTranslate,
  useNotify,
  useDataProvider,
  useRefresh,
  useRecordContext,
} from "react-admin";
import { getAbsencesByTeam } from "../../../types";

const addPlayersToTraining = async (
  dataProvider,
  team_id,
  training_id,
  trainingDate
) => {
  const { data: squadplayers, total } = (await dataProvider.getList(
    "squadplayers",
    {
      pagination: { page: 1, perPage: 30 },
      sort: { field: "name", order: "ASC" },
      filter: {
        team_id: team_id,
        scoutingstatus: "member",
      },
    }
  )) || { data: [], total: 0 };
  console.log(`found ${total} squadplayers`);
  const filtered = await getAbsencesByTeam(dataProvider, team_id, trainingDate);
  const squadPlayerPromises = squadplayers.map((squadplayer) => {
    const { id, name, player_id } = squadplayer;
    let present = 1,
      ill = 0,
      injured = null,
      away = null;
    if (filtered[player_id]) {
      if (filtered[player_id].key === "ill") {
        ill = 1;
        present = 0;
      } else if (filtered[player_id].key === "injured") {
        injured = filtered[player_id].value;
        present = 0;
      } else if (filtered[player_id].key === "away") {
        away = filtered[player_id].value;
        present = 0;
      }
    }
    // console.log('player', { name: name, status: status, value: value, player_id: player_id });
    return dataProvider.create(
      "trainings_players",
      {
        data: {
          name: name,
          team_id: team_id,
          player_id: player_id,
          squadplayer_id: id,
          training_id: training_id,
          present: present,
          injured: injured,
          ill: ill,
          away: away,
        },
      },
      {
        undoable: false,
      }
    );
  });
  await Promise.all(squadPlayerPromises);
  await dataProvider.update(
    "trainings",
    { id: training_id, data: { linkedPlayers: true }, previousData: null },
    { undoable: false }
  );
};

export const AddTrainingPlayersButton: React.FC = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const [, , training_id] = location.pathname.split("/");
  const dataProvider = useDataProvider();
  const handleSubmit = useCallback(
    async () => {
      setSubmitting(true);
      await addPlayersToTraining(
        dataProvider,
        record.team_id,
        training_id,
        record.date
      );
      refresh();
      notify("resources.trainings.notification.added", {
        type: "info",
        undoable: false,
      });
    },
    [dataProvider, record] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Button onClick={handleSubmit} disabled={submitting}>
      <AddSquadplayersIcons />
      {translate("resources.trainings.actions.add")}
    </Button>
  );
};
