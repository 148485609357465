import { Fragment } from 'react';
import { BulkDeleteButton, useGetIdentity } from 'react-admin';
import { AddSquadplayer } from './AddSquadplayer';

const BulkdPlayerActionButtons = props => {
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading) return null;
    return (
        <Fragment>
            <AddSquadplayer {...props} />
            {allowedAccess ? <BulkDeleteButton {...props} /> : null}
        </Fragment>
    );
};
export default BulkdPlayerActionButtons;
