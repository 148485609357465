import React from 'react';
import {
  DateField,
  Datagrid,
  List,
  DeleteButton,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  FunctionField,
} from 'react-admin';
import { FullNameField } from '../../components/fields/FullNameField';

const PhysicalFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput label="resources.scoutings.fields.player" source="playersName" alwaysOn />
  </Filter>
);

export const PhysicalList = props => {
  const { isLoading } = usePermissions();
  if (isLoading) return null;
  return (
    <List {...props} filters={<PhysicalFilter />} sort={{ field: 'physicaldate', order: 'DESC' }}>
      <Datagrid>
        <FullNameField
          label="resources.scoutings.fields.player"
          source="players.name"
          playerid="players.id"
          sourceRating="players.rating"
          sourceAvatar="players.photo.src"
        />
        <DateField label="resources.scoutings.fields.physicaldate" source="physicaldate" sortBy="physicaldate" />
        <FunctionField
          source="height"
          render={(record: any) => (record.height ? `${record.height}cm`.replace('.', ',') : '-')}
        />
        <FunctionField
          source="heightSitting"
          render={(record: any) => (record.heightSitting ? `${record.heightSitting}cm`.replace('.', ',') : '-')}
        />
        <FunctionField
          source="weight"
          render={(record: any) => (record.weight ? `${record.weight}kg`.replace('.', ',') : '-')}
        />
        <TextField label="resources.scoutings.fields.createdby" source="createdby" />
        <DeleteButton label="" />
      </Datagrid>
    </List>
  );
};
