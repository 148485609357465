import { addRefreshAuthToDataProvider, fetchUtils } from 'react-admin';
import postgrestRestProvider from '@promitheus/ra-data-postgrest';
import { supabaseConfig } from './supabaseConfig';
import {msalRefreshAuth } from 'ra-auth-msal';
import { myMSALObj } from './authProviderAzure';
import { tokenRequest } from './authConfig';

const httpClient = async (url, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // console.log("httpClient - options", { options });
    options.headers.set('Access-Control-Expose-Headers', 'Content-Range');
    options.headers.set("apikey", supabaseConfig.apiKey);
    const { accessToken } = await myMSALObj.acquireTokenSilent(tokenRequest)
    if (accessToken) {
        // console.log("httpClient - accessToken", { accessToken });
        options.headers.set('apikey', supabaseConfig.apiKey);
        options.user = {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        };
    }
    options.user = {
      authenticated: true,
      token: accessToken,
    };
    return fetchUtils.fetchJson(url, options);
};
  
export const postgrestRestDataProvider = postgrestRestProvider(
    supabaseConfig.postgrestUrl,
    httpClient
);

export const postgrestRestDataProviderWithRefresh = addRefreshAuthToDataProvider(
    postgrestRestDataProvider,
    msalRefreshAuth({
      msalInstance: myMSALObj,
      tokenRequest,
    })
);