import React from 'react';
import {
  DateField,
  Datagrid,
  List,
  EditButton,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  BooleanField,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import { FullNameField } from '../../components/fields/FullNameField';
import { ReviewShow } from './ReviewShow';

const ReviewFilter: React.FC = props => (
  <Filter {...props}>
    <ReferenceInput
      reference="players"
      source="player_id"
      label="resources.reviews.fields.player"
      filterToQuery={searchText => ({ name: searchText })}
      alwaysOn
    >
      <AutocompleteInput
        fullWidth
        noOptionsText="-"
        shouldRenderSuggestions={(val: any) => {
          return val.trim().length > 2;
        }}
        optionText="name"
      />
    </ReferenceInput>
    <TextInput label="resources.calls.fields.comments" source="q" />
  </Filter>
);

export const ReviewList = props => {
  const { isLoading } = usePermissions();
  if(isLoading) return null;
  return (
    <List {...props} filters={<ReviewFilter />} sort={{ field: 'createdate', order: 'DESC' }}>
      <Datagrid rowClick="expand" expand={<ReviewShow />}>
        <ReferenceField label="resources.reviews.fields.name" source="player_id" reference="players">
          <FullNameField source="name" playerid="id" sourceRating="rating" sourceAvatar="photo.src" />
        </ReferenceField>
        <DateField label="resources.reviews.fields.createdate" source="createdate" sortBy="createdate" />
        <BooleanField label="resources.reviews.fields.potential" source="potential" />
        <TextField label="resources.scoutings.fields.createdby" source="createdby" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
