import React, { useCallback } from 'react';
import { Edit, RaRecord, TabbedForm } from 'react-admin';
import { sanitizeRestListProps } from '../../types';
import { TrainingDetailsFormTab } from './tabs/TrainingDetailsFormTab';
import { TrainingPlanningFormTab } from './tabs/TrainingPlanningFormTab';
import { TrainingPresenceFormTab } from './tabs/TrainingPresenceFormTab';
import moment from 'moment';

const TrainingTitle: React.FC<{
  record: {
    date: string;
    name: string;
  };
}> = props => {
  return (
    <span>{props.record ? `${props.record.name} am ${new Date(props.record.date).toLocaleDateString()}` : ''}</span>
  );
};

export const TrainingEdit = props => {
  const transform = useCallback((data: RaRecord) => {
    return {
      ...data,
      date: moment(
        `${data.date} ${data.startTime}`,
        "YYYY-MM-DD HH:mm"
      ).toDate(),
    };
  }, []);
  return (
    <Edit transform={transform} title={<TrainingTitle {...props} />} {...props}>
      <TabbedForm {...sanitizeRestListProps(props)}>
        <TrainingDetailsFormTab />
        <TrainingPresenceFormTab />
        <TrainingPlanningFormTab />
      </TabbedForm>
    </Edit>
  );
};
