import React from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    styled,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import { RatingBadge } from '../../components/fields/AvatarField';

interface LineupItemCardProps {
    id: string;
    title: string;
    subtitle: string;
    badge: string;
    photo?: string;
    lineupstatus: string;
    playerstatus: string;
    clubstatus: string;
}

const PREFIX = 'LineupItemCard';
const classes = {
    avatar: `${PREFIX}-avatar`,
    lineupItemAvatar: `${PREFIX}-lineupItemAvatar`,
    lineupItemCardHeader: `${PREFIX}-lineupItemCardHeader`,
    lineupItemCardContent: `${PREFIX}-lineupItemCardContent`,
    planned: `${PREFIX}-planned`,
    open: `${PREFIX}-open`,
    notplanned: `${PREFIX}-notplanned`,
};
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.avatar}`]: {
        backgroundColor: '#006534',
    },
    [`&.${classes.lineupItemAvatar}`]: {
        margin: 0,
        [theme.breakpoints.up('md')]: {
            marginRight: 8,
        },
    },
    [`&.${classes.lineupItemCardHeader}`]: {
        width: '30px',
        [theme.breakpoints.up('md')]: {
            width: '185px',
            padding: 8,
            margin: 0,
        },
        display: 'flex',
        justifyContent: 'center',
        padding: 2,
        margin: '5px auto',
    },
    [`&.${classes.lineupItemCardContent}`]: {
        margin: '1vw',
        padding: '0 !important',
    },
    [`&.${classes.planned}`]: {
        border: '2px solid rgb(76, 175, 80)', // #8fff9d
    },
    [`&.${classes.open}`]: {
        border: '2px solid rgb(255, 152, 0)', //#ffe28f
    },
    [`&.${classes.notplanned}`]: {
        border: '2px solid rgb(244, 67, 54)', //#ff948f
    },
}));

export const LineupItemCard: React.FC<LineupItemCardProps> = ({
    id,
    title,
    subtitle,
    badge,
    photo,
    lineupstatus,
    clubstatus,
    playerstatus,
}) => {
    const shortName = name => {
        const [firstname, lastname] = name.split(' ');
        return firstname + ' ' + lastname.slice(0, 1) + '.';
    };
    let clubstatusIcon =
        clubstatus === 'yes' ? <ThumbUpIcon htmlColor="#00945e" /> : null;
    clubstatusIcon =
        clubstatus === 'no' ? (
            <ThumbDownIcon htmlColor="#f44336" />
        ) : (
            clubstatusIcon
        );
    let playerstatusIcon =
        playerstatus === 'yes' ? <PersonAddIcon htmlColor="#00945e" /> : null;
    playerstatusIcon =
        playerstatus === 'no' ? (
            <PersonAddDisabledIcon htmlColor="#f44336" />
        ) : (
            playerstatusIcon
        );

    const avatarTemplate = <Avatar src={photo} aria-label={title} />;
    const avatar = badge ? (
        <RatingBadge rating={badge} output={avatarTemplate} />
    ) : (
        avatarTemplate
    );
    const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    return isLarge ? (
        <Card className={classes[lineupstatus]}>
            <CardHeader
                classes={{ avatar: classes.lineupItemAvatar }}
                className={classes.lineupItemCardHeader}
                avatar={avatar}
                title={title}
                subheader={subtitle}
                action={
                    <List>
                        <ListItem
                            style={{
                                margin: 0,
                                padding: 0,
                                marginRight: '0.5em',
                            }}
                        >
                            {clubstatusIcon}
                        </ListItem>
                        <ListItem
                            style={{
                                margin: 0,
                                padding: 0,
                                marginRight: '0.5em',
                            }}
                        >
                            {playerstatusIcon}
                        </ListItem>
                    </List>
                }
                // disableTypography={false}
            />
        </Card>
    ) : (
        <Card className={classes[lineupstatus]}>
            <CardHeader
                classes={{ avatar: classes.lineupItemAvatar }}
                className={classes.lineupItemCardHeader}
                avatar={avatar}
                title={false}
                disableTypography={false}
            />
            <CardContent className={classes.lineupItemCardContent}>
                <Typography variant="subtitle2">{shortName(title)}</Typography>
            </CardContent>
        </Card>
    );
};
