import { Edit, TextInput, SimpleForm, required } from 'react-admin';
import React from 'react';

export const ConsultancyEdit: React.FC = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
        </SimpleForm>
    </Edit>
);
