/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  Datagrid,
  List,
  EditButton,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  ReferenceField,
  FunctionField,
  useTranslate,
  SelectInput,
  useGetIdentity,
} from 'react-admin';
import { CoachShow } from './CoachShow';
import { choicesCoachType, choiceCoachLicenses } from '../../types';

const CoachFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput
      label="resources.coaches.fields.name"
      source="name@ilike"
      alwaysOn
    />
    <SelectInput label="resources.coaches.fields.type" source="type" choices={choicesCoachType} />
    <SelectInput label="resources.coaches.fields.license" source="license" choices={choiceCoachLicenses} />
  </Filter>
);

export const CoachList = props => {
  const translate = useTranslate();
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if(isLoading || !allowedAccess) return null;
  return (
    <List {...props} filters={<CoachFilter />} sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid rowClick="expand" expand={<CoachShow />}>
        <TextField source="name" sortBy="name" />
        <FunctionField
          sortBy="type"
          label="resources.coaches.fields.type"
          render={(record: any) => (record.type ? translate(`resources.coaches.types.${record.type}`) : '')}
        />
        <TextField source="mobile" sortBy="mobile" />
        <ReferenceField label="resources.coaches.fields.currentClub" source="club_id" reference="clubs">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="resources.coaches.fields.license" source="license" />
        <TextField label="resources.coaches.fields.createdby" source="createdby" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
