import React from "react";
import { Chip } from "@mui/material";
import { get } from "lodash";
import { FieldProps, useRecordContext } from "react-admin";

export const PositionChipField: React.FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();
  const color = get(record, "color") || "#000000";
  const label = get(record, source);
  if (!label) return null;
  return (
    <Chip
      sx={{ 
        margin: "4px 2px", 
        backgroundColor: `${color}`
      }}
      label={label}
    />
  );
};
