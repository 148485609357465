// @flow
import type { DraggableId, DraggableLocation } from 'react-beautiful-dnd';

export const grid: number = 1;
export const borderRadius: number = 2;

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

export type Id = string;

export type LineupItemContent = {
  id: Id;
  title: string;
  subtitle: string;
  badge: string;
  photo: string;
  lineupstatus: string;
  playerstatus: string;
  clubstatus: string;
};

export type Dragging = {
  id: DraggableId;
  location: DraggableLocation;
};

export type LineupMap = {
  [key: string]: LineupItemContent[];
};

type ReorderLineupMapArgs = {
  lineupMap: LineupMap;
  source: DraggableLocation;
  destination: DraggableLocation;
};

// export type ReorderLineupMapResult = {
//   lineupMap: LineupMap,
// };

export const reorderLineupMap = ({ lineupMap, source, destination }: ReorderLineupMapArgs): LineupMap => {
  const current: LineupItemContent[] = [...lineupMap[source.droppableId]];
  const next: LineupItemContent[] = [...lineupMap[destination.droppableId]];
  const target: LineupItemContent = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered: LineupItemContent[] = reorder(current, source.index, destination.index);
    const result: LineupMap = {
      ...lineupMap,
      [source.droppableId]: reordered,
    };
    return result;
    // return {
    //   lineupMap: result,
    // };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result: LineupMap = {
    ...lineupMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };
  return result;
  // return {
  //   lineupMap: result,
  // };
};

type List<T> = {
  id: string;
  values: T[];
};

type MoveBetweenArgs<T> = {
  list1: List<T>;
  list2: List<T>;
  source: DraggableLocation;
  destination: DraggableLocation;
};

type MoveBetweenResult<T> = {
  list1: List<T>;
  list2: List<T>;
};

export function moveBetween<T>({ list1, list2, source, destination }: MoveBetweenArgs<T>): MoveBetweenResult<T> {
  const newFirst = Array.from(list1.values);
  const newSecond = Array.from(list2.values);

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
  const moveTo = moveFrom === newFirst ? newSecond : newFirst;

  const [moved] = moveFrom.splice(source.index, 1);
  moveTo.splice(destination.index, 0, moved);

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  };
}
