import { Box } from "@mui/material";
import { AccordionForm, AccordionFormPanel } from "@s-graber/ra-form-layout";
import { FormTab, ReferenceManyField, Datagrid, ReferenceField, TextField, NumberField } from "react-admin";

export const TeamsDetailsMatchStatsTab = (props) => {
    return (
        <FormTab label="resources.teams.tabs.match_stats" path="match_stats" {...props}>
            <Box display={{ sm: "block", md: "flex" }}>
                <AccordionForm autoClose>
                    <AccordionFormPanel label="Meisterschaftsspiele" defaultExpanded>
                        <ReferenceManyField
                            sort={{ field: "name", order: "ASC" }}
                            filter={{ isOfficial: 1 }}
                            perPage={50}
                            reference="match_stats_by_team"
                            source="id"
                            target="team_id"
                        >
                            <Datagrid>
                                <ReferenceField
                                    label="resources.matches.fields.player_id"
                                    source="player_id"
                                    reference="players"
                                    link={(record, reference) => `/${reference}/${record.player_id}/2`}
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                                <NumberField
                                    label="resources.matches.fields.assists"
                                    source="assists" />
                                <NumberField
                                    label="resources.matches.fields.goals"
                                    source="goals" />
                                <NumberField
                                    label="resources.matches.fields.yellow"
                                    source="yellow" />
                                <NumberField
                                    label="resources.matches.fields.time_played"
                                    source="time_played" />
                                <NumberField
                                    label="resources.matches.fields.starting"
                                    source="starting" />
                                <NumberField
                                    label="resources.matches.fields.ill"
                                    source="ill" />
                                <NumberField
                                    label="resources.matches.fields.injured"
                                    source="injured" />
                                <NumberField
                                    label="resources.matches.fields.substituted_in"
                                    source="substituted_in" />
                                <NumberField
                                    label="resources.matches.fields.substituted_out"
                                    source="substituted_out" />
                                <NumberField
                                    label="resources.matches.fields.avg_rating"
                                    source="avg_rating"
                                    options={{ maximumFractionDigits: 2 }} />
                                <NumberField
                                    label="resources.matches.fields.min_rating"
                                    source="min_rating" />
                                <NumberField
                                    label="resources.matches.fields.max_rating"
                                    source="max_rating" />
                            </Datagrid>
                        </ReferenceManyField>
                    </AccordionFormPanel>
                    <AccordionFormPanel label="Pokalspiele" defaultExpanded>
                        <ReferenceManyField
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={50}
                            filter={{ isOfficial: 2 }}
                            reference="match_stats_by_team"
                            source="id"
                            target="team_id"
                        >
                            <Datagrid>
                                <ReferenceField
                                    label="resources.matches.fields.player_id"
                                    source="player_id"
                                    reference="players"
                                    link={(record, reference) => `/${reference}/${record.player_id}/2`}
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                                <NumberField label="resources.matches.fields.assists" source="assists" />
                                <NumberField label="resources.matches.fields.goals" source="goals" />
                                <NumberField label="resources.matches.fields.yellow" source="yellow" />
                                <NumberField label="resources.matches.fields.time_played" source="time_played" />
                                <NumberField label="resources.matches.fields.starting" source="starting" />
                                <NumberField label="resources.matches.fields.ill" source="ill" />
                                <NumberField label="resources.matches.fields.injured" source="injured" />
                                <NumberField label="resources.matches.fields.substituted_in" source="substituted_in" />
                                <NumberField label="resources.matches.fields.substituted_out" source="substituted_out" />
                                <NumberField
                                    label="resources.matches.fields.avg_rating"
                                    source="avg_rating"
                                    options={{ maximumFractionDigits: 2 }} />
                                <NumberField label="resources.matches.fields.min_rating" source="min_rating" />
                                <NumberField label="resources.matches.fields.max_rating" source="max_rating" />
                            </Datagrid>
                        </ReferenceManyField>
                    </AccordionFormPanel>
                    <AccordionFormPanel label="Testspiele">
                        <ReferenceManyField
                            sort={{ field: "name", order: "ASC" }}
                            filter={{ isOfficial: 0 }}
                            reference="match_stats_by_team"
                            source="id"
                            target="team_id"
                        >
                            <Datagrid>
                                <ReferenceField
                                    label="resources.matches.fields.player_id"
                                    source="player_id"
                                    reference="players"
                                    link={(record, reference) => `/${reference}/${record.player_id}/2`}
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                                <NumberField
                                    label="resources.matches.fields.assists"
                                    source="assists" />
                                <NumberField
                                    label="resources.matches.fields.goals"
                                    source="goals" />
                                <NumberField
                                    label="resources.matches.fields.yellow"
                                    source="yellow" />
                                <NumberField
                                    label="resources.matches.fields.time_played"
                                    source="time_played" />
                                <NumberField
                                    label="resources.matches.fields.starting"
                                    source="starting" />
                                <NumberField
                                    label="resources.matches.fields.ill"
                                    source="ill" />
                                <NumberField
                                    label="resources.matches.fields.injured"
                                    source="injured" />
                                <NumberField
                                    label="resources.matches.fields.substituted_in"
                                    source="substituted_in" />
                                <NumberField
                                    label="resources.matches.fields.substituted_out"
                                    source="substituted_out" />
                                <NumberField
                                    label="resources.matches.fields.avg_rating"
                                    source="avg_rating"
                                    options={{ maximumFractionDigits: 2 }} />
                                <NumberField
                                    label="resources.matches.fields.min_rating"
                                    source="min_rating" />
                                <NumberField
                                    label="resources.matches.fields.max_rating"
                                    source="max_rating" />
                            </Datagrid>
                        </ReferenceManyField>
                    </AccordionFormPanel>
                </AccordionForm>
            </Box>
        </FormTab>);
}