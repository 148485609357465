/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Edit,
  TabbedForm,
} from "react-admin";
import { TeamsDetailsMatchStatsTab } from "./tabs/TeamsDetailsMatchStatsTab";
import { TeamsDetailsTab } from "./tabs/TeamsDetailsTab";
import { TeamsDetailsTrainingStatsTab } from "./tabs/TeamsDetailsTrainingStatsTab";
import { TeamsDetailsWeeklyStatsTab } from "./tabs/TeamsDetailsWeeklyStatsTab";

export const TeamEdit = (props) => {
  const transform = (data) => {
    return {
      ...data,
      current: data.currentPlanning || data.currentSeason,
    };
  };
  return (
    <Edit transform={transform} title="resources.teams.pages.edit">
      <TabbedForm>
        <TeamsDetailsTab />
        <TeamsDetailsMatchStatsTab />
        <TeamsDetailsTrainingStatsTab />
        <TeamsDetailsWeeklyStatsTab />
      </TabbedForm>
    </Edit>
  );
};
