// @flow
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, grid, LineupItemContent } from './reorder';
import type { DraggableProvided } from 'react-beautiful-dnd';
import { LineupItemCard } from './LineupItemCard';

type Props = {
  quote: LineupItemContent;
  isDragging: boolean;
  provided: DraggableProvided;
  isClone?: boolean;
  isGroupedOver?: boolean;
  style?: Object;
  index?: number;
  classes?: Object;
};

const imageSize: number = 40;

//   background-color: ${(props) =>
//     getBackgroundColor(props.isGroupedOver)};
//   box-shadow: ${({ isDragging }) =>
//     isDragging ? `2px 2px 1px #666666` : 'none'};
const Container = styled.a`
  border-radius: ${borderRadius}px;
  border: 2px solid transparent;
  border-color: 'transparent';
  box-sizing: border-box;
  padding: ${grid}px;
  min-height: ${imageSize}px;
  margin-bottom: ${grid}px;
  user-select: none;

  /* anchor overrides */
  color: #777777;

  &:hover,
  &:active {
    color: #111111;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: #222222;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

function getStyle(provided: DraggableProvided, style?: Object) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function LineupItem(props: Props) {
  const { quote, isDragging, isGroupedOver, provided, style, isClone, index } = props;
  return (
    <Container
      //   href={quote.author.url}
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote.id}
      data-index={index}
      aria-label={`${quote.title}`}
    >
      <LineupItemCard
        id={quote.id}
        title={quote.title}
        subtitle={quote.subtitle}
        badge={quote.badge}
        photo={quote.photo}
        lineupstatus={quote.lineupstatus}
        playerstatus={quote.playerstatus}
        clubstatus={quote.clubstatus}
      />
    </Container>
  );
}

export default React.memo<Props>(LineupItem);
