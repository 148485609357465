/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import {
  ReferenceInput,
  Create,
  DateInput,
  required,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import { choicesFoot } from "../../types";

const transform = (data) => ({
  ...data,
  agegroup:
    data?.birthdate && data.birthdate?.length === 10
      ? `${data.birthdate.substr(0, 4)}`
      : `${data.agegroup}` !== "undefined"
      ? `${data.agegroup}`
      : "",
  birthdate:
    (!data?.birthdate || (data.birthdate && data.birthdate?.length === 4)) &&
    `${data.agegroup}` !== "undefined"
      ? `${data.agegroup}`
      : `${data.birthdate}`,
});

export const PlayerCreate: React.FC = (props) => (
  <Create redirect="edit" transform={transform} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <DateInput
        label="resources.players.fields.birthdate"
        source="birthdate"
      />
      <NumberInput
        max={2022}
        min={1980}
        step={1}
        label="resources.players.fields.agegroup"
        source="agegroup"
      />
      <TextInput label="resources.players.fields.city" source="city" />
      <SelectInput
        label="resources.players.fields.foot"
        source="foot"
        choices={choicesFoot}
      />
      <ReferenceInput
        label="resources.players.fields.currentClub"
        source="club_id"
        reference="clubs"
        perPage={5}
      >
        <AutocompleteInput
          noOptionsText="-"
          shouldRenderSuggestions={(val: any) => {
            return val.trim().length > 2;
          }}
          filterToQuery={(searchText: any) => ({
            "name@ilike": `%${searchText}%`,
          })}
          optionText="name"
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
