import { FC, useCallback } from "react";
import { Card, CardHeader, CardContent, IconButton } from "@mui/material";
import ExpandRightIcon from "@mui/icons-material/ChevronRight";
import {
  EditButton,
  TextField,
  useTranslate,
  RaRecord,
  ChipField,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  useListContext,
  RecordContextProvider,
  useRedirect,
} from "react-admin";
import { FullNameField } from "../../components/fields/FullNameField";
import { convertBirthdateToAge } from "../../types";
import { SquadplayerStatusField } from "../../components/fields/SquadplayerStatusField";
import { get } from "lodash";
import { PositionChipField } from "../../components/fields/PositionChipField";
import { FootField } from "../../components/fields/FootField";
import { styled } from "@mui/styles";

const PREFIX = "SquadplayerMobileGrid";
const classes = {
  card: `${PREFIX}-card`,
  cardTitleContent: `${PREFIX}-cardTitleContent`,
  cardContentRow: `${PREFIX}-cardContentRow`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.card}`]: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",
  },
  [`&.${classes.cardTitleContent}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`&.${classes.cardContentRow}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0.5rem 0",
  },
}));

const SquadplayerMobileGrid: FC = (props: { data?: RaRecord[] }) => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext();
  const redirect = useRedirect();
  const handleOpenDetails = useCallback(
    (id) => {
      redirect(`/squadplayers/${id}/show`);
    },
    [history]
  );
  if (isLoading) return null;
  return (
    <div style={{ margin: "1em" }}>
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card key={record.id} className={classes.card}>
            <CardHeader
              title={
                <div className={classes.cardTitleContent}>
                  <span>
                    <FullNameField
                      source="name"
                      label="resources.players.fields.name"
                      playerid="players_id"
                      sortBy="lineupstatus"
                      sourceRating="rating"
                      sourceAvatar="photo.src"
                    />
                  </span>
                  <EditButton resource="players" />
                  <IconButton onClick={() => handleOpenDetails(record.id)}>
                    <ExpandRightIcon />
                  </IconButton>
                </div>
              }
            />
            <CardContent>
              <span className={classes.cardContentRow}>
                {translate("resources.players.fields.birthdate")}:&nbsp;
                <FunctionField
                  sortBy="birthdate"
                  label="resources.players.fields.birthdate"
                  render={(record: any) =>
                    convertBirthdateToAge(record, "birthdate", "agegroup")
                  }
                />
              </span>
              <span className={classes.cardContentRow}>
                {translate("resources.players.fields.currentClub")}
                :&nbsp;
                <TextField
                  label="resources.players.fields.currentClub"
                  source="club_name"
                  sortBy="club_name"
                  emptyText="-"
                />
              </span>
              <span className={classes.cardContentRow}>
                {translate("resources.players.fields.city")}
                :&nbsp;
                <TextField
                  label="resources.players.fields.city"
                  source="city"
                  emptyText="-"
                />
              </span>
              <span className={classes.cardContentRow}>
                {translate("resources.squadplayers.fields.status")}
                :&nbsp;
                <SquadplayerStatusField label="resources.squadplayers.fields.status" />
              </span>
              {get(record, "foot") && (
                <span className={classes.cardContentRow}>
                  {translate("resources.players.fields.foot")}
                  :&nbsp;
                  <FootField
                    source="foot"
                    label="resources.players.fields.foot"
                  />
                </span>
              )}
              {get(record, "position_ids") && (
                <span className={classes.cardContentRow}>
                  {translate("resources.players.fields.positions")}
                  :&nbsp;
                  <ReferenceArrayField
                    label="resources.players.fields.positions"
                    source="position_ids"
                    reference="positions"
                    sortable={false}
                  >
                    <SingleFieldList>
                      <PositionChipField source="name" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </span>
              )}
              {get(record, "repteam_ids") && (
                <span className={classes.cardContentRow}>
                  {translate("resources.players.fields.repTeams")}
                  :&nbsp;
                  <ReferenceArrayField
                    label="resources.players.fields.repTeams"
                    source="repteam_ids"
                    reference="repteams"
                  >
                    <SingleFieldList>
                      <ChipField
                        clickable={false}
                        color="secondary"
                        variant="outlined"
                        source="slug"
                      />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </span>
              )}
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </div>
  );
};

SquadplayerMobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default SquadplayerMobileGrid;
