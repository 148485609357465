export const supabaseConfig = {
  projectRef: "lrhmxlreciaqyszkqtmk",
  url: "https://lrhmxlreciaqyszkqtmk.supabase.co",
  siteUrl: "https://youngstarsdb.scpreussen-muenster.de",
  postgrestUrl: "https://lrhmxlreciaqyszkqtmk.supabase.co/rest/v1",
  keycloakLogoutUrl:
    "https://lemur-0.cloud-iam.com/auth/realms/scpyoungstars/protocol/openid-connect/logout?client_id=scp-you2-supabase",
  apiKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxyaG14bHJlY2lhcXlzemtxdG1rIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDg5MDIxMTAsImV4cCI6MTk2NDQ3ODExMH0.NtnGFvV4T7qznXx9nvEBU-Z6A2DF_j5PAd57LpRhJtk",
};
