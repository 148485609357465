import { FormTab, TextInput, required, ReferenceInput, SelectInput, BooleanInput } from "react-admin";

export const TeamsDetailsTab = (props) => {
    return (
        <FormTab label="resources.teams.tabs.details" {...props}>
            <TextInput
                label="resources.teams.fields.name"
                source="name"
                validate={required()} />
            <TextInput
                label="resources.teams.fields.short"
                source="short"
                validate={required()} />
            <TextInput
                label="resources.teams.fields.dfbnetTeamName"
                source="dfbnetTeamName"
                validate={required()} />
            <ReferenceInput
                label="resources.teams.fields.ageclass"
                source="ageclass_id"
                reference="ageclasses"
                sort={{ field: "name", order: "ASC" }}
            >
                <SelectInput
                    optionText="name"
                    label="resources.teams.fields.ageclass"
                    translateChoice={false} />
            </ReferenceInput>
            <ReferenceInput
                label="resources.teams.fields.season"
                source="season_id"
                reference="seasons"
                sort={{ field: "name", order: "ASC" }}
            >
                <SelectInput optionText="name" translateChoice={false} />
            </ReferenceInput>
            <BooleanInput
                label="resources.teams.fields.currentSeason"
                source="currentSeason" />
            <BooleanInput
                label="resources.teams.fields.currentPlanning"
                source="currentPlanning" />
            <TextInput source="lastupdate" disabled={true} />
            <TextInput source="updatedby" disabled={true} />
        </FormTab>);
}