import React, { Fragment, useCallback, useMemo, useState } from "react";
import get from "lodash/get";
import { calculateMatchPlayerStatusValues } from "../../../types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconCancel from "@mui/icons-material/Cancel";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarRateIcon from "@mui/icons-material/StarRate";
import ThumbDown from "@mui/icons-material/ThumbDown";
import StyleIcon from "@mui/icons-material/Style";
import HealingIcon from "@mui/icons-material/Healing";
import TaskIcon from "@mui/icons-material/Task";
import {
  sanitizeListRestProps,
  useRefresh,
  SaveContextProvider,
  useNotify,
  useTranslate,
  useUpdate,
} from "ra-core";
import { Button, NumberInput, SimpleForm, TextInput } from "ra-ui-materialui";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  styled,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {
  ResourceContextProvider,
  SaveButton,
  RaRecord,
  useRecordContext,
} from "react-admin";

const PREFIX = "MatchPlayerStatusIcon";
const classes = {
  badge: `${PREFIX}-badge`,
  fab: `${PREFIX}-fab`,
  icon: `${PREFIX}-icon`,
  iconActive: `${PREFIX}-iconActive`,
  iconInactive: `${PREFIX}-iconInactive`,
  fabRating1: `${PREFIX}-fabRating1`,
  fabRating2: `${PREFIX}-fabRating2`,
  fabRating3: `${PREFIX}-fabRating3`,
  fabRating4: `${PREFIX}-fabRating4`,
  fabRating5: `${PREFIX}-fabRating5`,
  fabRating6: `${PREFIX}-fabRating6`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.badge}`]: {
    backgroundColor: "white",
    color: "grey",
  },
  [`&.${classes.fab}`]: {
    backgroundColor: "grey",
  },
  [`&.${classes.icon}`]: {
    color: "white",
  },
  [`&.${classes.iconActive}`]: {
    color: "#f44336",
  },
  [`&.${classes.iconInactive}`]: {
    color: "grey",
  },
  [`&.${classes.fabRating1}`]: {
    backgroundColor: "#006534",
    color: "white",
  },
  [`&.${classes.fabRating2}`]: {
    backgroundColor: "#00945e",
    color: "white",
  },
  [`&.${classes.fabRating3}`]: {
    backgroundColor: "#d2bf41",
    color: "white",
  },
  [`&.${classes.fabRating4}`]: {
    backgroundColor: "#f48536",
    color: "white",
  },
  [`&.${classes.fabRating5}`]: {
    backgroundColor: "#f44336",
    color: "white",
  },
  [`&.${classes.fabRating6}`]: {
    backgroundColor: "#940000",
    color: "white",
  },
}));

export const MatchPlayerStatusIcon: React.FC<{
  value: string | null;
  source: string;
  onClick: any;
  inputType: "number" | "text";
}> = ({ value, source, onClick, inputType }) => {
  const color = (inputType === "text" && typeof value === 'string' && value !== '') ? "#f44336" : "grey";
  const icons = {
    away: <ThumbDown sx={{ color: color }} />,
    injured: <HealingIcon sx={{ color: color }} />,
    substituted_out: <ArrowBackIcon classes={{ root: classes.icon }} />,
    substituted_in: <ArrowForwardIcon classes={{ root: classes.icon }} />,
    goals: <SportsSoccerIcon classes={{ root: classes.icon }} />,
    yellow: <StyleIcon classes={{ root: classes.icon }} />,
    assits: <StarHalfIcon classes={{ root: classes.icon }} />,
    time_played: <ScheduleIcon classes={{ root: classes.icon }} />,
    rating: <StarRateIcon classes={{ root: classes.icon }} />,
    task: <TaskIcon classes={{ root: classes.icon }} />,
  };
  const buttonForTextInput = <Button onClick={onClick}>{icons[source]}</Button>;
  const textInput = value ? (
    <Tooltip placement="top" arrow title={value}>
      <div>{buttonForTextInput}</div>
    </Tooltip>
  ) : (
    buttonForTextInput
  );
  const className =
    source === "rating" ? classes[`fabRating${value}`] : classes.fab;
  return inputType === "number" ? (
    <Fab
      onClick={onClick}
      variant="extended"
      size="small"
      classes={{ root: className }}
    >
      {icons[source]}
      {value}
    </Fab>
  ) : (
    textInput
  );
};

const QuickCreateToolbar = ({ submitting, onCancel, ...props }) => {
  const { mutationMode, validating, ...rest } = props;
  return (
    <Toolbar>
      <SaveButton {...sanitizeListRestProps(rest)} />
      <Button label="ra.action.cancel" onClick={onCancel}>
        <IconCancel />
      </Button>
    </Toolbar>
  );
};

const QuickUpdateForm = ({
  inputType,
  match,
  handleSubmitWithRedirect,
  onCancel,
  onSave,
  source,
  id,
  ...props
}) => {
  const [update] = useUpdate();
  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [saving] = useState();
  const handleSave = useCallback(
    (values) => {
      const newValues = calculateMatchPlayerStatusValues(
        values,
        source,
        record,
        match,
        notify
      );
      update(
        "matches_players",
        { id, data: newValues, previousData: values },
        {
          mutationMode: "pessimistic",
          onSuccess: () => {
            onSave(values);
            refresh();
            notify(
              "resources.matches.notification.match_player_status_update",
              {
                type: "info",
                undoable: false,
              }
            );
          },
          onError: () => {
            notify(
              "resources.matches.notification.match_player_status_update_error",
              {
                type: "warning",
                undoable: false,
              }
            );
          },
        }
      );
    },
    [id, match, source, notify, onSave, record, update]
  );
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        toolbar={
          <QuickCreateToolbar
            handleSave={handleSave}
            onCancel={onCancel}
            submitting={saving}
          />
        }
        sx={{ padding: 0 }}
        {...props}
      >
        {inputType === "number" ? (
          <NumberInput
            label={`resources.matches.fields.${source}`}
            source={source}
            autoFocus
          />
        ) : (
          <TextInput
            label={`resources.matches.fields.${source}`}
            source={source}
            autoFocus
          />
        )}
      </SimpleForm>
    </SaveContextProvider>
  );
};

export const MatchPlayerStatusEditButton: React.FC<{
  source: string;
  label?: string;
  disabled?: boolean;
  match: RaRecord;
  inputType: "number" | "text";
}> = ({ source, disabled, match, inputType }) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const handleNewClick = useCallback(
    (event) => {
      event.preventDefault();
      setShowUpdateDialog(true);
    },
    [setShowUpdateDialog]
  );
  const handleCloseCreate = useCallback(() => {
    setShowUpdateDialog(false);
  }, [setShowUpdateDialog]);
  const handleSave = useCallback(
    (post) => {
      setShowUpdateDialog(false);
    },
    [setShowUpdateDialog]
  );
  return (
    <Fragment>
      <ResourceContextProvider value="matches_players">
        <>
          {record ? (
            <MatchPlayerStatusIcon
              onClick={disabled ? null : handleNewClick}
              value={get(record, source)}
              inputType={inputType}
              source={source}
            />
          ) : null}
          {disabled || !record ? null : (
            <Dialog
              fullWidth
              open={showUpdateDialog}
              onClose={handleCloseCreate}
            >
              <DialogTitle>
                {translate(`resources.matches.fields.${source}`)}{" "}
                {translate("resources.matches.actions.update_value")}
              </DialogTitle>
              <DialogContent>
                <QuickUpdateForm
                  inputType={inputType}
                  onCancel={handleCloseCreate}
                  onSave={handleSave}
                  handleSubmitWithRedirect={handleSave}
                  source={source}
                  record={record}
                  match={match}
                  basePath={`/matches/${record.match_id}/1`}
                  id={record.id}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      </ResourceContextProvider>
    </Fragment>
  );
};
