import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import { Badge } from "@mui/material";
import get from "lodash/get";
import { useMixedRatingStyles } from "../../types";
import { FieldProps, useRecordContext, useTranslate } from "react-admin";

type RatingBadgeProps = {
  rating?: string;
  output: any;
};

export const RatingBadge: React.FC<RatingBadgeProps> = (props) => {
  const { rating, output } = props;
  const classes = useMixedRatingStyles();
  const translate = useTranslate();
  if (rating) {
    const translated = translate(`resources.rating.${rating}`);
    const ratingUppercase = translated.slice(0, 1);
    const ratingColor = classes[rating];
    return (
      <Badge
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiBadge-badge": {
            bgcolor: ratingColor.bgcolor,
            color: ratingColor.color,
          },
        }}
        overlap="circular"
        badgeContent={`${ratingUppercase}`}
      >
        {output}
      </Badge>
    );
  }
};

type AvatarFieldProps = {
  className?: string;
  size?: string;
  recordSourceRating?: string;
  recordSourceAvatar?: string;
  recordSourceJersey?: string;
  addLabel?: boolean;
};

const AvatarFieldWithoutStyle: React.FC<FieldProps & AvatarFieldProps> = ({
  size,
  className,
  recordSourceRating,
  recordSourceAvatar,
  recordSourceJersey,
}) => {
  const record = useRecordContext();
  if (record && recordSourceAvatar) {
    const avatar = get(record, recordSourceAvatar);
    const jerseyNo = get(record, recordSourceJersey);
    let jerseyColor = "",
      jerseyColorRGB = "";
    if (jerseyNo) {
      jerseyColor = jerseyNo.substring(jerseyNo.length - 1);
      jerseyColorRGB = jerseyColor === "S" ? "#000000" : "";
      jerseyColorRGB = jerseyColor === "G" ? "#006534" : jerseyColorRGB;
      jerseyColorRGB = jerseyColor === "r" ? "#cf0000" : jerseyColorRGB;
      jerseyColorRGB = jerseyColor === "g" ? "#cfcf00" : jerseyColorRGB;
    }
    let output = jerseyNo ? (
      <Avatar
        className={className}
        sx={{ bgcolor: jerseyColorRGB, fontSize: "1.0rem" }}
      >
        {jerseyNo.replace(jerseyColor, "").trim()}
      </Avatar>
    ) : (
      <Avatar
        src={`${avatar}?size=25x25`}
        sizes={size}
        style={{
          width: size,
          height: size,
          position: "relative",
        }}
        className={className}
      />
    );

    if (recordSourceRating && get(record, recordSourceRating)) {
      output = (
        <RatingBadge rating={get(record, recordSourceRating)} output={output} />
      );
    }
    return output;
  }
  return <div />;
};

export const AvatarField = AvatarFieldWithoutStyle;

AvatarField.defaultProps = {
  addLabel: true,
  size: "25",
  className: "avatar",
  recordSourceRating: "rating",
  recordSourceAvatar: "photo.src",
  recordSourceJersey: "jerseyNo",
};

AvatarField.propTypes = {
  // record: PropTypes.object.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  addLabel: PropTypes.bool,
  recordSourceRating: PropTypes.string,
  recordSourceAvatar: PropTypes.string,
  recordSourceJersey: PropTypes.string,
};
