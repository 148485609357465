import React from 'react';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import {
  ChipField,
  DateField,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
  useTranslate,
} from 'react-admin';

const emailTextApplication = `,

Vielen Dank für Deine Bewerbung beim SC Preußen Münster!

Leider können wir Dir aktuell kein Probetraining anbieten, wobei wir uns, sobald Bedarf bestehen sollte, bei Dir melden werden.

Ich wünsche Dir alles Gute,

"Trainer"`;

const emailTextTalentdayRejection = `,
 
vielen Dank für Deine Bewerbung beim Preußen-Talenttag. Wir haben bisher über 200 Bewerbungen für unseren Talenttag erhalten, deshalb hat die Sichtung der Unterlagen teilweise etwas länger gedauert.
Wir müssen Dir leider mitteilen, dass es in diesem Jahr für Dich zunächst nicht zu einer Teilnahme an unserem Talenttag gereicht hat.
 
Du befindest dich aber zur Zeit auf unserer internen Warteliste. Gerne laden wir Dich ein, sobald ein eingeladener Spieler absagt. Solltest Du dies nicht wünschen, sende bitte eine Nachricht an talenttag@scpreussen-muenster.de
 
Für Deinen weiteren fußballerischen Werdegang wünschen wir Dir alles Gute und freuen uns schon jetzt auf Deine Bewerbung im nächsten Jahr.
 
Eventuelle Rückfragen richtest du bitte an folgende Mail-Adresse: talenttag@scpreussen-muenster.de
 
Mit freundlichen Grüßen
 
Dein Preußen Münster-Talenttag-Team`;

const emailTextTalentdayApproval = `,

vielen Dank für Deine Bewerbung beim Preußen-Talenttag. Wir haben bisher über 200 Bewerbungen für unseren Talenttag erhalten, deshalb hat die Sichtung der Unterlagen etwas länger gedauert. Deine Bewerbung wurde mit unseren Trainern besprochen und wir haben uns dafür entschieden, Dich einzuladen! Dazu herzlichen Glückwunsch!
 
Unser Check-In für den Talenttag startet um 8.45 Uhr. Das Training beginnt um 9.30 Uhr; bitte sei mindestens 20 Minuten vor Trainingsbeginn anwesend, sodass Du genug Zeit zum Umziehen hast. Das Training findet auf dem Gelände der Preußen Youngstars: Am Berg Fidel 51 in Münster statt.
Eine Anfahrtsskizze hängt diesem Schreiben an.
 
Um an der Trainingseinheit teilzunehmen, benötigen wir von Dir zunächst eine gültige Probetrainingsbescheinigung Deines aktuellen Vereins und die unterschriebene Datenschutzerklärung. Wenn Du diese noch nicht per Mail eingereicht hast, gib diese bitte am Anmelde-Pavillon zu Beginn ab. WICHTIG: Solltest Du die Bestätigung nicht vorlegen können, so dürfen wir Dich leider nicht mittrainieren lassen! Bitte denke ebenfalls an wettergerechte Trainingskleidung und kunstrasenfähige Fußballschuhe. Ein wenig Verpflegung und Getränke werden von uns gestellt. Außerdem erhältst Du von uns kostenlos ein Preußen-Talenttag-Shirt mit individueller Rückennummer. Dieses behältst Du bitte zur Identifikation den gesamten Tag an. Hinterher darfst Du das Shirt natürlich mit nach Hause nehmen.
 
Wir werden während des Talenttags mit Dir einige Trainingsübungen machen. Du musst Dich darauf natürlich nicht vorbereiten - sei einfach fit und ausgeruht. Die Trainer unserer jüngsten Preußen-Mannschaften leiten das Training und bewerten euch gemeinsam mit einigen weiteren Preußen-Trainern aus dem Jugendbereich.
Da wir bereits Deine Kontaktdaten haben, melden wir uns bei Dir, sofern wir Dich gerne erneut einladen möchten. Wir können nicht jedem unserer Teilnehmer eine Rückmeldung geben, sei in diesem Falle bitte nicht enttäuscht! Bereits die Einladung zum Preußen-Talenttag ist ein großer Erfolg und wir werden Deinen weiteren fußballerischen Weg mit Sicherheit auch in Zukunft verfolgen.
 
Wir wünschen Dir jetzt schon mal viel Erfolg für den Preußen-Talenttag und hoffen, dass Du bald ein Teil von uns bist.
Eventuelle Rückfragen richtest du bitte an folgende Mail-Adresse: talenttag@scpreussen-muenster.de
 
 
Mit freundlichen Grüßen
 
Dein Preußen Münster-Talenttag-Team`;

const generateLink = (label: string, subject: string, parentsEmail: string, firstName: string, text: string) => (
  <>
    <label
      style={{ position: 'relative' }}
      className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense"
    >
      <span>{label}</span>
    </label>
    <a
      href={`mailto:${parentsEmail}?bcc=mirco.agnischock@scpreussen-muenster.de&subject=${subject}&body=Lieber%20${firstName},${encodeURIComponent(
        text
      )}`}
    >
      {parentsEmail}
    </a>
  </>
);

const applicationParentsEmail = (record: any, translate) => (
  <FunctionField
    render={(record: any) =>
      record.parentsEmail
        ? generateLink(
            translate('resources.applications.fields.parentsEmail'),
            'Bewerbung SC Preußen Münster',
            record.parentsEmail,
            record.name.split(' ')[0] || 'Vorname',
            emailTextApplication
          )
        : ''
    }
    addLabel={true}
    label="resources.applications.fields.parentsEmail"
    emptyText="-"
  />
);

const talentdayParentsEmailAppoval = (record: any, translate) => (
  <FunctionField
    render={(record: any) =>
      record.parentsEmail
        ? generateLink(
            translate('resources.applications.fields.parentsEmailAppoval'),
            'Anmeldung Talenttag SC Preußen Münster',
            record.parentsEmail,
            record.name.split(' ')[0] || 'Vorname',
            emailTextTalentdayApproval
          )
        : ''
    }
    addLabel={true}
    label="resources.applications.fields.parentsEmailAppoval"
    emptyText="-"
  />
);

const talentdayParentsEmailRejection = (record, translate) => (
  <FunctionField
    render={(record: any) =>
      record.parentsEmail
        ? generateLink(
            translate('resources.applications.fields.parentsEmailRejection'),
            'Deine Anmeldung zum Talenttag (Absage/Warteliste)',
            record.parentsEmail,
            record.name.split(' ')[0] || 'Vorname',
            emailTextTalentdayRejection
          )
        : ''
    }
    addLabel={true}
    label="resources.applications.fields.parentsEmailRejection"
    emptyText="-"
  />
);

const ApplicationTitle: React.FC<{ record: { name: string } }> = props => {
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.applications.name')}
      {props.record ? ` "${props.record.name}"` : ''}
    </span>
  );
};

export const ApplicationShow = (props: any) => {
  const { record } = useShowController(props);
  const translate = useTranslate();

  if (!record) return null;
  const parentsEmail =
    record.type && record.type === 'application'
      ? applicationParentsEmail(record, translate)
      : talentdayParentsEmailAppoval(record, translate);
  const parentsEmailReject = record.type !== 'application' ? talentdayParentsEmailRejection(record, translate) : null;

  return (
    <Show title={<ApplicationTitle {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="resources.applications.tabs.player" key="player">
          <BoxedShowLayout>
            <RaBox display="flex">
              <RaBox flex="33%">
                <TextField source="name" label="resources.applications.fields.name" emptyText="-" />
                <DateField source="birthdate" label="resources.applications.fields.birthdate" emptyText="-" />
                <TextField source="placeOfBirth" label="resources.applications.fields.placeOfBirth" emptyText="-" />
                <TextField source="nationality" label="resources.applications.fields.nationality" emptyText="-" />
              </RaBox>
              <RaBox flex="33%">
                <TextField source="address" label="resources.applications.fields.address" emptyText="-" />
                <TextField source="postal" label="resources.applications.fields.postal" emptyText="-" />
                <TextField source="city" label="resources.applications.fields.city" emptyText="-" />
                <TextField source="mobile" label="resources.applications.fields.mobile" emptyText="-" />
                <TextField source="emailPlayer" label="resources.applications.fields.emailPlayer" emptyText="-" />
              </RaBox>
              <RaBox flex="33%">
                <ChipField source="foot" label="resources.applications.fields.foot" emptyText="-" />
                <TextField source="height" label="resources.applications.fields.height" emptyText="-" />
                <TextField source="weight" label="resources.applications.fields.weight" emptyText="-" />
                <TextField source="favPosition" label="resources.applications.fields.favPosition" emptyText="-" />
                <TextField source="favPlayer" label="resources.applications.fields.favPlayer" emptyText="-" />
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        </Tab>
        <Tab label="resources.applications.tabs.career" key="career">
          <BoxedShowLayout>
            <RaBox display="flex">
              <RaBox flex="33%">
                <TextField source="currentClub" label="resources.applications.fields.currentClub" emptyText="-" />
                <TextField source="currentLeague" label="resources.applications.fields.currentLeague" emptyText="-" />
              </RaBox>
              <RaBox flex="33%">
                <DateField source="clubSince" label="resources.applications.fields.clubSince" emptyText="-" />
                <ChipField source="clubPosition" label="resources.applications.fields.clubPosition" emptyText="-" />
              </RaBox>
              <RaBox flex="33%">
                <TextField source="prevClubs" label="resources.applications.fields.prevClubs" emptyText="-" />
                <TextField source="dfbLocation" label="resources.applications.fields.dfbLocation" emptyText="-" />
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        </Tab>
        <Tab label="resources.applications.tabs.parents" key="parents">
          <BoxedShowLayout>
            <RaBox display="flex">
              <RaBox flex="25%">
                <TextField source="parentsName" label="resources.applications.fields.parentsName" emptyText="-" />
              </RaBox>
              <RaBox flex="25%">
                <TextField source="parentsPhone" label="resources.applications.fields.parentsPhone" emptyText="-" />
              </RaBox>
              <RaBox flex="25%">
                <>{parentsEmail}</>
              </RaBox>
              <RaBox flex="25%">
                <>{parentsEmailReject}</>
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        </Tab>
        <Tab label="resources.applications.tabs.school" key="school">
          <BoxedShowLayout>
            <RaBox display="flex">
              <RaBox flex="50%">
                <TextField source="schoolType" label="resources.applications.fields.schoolType" emptyText="-" />
                <TextField source="schoolClass" label="resources.applications.fields.schoolClass" emptyText="-" />
              </RaBox>
              <RaBox flex="50%">
                <TextField source="schoolName" label="resources.applications.fields.schoolName" emptyText="-" />
                <TextField source="schoolLocation" label="resources.applications.fields.schoolLocation" emptyText="-" />
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        </Tab>
        <Tab label="resources.applications.tabs.self" key="self">
          <BoxedShowLayout>
            <RaBox display="flex">
              <RaBox flex="50%">
                <TextField source="strength" label="resources.applications.fields.strength" emptyText="-" />
                <TextField source="scpWhy" label="resources.applications.fields.scpWhy" emptyText="-" />
              </RaBox>
              <RaBox flex="50%">
                <TextField source="weakness" label="resources.applications.fields.weakness" emptyText="-" />
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        </Tab>
        <Tab label="resources.applications.tabs.club" key="club">
          <BoxedShowLayout>
            <RaBox display="flex">
              <RaBox flex="50%">
                <TextField source="clubRepName" label="resources.applications.fields.clubRepName" emptyText="-" />
                <TextField
                  source="clubRepFunction"
                  label="resources.applications.fields.clubRepFunction"
                  emptyText="-"
                />
              </RaBox>
              <RaBox flex="50%">
                <TextField source="clubRepPhone" label="resources.applications.fields.clubRepPhone" emptyText="-" />
                <TextField source="clubRepEmail" label="resources.applications.fields.clubRepEmail" emptyText="-" />
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
