import * as React from 'react';
import { TextField, MenuItem, Box } from '@mui/material';
import { SCP_CLUB_ID, ratings, resolveNoteTypeAttributes, scoutingReviews } from '../../types';
import { useTranslate } from 'react-admin';

export const Rating = ({ value }: { value: string }) => {
    return (
    <Box
        marginLeft={0.5}
        width={10}
        height={10}
        display="inline-block"
        borderRadius="8px"
        bgcolor={'#000'}
        component="span"
    />
)};

export const RatingSelectField = ({ rating, player, setRating, sx, type }: any) => {
    const translate = useTranslate();
    const typeAttributes = resolveNoteTypeAttributes(type);
    if(!player) return null;
    return (
    <TextField
        select
        value={rating}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            console.log('setRating', { value: event.target.value, rating});
            setRating(event.target.value);
        }}
        variant="filled"
        label={false}
        margin="none"
        size="small"
        sx={sx}
    >
        { typeAttributes.resource === 'reviews' && player?.club_id === SCP_CLUB_ID && ratings.map((rating) => {
            if(Number.parseInt(rating) % 10 == 0){
                const label = translate(`resources.rating.${rating}`)
                return (
                    <MenuItem key={rating} value={rating}>
                    <Box>{label} </Box>{/* <Rating value={rating} /> */}
                    </MenuItem>);
            }
            })
        }
        { typeAttributes.resource === 'reviews' && player?.club_id !== SCP_CLUB_ID && ratings.map((rating) => {
            if(Number.parseInt(rating) % 10 !== 0){
                const label = translate(`resources.rating.${rating}`)
                return (
                    <MenuItem key={rating} value={rating}>
                    <Box>{label} </Box>{/* <Rating value={rating} /> */}
                    </MenuItem>);
            }
            })
        }
        { typeAttributes.resource === 'scoutings' && scoutingReviews.map((rating) => {
                const label = translate(`resources.scoutingReview.${rating}`)
                return (
                    <MenuItem key={rating} value={rating}>
                        <Box> {label} </Box>{/* <RatingIcon rating={Number.parseInt(rating)} /> */}
                    </MenuItem>);
            })
        }
    </TextField>
);
}
