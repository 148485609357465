import { FC } from "react";
import get from "lodash/get";
import {
  FieldProps,
  FunctionField,
  RaRecord,
  useRecordContext,
  useTranslate,
} from "react-admin";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import ContactPhoneIcon from "@mui/icons-material/Call";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import ContractOfferedIcon from "@mui/icons-material/Assignment";
import ContractIcon from "@mui/icons-material/AssignmentTurnedIn";
import AppEnabledIcon from '@mui/icons-material/MobileFriendly';
import {
  Chip,
  Tooltip,
  Box,
  IconButton,
  useMediaQuery,
  Theme,
} from "@mui/material";

export const StatusIcon = ({ icon, label }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const translatedLabel = translate(`resources.squadplayers.actions.${label}`);
  return isXSmall ? (
    <Chip
      sx={{ margin: 1 }}
      icon={icon}
      label={translatedLabel}
      color="primary"
      variant="outlined"
    />
  ) : (
    <Tooltip title={translatedLabel} aria-label={translatedLabel}>
      <IconButton aria-label={translatedLabel}>{icon}</IconButton>
    </Tooltip>
  );
};

export const SquadplayerStatusField = ({ label }) => {
  const record = useRecordContext();
  const {
    contacted,
    contractOffered,
    hasContract,
    appEnabled,
    clubInformed,
    agentInformed,
    trialexercise,
    playerstatus,
    clubstatus,
  } = record;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const classesSmall = {
    alignItems: "center",
  };
  const classesLarge = {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  };
  const classes = isXSmall ? classesSmall : classesLarge;
  return (
    <FunctionField
      label={label}
      render={(record) => (
        <Box sx={classes}>
          {agentInformed && (
            <StatusIcon icon={<ContactEmergencyIcon />} label="agentInformed" />
          )}
          {clubInformed && (
            <StatusIcon icon={<MailOutlineIcon />} label="clubInformed" />
          )}
          {contacted && (
            <StatusIcon icon={<ContactPhoneIcon />} label="contacted" />
          )}
          {trialexercise && (
            <StatusIcon icon={<SportsSoccerIcon />} label="trialexercise" />
          )}
          {contractOffered && (
            <StatusIcon
              icon={<ContractOfferedIcon />}
              label="contractOffered"
            />
          )}
          {hasContract && (
            <StatusIcon icon={<ContractIcon />} label="hasContract" />
          )}
          {appEnabled && (
            <StatusIcon icon={<AppEnabledIcon />} label="appEnabled" />
          )}
          {clubstatus === "yes" && (
            <StatusIcon
              icon={<ThumbUpIcon style={{ color: "#00945e" }} />}
              label="club_yes"
            />
          )}
          {clubstatus === "no" && (
            <StatusIcon
              icon={<ThumbDownIcon style={{ color: "#f44336" }} />}
              label="club_no"
            />
          )}
          {playerstatus === "yes" && (
            <StatusIcon
              icon={<PersonAddIcon style={{ color: "#00945e" }} />}
              label="player_yes"
            />
          )}
          {playerstatus === "no" && (
            <StatusIcon
              icon={<PersonAddDisabledIcon style={{ color: "#f44336" }} />}
              label="player_no"
            />
          )}
        </Box>
      )}
    />
  );
};
