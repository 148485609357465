/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { FC } from "react";
import { styled } from "@mui/material";
import get from "lodash/get";
import { Link, FieldProps, RaRecord, useRecordContext } from "react-admin";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
}));

export const NameField: FC<
  FieldProps & {
    source: string;
    label?: string;
    player_id?: string;
  }
> = (props) => {
  const record = useRecordContext();
  const { source, player_id } = props;
  const recordId = player_id || "player_id";
  if (!record) {
    return null;
  }
  return (
    <Wrapper>
      <Link to={`/players/${get(record, recordId)}`}>
        {get(record, source)}
      </Link>
    </Wrapper>
  );
};
