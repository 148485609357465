import {
    Edit,
    TextInput,
    SimpleForm,
    required,
    usePermissions,
    useGetIdentity,
} from 'react-admin';
import React from 'react';

export const ClubEdit: React.FC = props => {
    const {
        isLoading,
        data: {
            idTokenClaims: { roles },
        },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading) return null;
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name" validate={required()} />
                {allowedAccess && (
                    <TextInput
                        label="resources.clubs.fields.arrangements"
                        source="arrangements"
                        multiline
                        minRows={10}
                    />
                )}
            </SimpleForm>
        </Edit>
    );
};
