import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Button,
  useUpdateMany,
  useNotify,
  useUnselectAll,
  useRefresh,
  useListContext,
  Identifier,
} from "react-admin";

const noSelection: Identifier[] = [];

export const BulkSquadplayerStatus: React.FC<{
  field: "scoutingstatus" | "lineupstatus"; // | "clubstatus" | "playerstatus"
  value:
    | "notplanned"
    | "open"
    | "planned"
    | "member"
    | "scouted"
    | "notscouted"; //| "no"| "yes"
}> = ({ field, value }) => {
  const { selectedIds = noSelection } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("squadplayers");
  const [updateMany, { isLoading }] = useUpdateMany();
  const icons = {
    lineup_planned: <ThumbUp />,
    lineup_notplanned: <ThumbDownIcon />,
    lineup_open: <ThumbsUpDownIcon />,
    scouting_member: <VerifiedUserIcon />,
    scouting_scouted: <ZoomInIcon />,
    scouting_open: <SearchIcon />,
  };
  const status = `${field.replace("status", "")}_${value}`;
  const handleOnClick = () => {
    updateMany(
      "teams_players",
      {
        ids: selectedIds,
        data: {
            [field]: value,
        },
      },
      {
        mutationMode: "pessimistic",
        onSettled: () => {
          notify(`resources.squadplayers.notification.${value}`);
          unselectAll();
          refresh();
        },
        onError: () => {
          notify("resources.squadplayers.notification.error", {
            type: "warning",
          });
        },
      }
    );
  };
  return (
    <Button
      label={`resources.squadplayers.actions.${status}`}
      onClick={handleOnClick}
      disabled={isLoading}
    >
      {icons[status]}
    </Button>
  );
};
