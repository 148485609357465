import React, { Fragment } from "react";
import {
  Datagrid,
  DeleteButton,
  FormDataConsumer,
  FormTab,
  FunctionField,
  Link,
  RaRecord,
  ReferenceManyField,
  useGetOne,
  useTranslate,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { TrainingPlayerStatusEditButton } from "../buttons/TrainingPlayerStatusEditButton";
import { get } from "lodash";
import { TrainingPlayerStatusField } from "../buttons/TrainingPlayerStatusField";
import { AddTrainingPlayersButton } from "../buttons/AddTrainingPlayersButton";
import { DeleteTrainingPlayersButton } from "../buttons/DeleteTrainingPlayersButton";
import { AddPlayerButton } from "../../matches/buttons/AddPlayerButton";

const TrainingPresenceDataGrid = (props) => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const [, , training_id] = pathname.split("/");

  const { data: stats, isLoading } = useGetOne(
    "trainings_players_by_training",
    { id: training_id }
  ) || {
    data: {
      total: 0,
      present: 0,
      ill: 0,
      injured: 0,
      away: 0,
      light: 0,
    },
    loaded: false,
  };
  return !isLoading ? (
    <Datagrid>
      <FunctionField
        label={`${translate("resources.trainings.fields.name")} (${get(
          stats,
          "total"
        )})`}
        source="name"
        render={(record) => (
          <Link to={`/players/${get(record, "player_id")}/2`}>
            {get(record, "name")}
          </Link>
        )}
      />
      <TrainingPlayerStatusField
        label={`${translate("resources.trainings.fields.present")} (${get(
          stats,
          "present"
        )})`}
        source="present"
      />
      <TrainingPlayerStatusEditButton
        label={`${translate("resources.trainings.fields.light")} (${get(
          stats,
          "light"
        )})`}
        source="light"
      />
      <TrainingPlayerStatusField
        label={`${translate("resources.trainings.fields.ill")} (${get(
          stats,
          "ill"
        )})`}
        source="ill"
      />
      <TrainingPlayerStatusEditButton
        label={`${translate("resources.trainings.fields.away")} (${get(
          stats,
          "away"
        )})`}
        source="away"
      />
      <TrainingPlayerStatusEditButton
        label={`${translate("resources.trainings.fields.injured")} (${get(
          stats,
          "injured"
        )})`}
        source="injured"
      />
      <DeleteButton
        mutationMode="optimistic"
        resource="trainings_players"
        redirect={pathname}
        label=""
      />
    </Datagrid>
  ) : null;
};

export const TrainingPresenceFormTab: React.FC = (props) => {
  return (
    <FormTab
      {...props}
      label="resources.trainings.tabs.presence"
      path="presence"
    >
      <ReferenceManyField
        sort={{ field: "name", order: "ASC" }}
        perPage={50}
        reference="trainings_players"
        source="id"
        target="training_id"
      >
        <TrainingPresenceDataGrid {...props} />
      </ReferenceManyField>
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData: record }) =>
          record.id && (
            <Fragment>
              <AddTrainingPlayersButton  {...props} />
              <DeleteTrainingPlayersButton {...props} />
              <AddPlayerButton
                type="trainings_players"
                {...props}
              />
            </Fragment>
          )
        }
      </FormDataConsumer>
    </FormTab>
  );
};
