import { FormTab, ReferenceManyField, Datagrid, ReferenceField, TextField, NumberField } from "react-admin";

export const TeamsDetailsTrainingStatsTab = (props) => {
    return (
        <FormTab
            label="resources.teams.tabs.training_stats"
            path="training_stats"
            {...props}
        >
            <ReferenceManyField
                sort={{ field: "name", order: "ASC" }}
                perPage={50}
                reference="training_stats_by_team"
                source="id"
                target="team_id"
            >
                <Datagrid>
                    <ReferenceField
                        label="resources.matches.fields.player_id"
                        source="player_id"
                        reference="players"
                        link={(record, reference) => `/${reference}/${record.player_id}/2`}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField
                        label="resources.trainings.fields.num_trainings"
                        source="num_trainings" />
                    <NumberField
                        label="resources.trainings.fields.present"
                        source="present" />
                    <NumberField
                        label="resources.trainings.fields.light"
                        source="light" />
                    <NumberField
                        label="resources.trainings.fields.rate_presence"
                        source="rate_presence"
                        locales="de-DE"
                        options={{ style: "percent" }} />
                    <NumberField
                        label="resources.trainings.fields.ill"
                        source="ill" />
                    <NumberField
                        label="resources.trainings.fields.injured"
                        source="injured" />
                    <NumberField
                        label="resources.trainings.fields.away"
                        source="away" />
                </Datagrid>
            </ReferenceManyField>
        </FormTab>
    );
}