import React, { useCallback, useMemo, useState } from "react";
import IconCancel from "@mui/icons-material/Cancel";
import {
  useTranslate,
  useNotify,
  useDataProvider,
  useRefresh,
  useCreate,
  SaveButton,
  SaveContextProvider,
  Toolbar,
  required,
  sanitizeListRestProps,
  useRecordContext,
} from "react-admin";
import AddPlayerIcon from "@mui/icons-material/PersonAdd";
import { Dialog, DialogTitle, DialogContent, makeStyles } from "@mui/material";
import {
  AutocompleteInput,
  Button as ReactAdminButton,
  ReferenceInput,
  SimpleForm,
} from "ra-ui-materialui";
import { get } from "lodash";
import { convertBirthdateToAge } from "../../../types";
import Button from "@mui/material/Button";

const QuickCreateToolbar = ({ submitting, onCancel, ...props }) => {
  const { mutationMode, validating, ...rest } = props;
  return (
    <Toolbar>
      <SaveButton {...sanitizeListRestProps(rest)} />
      <ReactAdminButton label="ra.action.cancel" onClick={onCancel}>
        <IconCancel />
      </ReactAdminButton>
    </Toolbar>
  );
};

const QuickUpdateForm = ({
  setSubmitting,
  onCancel,
  onSave,
  id,
  type,
  record,
  ...props
}) => {
  const { team_id } = record;
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [saving] = useState();
  const dataProvider = useDataProvider();
  const handleSave = useCallback(
    async ({ player_id }) => {
      setSubmitting(true);
      const { data: player } = await dataProvider.getOne("players", {
        id: player_id,
      });
      let data = {};
      if (type === "matches_players") {
        data = {
          name: player.name,
          player_id: player_id,
          team_id: team_id,
          match_id: id,
        };
        create("matches_players", { data });
      } else if (type === "trainings_players") {
        data = {
          name: player.name,
          team_id: team_id,
          player_id: player_id,
          training_id: id,
          present: 1,
        };
        create("trainings_players", { data });
      }
      refresh();
      notify("resources.matches.notification.player_added", {
        type: "info",
        undoable: false,
      });
      refresh();
    },
    [dataProvider, id]
  );
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        toolbar={
          <QuickCreateToolbar
            handleSave={handleSave}
            onCancel={onCancel}
            submitting={saving}
          />
        }
        sx={{ "& .RaForm-form": { padding: 0 } }}
        {...props}
      >
        <ReferenceInput
          label="resources.squadplayers.fields.player"
          source="player_id"
          reference="players"
          validate={[required()]}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            optionValue="id"
            filterToQuery={(searchText) => ({
              "name@ilike": `%${searchText}%`,
            })}
            noOptionsText="-"
            shouldRenderSuggestions={(val: any) => {
              return val.trim().length > 2;
            }}
            optionText={(choice) => {
              const birthdate = convertBirthdateToAge(
                choice,
                "birthdate",
                "agegroup"
              );
              return `${get(choice, "name")} - ${birthdate}`;
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </SaveContextProvider>
  );
};

export const AddPlayerButton: React.FC<{
  type: "matches_players" | "trainings_players";
}> = (props) => {
  const { type } = props;
  const record = useRecordContext();
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const translate = useTranslate();
  const handleNewClick = useCallback(
    (event) => {
      event.preventDefault();
      setShowUpdateDialog(true);
    },
    [setShowUpdateDialog]
  );
  const handleCloseCreate = useCallback(() => {
    setShowUpdateDialog(false);
  }, [setShowUpdateDialog]);
  const handleSave = useCallback(
    (post) => {
      setShowUpdateDialog(false);
    },
    [setShowUpdateDialog]
  );
  return (
    <>
      <Button onClick={handleNewClick} disabled={submitting}>
        <AddPlayerIcon />
        {translate("resources.matches.actions.player_add")}
      </Button>
      <Dialog fullWidth open={showUpdateDialog} onClose={handleCloseCreate}>
        <DialogTitle>
          {translate("resources.matches.actions.player_add")}
        </DialogTitle>
        <DialogContent>
          <QuickUpdateForm
            onCancel={handleCloseCreate}
            onSave={handleSave}
            setSubmitting={setSubmitting}
            handleSubmitWithRedirect={handleSave}
            record={record}
            type={type}
            id={record.id}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
