import { FC } from "react";
import { FieldProps, useRecordContext, useTranslate } from "react-admin";
import get from "lodash/get";

export const FootField: FC<FieldProps> = ({
  source = "foot",
  sortBy = "players.foot",
}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const foot = get(record, source);
  return foot ? (
    <span>{translate(`resources.players.foot.${foot}`)}</span>
  ) : (
    <span />
  );
};
