import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import LineupItem from './LineupItem';
import { grid } from './reorder';
import type { LineupItemContent } from './reorder';
import type {
    DroppableProvided,
    DroppableStateSnapshot,
    DraggableProvided,
    DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { Typography } from '@mui/material';

export const getBackgroundColor = (
    isDraggingOver: boolean,
    isDraggingFrom: boolean
): string => {
    if (isDraggingOver) {
        return 'rgba(0, 0, 0, 0.5)';
    }
    if (isDraggingFrom) {
        return 'rgba(0, 0, 0, 0.5)';
    }
    return 'rgba(0, 0, 0, 0.87)';
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${grid}px;
    border: ${grid}px;
    padding-bottom: 0;
    transition: background-color 0.2s ease, opacity 0.1s ease;
    user-select: none;
`;

const scrollContainerHeight: number = 80;

const DropZone = styled.div`
    /* stop the list collapsing when empty */
    min-height: ${scrollContainerHeight}px;

    /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
    padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    max-height: ${scrollContainerHeight}px;
`;

const Container = styled.div``;

type Props = {
    listId?: string;
    listType?: string;
    quotes: LineupItemContent[];
    title?: string;
    internalScroll?: boolean;
    scrollContainerStyle?: Object;
    isDropDisabled?: boolean;
    isCombineEnabled?: boolean;
    style?: Object;
    // may not be provided - and might be null
    ignoreContainerClipping?: boolean;
    useClone?: boolean;
};

type InnerLineupItemListFCProps = {
    quotes: LineupItemContent[];
};

const InnerLineupItemListFC: React.FC<InnerLineupItemListFCProps> = (
    props: InnerLineupItemListFCProps
) => {
    return props.quotes ? (
        <Fragment>
            {props.quotes.map((quote: LineupItemContent, index: number) => (
                <Draggable key={quote.id} draggableId={quote.id} index={index}>
                    {(
                        dragProvided: DraggableProvided,
                        dragSnapshot: DraggableStateSnapshot
                    ) => (
                        <LineupItem
                            key={quote.id}
                            quote={quote}
                            isDragging={dragSnapshot.isDragging}
                            isGroupedOver={Boolean(
                                dragSnapshot.combineTargetFor
                            )}
                            provided={dragProvided}
                        />
                    )}
                </Draggable>
            ))}
        </Fragment>
    ) : null;
};

const InnerLineupItemList = React.memo(InnerLineupItemListFC);
type InnerListProps = {
    dropProvided: DroppableProvided;
    quotes: LineupItemContent[];
    title?: string;
};

function InnerList(props: InnerListProps) {
    const { quotes, dropProvided } = props;

    const title = props.title ? (
        <Typography
            variant="h6"
            sx={{
                margin: 0,
                lineHeight: 1,
                textAlign: 'left',
            }}
        >
            {props.title}
        </Typography>
    ) : null;

    return (
        <Container>
            {title}
            <DropZone ref={dropProvided.innerRef}>
                <InnerLineupItemList quotes={quotes} />
                {dropProvided.placeholder}
            </DropZone>
        </Container>
    );
}

export default function LineupItemList(props: Props) {
    const {
        ignoreContainerClipping,
        internalScroll,
        scrollContainerStyle,
        isDropDisabled,
        isCombineEnabled,
        listId = 'LIST',
        listType,
        style,
        quotes,
        title,
        useClone,
    } = props;

    return (
        <Droppable
            droppableId={listId}
            type={listType}
            ignoreContainerClipping={ignoreContainerClipping}
            isDropDisabled={isDropDisabled}
            isCombineEnabled={isCombineEnabled}
            renderClone={
                useClone
                    ? (provided, snapshot, descriptor) => (
                          <LineupItem
                              quote={quotes[descriptor.source.index]}
                              provided={provided}
                              isDragging={snapshot.isDragging}
                              isClone
                          />
                      )
                    : null
            }
        >
            {(
                dropProvided: DroppableProvided,
                dropSnapshot: DroppableStateSnapshot
            ) => (
                <Wrapper
                    style={style}
                    isDraggingOver={dropSnapshot.isDraggingOver}
                    isDropDisabled={isDropDisabled}
                    isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                    {...dropProvided.droppableProps}
                >
                    {internalScroll ? (
                        <ScrollContainer style={scrollContainerStyle}>
                            <InnerList
                                quotes={quotes}
                                title={title}
                                dropProvided={dropProvided}
                            />
                        </ScrollContainer>
                    ) : (
                        <InnerList
                            quotes={quotes}
                            title={title}
                            dropProvided={dropProvided}
                        />
                    )}
                </Wrapper>
            )}
        </Droppable>
    );
}
