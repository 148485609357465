import { get } from "lodash";
import { useCallback, useEffect } from "react";
import {
  TextInput,
  sanitizeInputRestProps,
  TextInputProps,
  useDataProvider,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { venues } from "../../types";

export const LinkedTeamInput = ({ ...rest }: TextInputProps) => {
  const { getValues } = useFormContext();
  const { team_id, club_id, isHome, venueChoice, ...values } = getValues();
  const dataProvider = useDataProvider();
  const getData = useCallback(async () => {
    const { data: team } =
      (await dataProvider.getOne("teams", { id: team_id })) || {};
    const { data: club } =
      (await dataProvider.getOne("clubs", { id: club_id })) || {};
    const teamName = `${get(team, "dfbnetTeamName")}`;
    const clubName = `${get(club, "name")}`;
    if (isHome) {
      values["homeTeam"] = teamName;
      values["guestTeam"] = clubName;
    } else {
      values["homeTeam"] = clubName;
      values["guestTeam"] = teamName;
    }
  }, [dataProvider, team_id, club_id, isHome]);
  useEffect(() => {
    if (isHome !== undefined || team_id || club_id) {
      getData();
    }
    if (venueChoice !== undefined) {
      values["venue"] = venues[venueChoice]["name"];
      values["street"] = venues[venueChoice]["street"];
      values["postal"] = venues[venueChoice]["postal"];
      values["city"] = venues[venueChoice]["city"];
      values["placeType"] = venues[venueChoice]["placeType"];
      values["area"] = venues[venueChoice]["area"];
    }
  }, [isHome, team_id, club_id, venueChoice]);
  if (!team_id || !club_id) return null;
  return (
    <>
      <TextInput
        source="homeTeam"
        label="resources.matches.fields.homeTeam"
        disabled={true}
        {...sanitizeInputRestProps(rest)}
      />
      <TextInput
        source="guestTeam"
        label="resources.matches.fields.guestTeam"
        disabled={true}
        {...sanitizeInputRestProps(rest)}
      />
      <TextInput
        source="venue"
        label="resources.matches.fields.venue"
        disabled={true}
        {...sanitizeInputRestProps(rest)}
      />
      <TextInput
        source="street"
        label="resources.matches.fields.street"
        disabled={true}
        {...sanitizeInputRestProps(rest)}
      />
      <TextInput
        source="city"
        label="resources.matches.fields.city"
        disabled={true}
        {...sanitizeInputRestProps(rest)}
      />
      <TextInput
        source="placeType"
        label="resources.matches.fields.placeType"
        disabled={true}
        {...sanitizeInputRestProps(rest)}
      />
      <TextInput
        source="area"
        label="resources.matches.fields.area"
        disabled={true}
        {...sanitizeInputRestProps(rest)}
      />
    </>
  );
};
