import {
    Create,
    SimpleForm,
    DateInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    required,
} from 'react-admin';
import LinkedNameFromPracticeInput from '../../components/inputs/LinkedNameFromPracticeInput';
import LinkedTimeInput from '../../components/inputs/LinkedTimeInput';
import { sanitizeRestListProps, validateTime } from '../../types';

export const TrainingCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list" {...sanitizeRestListProps(props)}>
                <DateInput
                    label="resources.trainings.fields.date"
                    source="date"
                />
                <ReferenceInput
                    label="resources.trainings.fields.team_id"
                    source="team_id"
                    reference="teams"
                    filter={{ currentSeason: true }}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData }) => {
                        return formData && formData.team_id ? (
                            <ReferenceInput
                                label="resources.trainings.fields.practice_id"
                                source="practice_id"
                                reference="practices"
                                allowEmpty
                                filter={{ team_id: formData.team_id }}
                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        ) : null;
                    }}
                </FormDataConsumer>
                <LinkedNameFromPracticeInput
                    label="resources.trainings.fields.name"
                    source="name"
                    validate={required()}
                />
                <LinkedTimeInput
                    label="resources.trainings.fields.startTime"
                    source="startTime"
                    validate={validateTime}
                />
                <LinkedTimeInput
                    label="resources.trainings.fields.endTime"
                    source="endTime"
                    validate={validateTime}
                />
                <LinkedTimeInput
                    label="resources.trainings.fields.meetingTime"
                    source="meetingTime"
                    validate={validateTime}
                />
            </SimpleForm>
        </Create>
    );
};
