import {
  AutocompleteInput,
  ReferenceInput,
  Create,
  DateInput,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  FormDataConsumer,
  RaRecord,
  useGetOne,
} from 'react-admin';
import { convertBirthdateToAge, choicesScoutingType, choicesScoutingReviews, choicesMatchLevels } from '../../types';
import { get } from 'lodash';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useRef, useCallback } from 'react';

export const ScoutingCreate = props => {
  const location = useLocation();
  const player_id = get(location.state, 'record.player_id');
  const initialValues = {
    date: new Date(),
    player_id,
    type: 'regularmatch',
  };
  const { isLoading, data: {
    name
  } } = useGetOne('players', { id: player_id });
  const playerRef = useRef();
  if (isLoading && name && playerRef) {
    playerRef.current = name;
  }
  const transform = useCallback((data: RaRecord) => {
    return {
      ...data,
      name: `${playerRef.current}`,
      club_home_id: `${data.type}` === 'regularmatch' || `${data.type}` === 'testmatch' ? `${data.club_home_id}` : null,
      club_away_id: `${data.type}` === 'regularmatch' || `${data.type}` === 'testmatch' ? `${data.club_away_id}` : null,
      location: `${data.type}` !== 'regularmatch' && `${data.type}` !== 'testmatch' ? `${data.location}` : '',
    };
  }, []);
  return (
    <Create transform={transform} actions={<PlayerBreadcrumb player_id={player_id} />} redirect={`/players/${player_id}/4`} {...props}>
      <SimpleForm defaultValues={initialValues}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput
              source="player_id"
              reference="players"
              validate={[required()]}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput
                optionValue="id"
                label="resources.scoutings.fields.player"
                noOptionsText="-"
                shouldRenderSuggestions={(val: any) => {
                  return val.trim().length > 2;
                }}
                optionText={choice => {
                  const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                  return `${get(choice, 'name')} - ${birthdate}`;
                }}
                filterToQuery={(searchText: any) => ({
                  "name@ilike": `%${searchText}%`,
                })}
              />
            </ReferenceInput>
            <DateInput label="resources.scoutings.fields.date" source="date" validate={required()} />
            <SelectInput
              label="resources.scoutings.fields.type"
              source="type"
              fullWidth
              choices={choicesScoutingType}
            />
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'regularmatch' || formData.type === 'testmatch') && (
                  <ReferenceInput
                    source="club_home_id"
                    reference="clubs"
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={5}
                    {...rest}
                  >
                    <AutocompleteInput
                      label="resources.scoutings.fields.clubHome"
                      noOptionsText="-"
                      shouldRenderSuggestions={(val: any) => {
                        return val.trim().length > 2;
                      }}
                      filterToQuery={(searchText: any) => ({
                        "name@ilike": `%${searchText}%`,
                      })}
                      optionText="name"
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'regularmatch' || formData.type === 'testmatch') && (
                  <ReferenceInput
                    label="resources.scoutings.fields.clubAway"
                    source="club_away_id"
                    reference="clubs"
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={5}
                    {...rest}
                  >
                    <AutocompleteInput
                      label="resources.scoutings.fields.clubAway"
                      noOptionsText="-"
                      shouldRenderSuggestions={(val: any) => {
                        return val.trim().length > 2;
                      }}
                      filterToQuery={(searchText: any) => ({
                        "name@ilike": `%${searchText}%`,
                      })}
                      optionText="name"
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'repplayer' ||
                  formData.type === 'dfbteam' ||
                  formData.type === 'fieldtournament' ||
                  formData.type === 'halltournament') && (
                  <TextInput label="resources.scoutings.fields.location" source="location" {...rest} />
                )
              }
            </FormDataConsumer>
          </Box>
          <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput label="resources.scoutings.fields.report" source="report" multiline fullWidth rows={10} />
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'regularmatch' || formData.type === 'testmatch') && (
                  <SelectInput
                    label="resources.scoutings.fields.matchLevel"
                    source="matchLevel"
                    choices={choicesMatchLevels}
                    fullWidth
                    {...rest}
                  />
                )
              }
            </FormDataConsumer>
            <SelectInput
              label="resources.scoutings.fields.scoutingReview"
              source="scoutingReview"
              choices={choicesScoutingReviews}
              fullWidth
            />
          </Box>
      </SimpleForm>
    </Create>
  );
};
