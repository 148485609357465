import {
  Edit,
  AutocompleteInput,
  ReferenceInput,
  DateInput,
  RaRecord,
  TextInput,
  required,
  SelectInput,
  SimpleForm,
  FormDataConsumer,
  SaveButton,
  Toolbar,
  useGetOne,
} from 'react-admin';
import { useCallback, useRef } from 'react';
import {
  convertBirthdateToAge,
  choicesScoutingType,
  choicesScoutingReviews,
  choicesMatchLevels,
  sanitizeRestListProps,
} from '../../types';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

export const CustomToolbar = props => {
  const playerRef = useRef();
  const { player_id } = props;
  const { isLoading, data: player } = useGetOne('players',{ id: player_id });
  if (!isLoading) {
    playerRef.current = player.name;
  }
  const transform = useCallback((data: RaRecord) => {
    return {
      ...data,
      club_home_id: `${data.type}` === 'regularmatch' || `${data.type}` === 'testmatch' ? `${data.club_home_id}` : null,
      club_away_id: `${data.type}` === 'regularmatch' || `${data.type}` === 'testmatch' ? `${data.club_away_id}` : null,
      location: `${data.type}` !== 'regularmatch' && `${data.type}` !== 'testmatch' ? `${data.location}` : '',
      name: `${playerRef.current}`,
    };
  }, []);
  return (
    <Toolbar {...props}>
      <SaveButton label="ra.action.save" transform={transform} />
    </Toolbar>
  );
};

export const ScoutingEdit = props => {
  const location = useLocation();
  const [,,id] = location.pathname.split("/");
  const { data, isLoading } = useGetOne("scoutings", { id });
  if(isLoading) return null;
  return (
    <Edit
      title="resources.scoutings.actions.edit"
      actions={<PlayerBreadcrumb source="player_id" {...props} />}
      redirect={`/players/${data.player_id}/4`}
      {...props}
    >
      <SimpleForm
        toolbar={<CustomToolbar player_id={data.player_id} />}
        submitOnEnter={false}
        {...sanitizeRestListProps(props)}
      >
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput
              source="player_id"
              reference="players"
              validate={[required()]}
              options={{ disabled: true }}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput
                label="resources.scoutings.fields.player"
                disabled={true}
                optionValue="id"
                optionText={choice => {
                  const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                  return `${get(choice, 'name')} - ${birthdate}`;
                }}
                filterToQuery={(searchText: any) => ({
                  "name@ilike": `%${searchText}%`,
                })}
              />
            </ReferenceInput>
            <DateInput label="resources.scoutings.fields.date" source="date" validate={required()} />
            <SelectInput
              label="resources.scoutings.fields.type"
              source="type"
              fullWidth
              choices={choicesScoutingType}
            />
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'regularmatch' || formData.type === 'testmatch') && (
                  <ReferenceInput
                    source="club_home_id"
                    reference="clubs"
                    filterToQuery={(searchText: any) => ({ q: searchText })}
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={5}
                    {...rest}
                  >
                    <AutocompleteInput
                      label="resources.scoutings.fields.clubHome"
                      noOptionsText="-"
                      shouldRenderSuggestions={(val: any) => {
                        return val.trim().length > 2;
                      }}
                      filterToQuery={(searchText: any) => ({
                        "name@ilike": `%${searchText}%`,
                      })}
                      optionText="name"
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'regularmatch' || formData.type === 'testmatch') && (
                  <ReferenceInput
                    label="resources.scoutings.fields.clubAway"
                    source="club_away_id"
                    reference="clubs"
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={5}
                    {...rest}
                  >
                    <AutocompleteInput
                      noOptionsText="-"
                      shouldRenderSuggestions={(val: any) => {
                        return val.trim().length > 2;
                      }}
                      filterToQuery={(searchText: any) => ({
                        "name@ilike": `%${searchText}%`,
                      })}
                      optionText="name"
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'repplayer' ||
                  formData.type === 'dfbteam' ||
                  formData.type === 'fieldtournament' ||
                  formData.type === 'halltournament') && (
                  <TextInput label="resources.scoutings.fields.location" source="location" {...rest} />
                )
              }
            </FormDataConsumer>
          </Box>
          <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="resources.scoutings.fields.report"
              multiline
              fullWidth
              source="report"
              rows={10}
              maxRows={10}
            />
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                (formData.type === 'regularmatch' || formData.type === 'testmatch') && (
                  <SelectInput
                    label="resources.scoutings.fields.matchLevel"
                    source="matchLevel"
                    choices={choicesMatchLevels}
                    fullWidth
                    {...rest}
                  />
                )
              }
            </FormDataConsumer>
            <SelectInput
              label="resources.scoutings.fields.scoutingReview"
              source="scoutingReview"
              choices={choicesScoutingReviews}
              fullWidth
            />
          </Box>
      </SimpleForm>
    </Edit>
  );
};
