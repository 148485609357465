/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import {
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  BooleanField,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { ShowSupabaseUser } from "../../../components/fields/SupabaseUserField";

export const RelationFormTab: React.FC = (props) => {
  const { pathname } = useLocation();
  return (
    <FormTab {...props} label="resources.players.tabs.relation" path="relation">
      <ReferenceManyField
        sort={{ field: "createdate", order: "DESC" }}
        reference="teams_players"
        source="id"
        target="player_id"
      >
        <Datagrid rowClick="expand">
          <ReferenceField label="resources.relations.fields.team_id" source="team_id" reference="teams">

            <TextField
              label="resources.relations.fields.team_id"
              source="name"
            />
          </ReferenceField>
          <TextField
            label="resources.relations.fields.createdby"
            source="createdby"
          />
          <BooleanField
            lable="resources.relations.fields.appEnabled" 
            source="appEnabled"
            />
          <DeleteButton label="" redirect={pathname} />
        </Datagrid>
      </ReferenceManyField>

      <ReferenceManyField
        sort={{ field: "updated_at", order: "DESC" }}
        reference="profiles"
        source="id"
        target="player_id"
      >
        <Datagrid>
        {/* <Datagrid expand={<ShowSupabaseUser />} expandSingle> */}
            <TextField label="resources.relations.fields.profile_id" source={"id"} />
            <DeleteButton label="" redirect={pathname} />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  );
};
