import { TranslationMessages } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import german from 'ra-language-german';

export const de: TranslationMessages = {
    ...german,
    ra: {
        action: {
            add_filter: 'Filter hinzufügen',
            add: 'Neu',
            back: 'Zurück',
            bulk_actions:
                'Ein Element ausgewählt |||| %{smart_count} Elemente ausgewählt',
            cancel: 'Abbrechen',
            clear_input_value: 'Eingabe löschen',
            clone: 'Klonen',
            confirm: 'Bestätigen',
            create: 'Erstellen',
            delete: 'Löschen',
            edit: 'Bearbeiten',
            export: 'Exportieren',
            list: 'Liste',
            refresh: 'Aktualisieren',
            remove_filter: 'Filter entfernen',
            remove: 'Entfernen',
            save: 'Speichern',
            search: 'Suchen',
            show: 'Anzeigen',
            sort: 'Sortieren',
            undo: 'Zurücksetzen',
            unselect: 'Abwählen',
            expand: 'Erweitern',
            close: 'Schliessen',
            open_menu: 'Öffnen des Menüs',
            close_menu: 'Schliessen des Menüs',
            select_columns: 'Spalten',
        },
        boolean: {
            true: 'Ja',
            false: 'Nein',
            null: ' ',
        },
        configurable: {
            customize: '',
        },
        page: {
            create: '%{name} erstellen',
            dashboard: 'Dashboard',
            edit: '%{name} #%{id}',
            error: 'Etwas ist schief gelaufen',
            list: '%{name}',
            loading: 'Die Seite wird geladen.',
            not_found: 'Nicht gefunden',
            show: '%{name} #%{id}',
            empty: 'Noch kein %{name}.',
            invite: 'Neu erstellen?',
        },
        input: {
            file: {
                upload_several:
                    'Zum Hochladen Dateien hineinziehen oder hier klicken, um Dateien auszuwählen.',
                upload_single:
                    'Zum Hochladen Datei hineinziehen oder hier klicken, um eine Datei auszuwählen.',
            },
            image: {
                upload_several:
                    'Zum Hochladen Bilder hineinziehen oder hier klicken, um Bilder auszuwählen.',
                upload_single:
                    'Zum Hochladen Bild hineinziehen oder hier klicken, um ein Bild auszuwählen.',
            },
            references: {
                all_missing:
                    'Die zugehörigen Referenzen konnten nicht gefunden werden.',
                many_missing:
                    'Mindestens eine der zugehörigen Referenzen scheint nicht mehr verfügbar zu sein.',
                single_missing:
                    'Eine zugehörige Referenz scheint nicht mehr verfügbar zu sein.',
            },
            password: {
                toggle_visible: 'Passwort ausblenden',
                toggle_hidden: 'Passwort einblenden',
            },
        },
        message: {
            about: 'Über',
            are_you_sure: 'Sind Sie sicher?',
            bulk_delete_content:
                'Möchten Sie "%{name}" wirklich löschen? |||| Möchten Sie diese %{smart_count} Elemente wirklich löschen?',
            bulk_delete_title:
                'Lösche %{name} |||| Lösche %{smart_count} %{name} Elemente',
            delete_content: 'Möchten Sie diesen Inhalt wirklich löschen?',
            delete_title: 'Lösche %{name} #%{id}',
            details: 'Details',
            error: 'Ein Fehler ist aufgetreten und Ihre Anfrage konnte nicht abgeschlossen werden.',
            invalid_form:
                'Das Formular ist ungültig. Bitte überprüfen Sie Ihre Eingaben.',
            loading: 'Die Seite wird geladen, bitte haben Sie etwas Geduld.',
            no: 'Nein',
            not_found: 'Die Seite konnte nicht gefunden werden.',
            yes: 'Ja',
            unsaved_changes:
                'Einige Änderungen wurden nicht gespeichert. Sind Sie sicher, dass Sie diese Seite verlassen wollen?',
        },
        navigation: {
            no_results: 'Keine Resultate gefunden',
            no_more_results: 'Die Seite %{page} enthält keine Inhalte.',
            page_out_of_boundaries:
                'Die Seite %{page} liegt ausserhalb des gültigen Bereichs',
            page_out_from_end: 'Letzte Seite',
            page_out_from_begin: 'Erste Seite',
            page_range_info: '%{offsetBegin}-%{offsetEnd} von %{total}',
            page_rows_per_page: 'Zeilen pro Seite:',
            next: 'Weiter',
            prev: 'Zurück',
            skip_nav: 'Zum Inhalt springen',
        },
        auth: {
            auth_check_error: 'Bitte verbinden Sie sich um fortzufahren',
            user_menu: 'Profil',
            username: 'Nutzername',
            password: 'Passwort',
            sign_in: 'Anmelden',
            sign_in_error: 'Fehler bei der Anmeldung',
            logout: 'Abmelden',
        },
        notification: {
            updated:
                'Element wurde aktualisiert |||| %{smart_count} Elemente wurden aktualisiert',
            created: 'Element wurde erstellt',
            deleted:
                'Element wurde gelöscht |||| %{smart_count} Elemente wurden gelöscht',
            bad_item: 'Fehlerhaftes Element',
            item_doesnt_exist: 'Das Element existiert nicht',
            http_error: 'Fehler beim Kommunizieren mit dem Server',
            data_provider_error:
                'Fehler im dataProvider. Prüfe die Konsole für Details.',
            i18n_error:
                'Die Übersetzungen für die angegebene Sprache können nicht geladen werden.',
            canceled: 'Aktion abgebrochen',
            logged_out:
                'Ihre Sitzung wurde beendet, bitte verbinden Sie sich neu.',
        },
        validation: {
            required: 'Benötigt',
            minLength: 'Muss mindestens %{min} Zeichen lang sein',
            maxLength: 'Darf maximal %{max} Zeichen lang sein',
            minValue: 'Muss mindestens %{min} sein',
            maxValue: 'Muss %{max} oder weniger sein',
            number: 'Muss eine Nummer sein',
            email: 'Muss eine gültige E-Mail sein',
            oneOf: 'Es muss einer sein von: %{options}',
            regex: 'Es muss folgendem regulären Ausdruck entsprechen: %{pattern}',
        },
    },
    pos: {
        search: 'Suche',
        configuration: 'Konfiguration',
        profile: 'Benutzerprofil',
        language: 'Sprache',
        review: 'Bewertung',
        scouting: 'Sichtung',
        menu: {
            settings: 'Einstellungen',
            additional: 'Weitere',
        },
        fields: {
            createdby: 'Autor',
            createdate: 'eingetragen am',
            lastupdate: 'zuletzt geändert am',
            updatedby: 'Änderer',
        },
    },
    resources: {
        rating: {
            6: '6 (Topspieler)',
            5: '5 (Stammspieler)',
            4: '4 (regelmäßige Einsätze)',
            3: '3 (unregelmäßige Einsätze, aber Potential)',
            2: '2 (Wackelkandidat)',
            1: '1 (Streichkandidat)',
            a: 'A (klare Verstärkung, offensichtlich)',
            b: 'B (Verstärkung, mit etwas Phantasie)',
            c: 'C (evtl. Verstärkung, noch zu früh)',
            d: 'D (keine Verstärkung)',
            '60': '6 (Topspieler)',
            '50': '5 (Stammspieler)',
            '40': '4 (regelmäßige Einsätze)',
            '30': '3 (unregelmäßige Einsätze, aber Potential)',
            '20': '2 (Wackelkandidat)',
            '10': '1 (Streichkandidat)',
            '51': 'A (klare Verstärkung, offensichtlich)',
            '41': 'B (Verstärkung, mit etwas Phantasie)',
            '31': 'C (evtl. Verstärkung, noch zu früh)',
            '21': 'D (keine Verstärkung)',
        },
        scoutingReview: {
            1: 'herausragend',
            2: 'positiv auffällig',
            3: 'nicht auffällig',
            4: 'negativ auffällig',
        },
        matchLevels: {
            KL: 'Kreisliga',
            BzL: 'Bezirksliga',
            LL: 'Landesliga',
            WL: 'Westfalenliga',
            OL: 'Oberliga',
            RL: 'Regionalliga',
            BL: 'Bundesliga',
        },
        journal: {
            name: 'Dashboard',
        },
        squadplayers: {
            name: 'Kaderplanung',
            details: 'Spieler-Details',
            fields: {
                squad: 'Kader',
                player: 'Spieler',
                clubstatus: 'Zusage (Verein)',
                playerstatus: 'Zusage (Spieler)',
                lineupstatus: 'Status (Kaderplanung)',
                scoutingstatus: 'Status (Scouting)',
                contacted: 'Kontakt aufgenommen?',
                hasContract: 'hat Vertrag?',
                contractOffered: 'Vertrags-Angebot?',
                clubInformed: 'Verein informiert?',
                agentInformed: 'Berater kontaktiert?',
                status: 'Status',
                trialexercise: 'Probetraining geplant?',
                appEnabled: 'App-Zugang aktiv?',
            },
            notification: {
                transfer_updated: 'Transfer aktualisiert: %{name}',
                transfer_added: 'Transfer ergänzt: %{name}',
                transfer_not_added:
                    'Transfer nicht möglich für %{name}! Grund: %{error}',
                added: 'Spieler in Kaderplanung aufgenommen',
                exists: 'Spieler %{player} existiert bereits in der Kaderplanung',
                deleted: 'Spieler aus der  Kaderplanung entfernt',
                updated: 'Spieler-Status in der  Kaderplanung aktualisiert',
                planned: 'Spieler auf geplant gesetzt',
                open: 'Spieler auf offen gesetzt',
                notplanned: 'Spieler auf nicht geplant gesetzt',
                member: 'Spieler in aktuellen Kader verschoben',
                notscouted: 'Spieler in nicht gesichtet verschoben',
                scouted: 'Spieler in Sichtung verschoben',
                yes: 'Zusage vermerkt',
                no: 'Absage vermerkt',
                error: 'Fehler bei der Kaderplanung',
            },
            actions: {
                create_transfer: 'Transfer erzeugen',
                lineup_planned: 'geplant',
                lineup_notplanned: 'n. geplant',
                lineup_open: 'offen',
                scouting_member: 'Kader',
                scouting_scouted: 'Sichtung',
                scouting_open: 'n. gesichtet',
                player_yes: 'Zusage Spieler',
                player_no: 'Absage Spieler',
                player_open: 'Zusage Spieler (offen)',
                club_yes: 'Zusage Verein',
                club_no: 'Absage Verein',
                club_open: 'Zusage Verein (offen)',
                delete: 'Löschen',
                contractOffered: 'Vertrags-Angebot abgegeben',
                hasContract: 'hat Vertrag',
                appEnabled: 'App-Zugang erlauben',
                contacted: 'Kontakt aufgenommen',
                clubInformed: 'Verein informiert',
                agentInformed: 'Berater kontaktiert',
                trialexercise: 'Probetraining',
                add: 'in Kaderplanung aufnehmen',
            },
            status: {
                scouting: {
                    open: 'nicht gesichtet',
                    scouted: 'Sichtung',
                    member: 'akt. Kader',
                },
                lineup: {
                    open: 'offen',
                    planned: 'geplant',
                    notplanned: 'nicht geplant',
                },
                player: {
                    open: 'offen',
                    yes: 'Ja',
                    no: 'Nein',
                },
                club: {
                    open: 'offen',
                    yes: 'Ja',
                    no: 'Nein',
                },
            },
        },
        lineups: {
            name: 'Aufstellungen',
            boxes: {
                substitution: 'Ergänzungsspieler',
                startup: 'Startelf',
            },
            empty: 'Leer',
            search_name: 'Suche nach Aufstellung',
            lineuptypes: {
                type343: '1-3-4-3',
                type442: '1-4-4-2',
                type433: '1-4-3-3',
                type352: '1-3-5-2',
            },
            fields: {
                name: 'Name',
                squad: 'aktueller Kader',
                lineuptype: 'Grundaufstellung',
                scout_id: 'Sichtungs-Kader',
                type343: {
                    p1: 'TW (1)',
                    p2: 'RIV (2)',
                    p3: 'ZIV (3)',
                    p4: 'RIV (4)',
                    p5: 'RM (5)',
                    p6: 'DM (6)',
                    p7: 'DM (7)',
                    p8: 'LM (8)',
                    p9: 'ST (9)',
                    p10: 'RF (10)',
                    p11: 'LF (11)',
                },
                type442: {
                    p1: 'TW (1)',
                    p2: 'RV (2)',
                    p3: 'LV (3)',
                    p4: 'IV (4)',
                    p5: 'IV (5)',
                    p6: 'DM (6)',
                    p7: 'RA (7)',
                    p8: 'DM (8)',
                    p9: 'ST (9)',
                    p10: 'HS (10)',
                    p11: 'LA (11)',
                },
                type433: {
                    p1: 'TW (1)',
                    p2: 'RV (2)',
                    p3: 'LV (3)',
                    p4: 'IV (4)',
                    p5: 'IV (5)',
                    p6: 'DM (6)',
                    p7: 'RA (7)',
                    p8: 'OM (8)',
                    p9: 'ST (9)',
                    p10: 'OM (10)',
                    p11: 'LA (11)',
                },
            },
            pages: {
                edit: 'Aufstellung bearbeiten',
            },
        },
        practices: {
            name: 'Trainingszeiten',
            fields: {
                name: 'Name',
                team_id: 'Team',
                type: 'Typ',
                meetingTime: 'Treffen',
                startTime: 'Start-Zeit',
                endTime: 'End-Zeit',
                startRecur: 'Start der Serie',
                endRecur: 'Ende der Serie',
                daysOfWeek: 'Wochentage',
                shareOfSpace: 'Platzgröße',
                venue: 'Platz',
            },
            types: {
                team: 'Mannschafts-Training',
                keeper: 'TW-Training',
                athletic: 'Athletik-Training',
                meeting: 'Besprechung/Video-Analyse',
                support: 'Förder-Training',
            },
            shareOfSpace: {
                half: '½ - Platzhälfte',
                full: 'ganzer Platz',
                quarter: '¼ - Platzviertel',
                penaltyarea: 'Strafraum',
            },
            tabs: {
                details: 'Details',
                upcoming: 'Einheiten (zukünftig)',
                past: 'Einheiten (vergangen)',
            },
            daysOfWeek: {
                '0': 'Sonntag',
                '1': 'Montag',
                '2': 'Dienstag',
                '3': 'Mittwoch',
                '4': 'Donnerstag',
                '5': 'Freitag',
                '6': 'Samstag',
            },
            notification: {
                trainings_added: '%{count} Trainingseinheiten erzeugt.',
                time_update_title:
                    'Zukünftige Trainingseinheiten aktualisieren',
                time_update_content:
                    'Soll die Uhrzeit von %{count} Trainingseinheiten aktualisiert werden?',
                trainings_deleted: '%{count} Trainingseinheiten gelöscht.',
            },
            actions: {
                trainings_added: 'Trainingseinheiten erzeugen',
                trainings_deleted: 'Trainingseinheiten löschen',
                edit: 'Trainingszeiten ändern',
            },
        },
        trainings: {
            name: 'Trainingseinheiten',
            fields: {
                name: 'Name',
                team_id: 'Team',
                meetingTime: 'Treffen',
                startTime: 'Start-Zeit',
                endTime: 'End-Zeit',
                date: 'Datum',
                practice_id: 'Trainingszeit',
                injured: 'Verletzt',
                ill: 'Krank',
                present: 'anwesend',
                away: 'abgemeldet',
                light: 'leichtes Training',
                reason: 'Begründung',
                content: 'Notizen',
                linkedPlayers: 'Kader?',
                num_trainings: 'Gesamt',
                rate_presence: 'Beteiligung',
            },
            tabs: {
                details: 'Details',
                presence: 'Anwesenheit',
                planning: 'Planung',
            },
            notification: {
                added_error: 'Fehler bei der Übernahme des Kaders!',
                added: 'Kader wurde fürs Training übernommen!',
                deleted_error: 'Fehler beim Löschen des Kaders!',
                deleted: 'Kader wurde fürs Training gelöscht!',
                linkerdPlayers: 'Kader wurde aktualisiert!',
                status_update:
                    'Spieler wurde in der Trainings-Anwesenheit aktualisiert!',
                status_update_error:
                    'Fehler bei der Aktualisierung der Trainings-Anwesenheit!',
            },
            actions: {
                add: 'Kader ergänzen',
                delete: 'Kader löschen',
                update_value: 'ändern',
                linkerdPlayers: 'Kader aktualisieren',
            },
        },
        matches: {
            name: 'Spiele',
            search_id: 'Suche nach Spielkennung',
            fields: {
                date: 'Datum',
                opponent: 'Gegner',
                season: 'Saison',
                association: 'Verband',
                area: 'Spielgebiet',
                teamType: 'Mannschaftsart',
                series: 'Staffel',
                venue: 'Spielstätte',
                venueNo: 'Spielstätten-Nr',
                street: 'Straße',
                postal: 'PLZ',
                city: 'Ort',
                placeNo: 'Platznummer',
                placeType: 'Platz-Typ',
                placeSize: 'Größe',
                maxParallelGames: 'Max parallele Spiele',
                maxGamesDay: 'Max Spiele/Tag',
                maxGamesWeekend: 'Max Spiele/Wochenende',
                earliestKickoff: 'früheste Anstoßzeit',
                latestKickoff: 'späteste Anstoßzeit',
                lunchBreak: 'Mittagspause',
                weekday: 'Wochentag',
                matchDate: 'Spieldatum',
                matchTime: 'Uhrzeit',
                gameday: 'Sptg',
                matches: 'Spiele',
                id: 'Spielkennung',
                type: 'Typ',
                league: 'Liga',
                match: 'Begegnung',
                homeTeam: 'Heimmannschaft',
                guestTeam: 'Gastmannschaft',
                referee: 'Spielleitung',
                assistantReferee1: 'Assistent 1',
                assistantReferee2: 'Assistent 2',
                refereeId: 'Spielleitung Ausweisnr',
                assistantReferee1Id: 'Assistent 1 Ausweisnr',
                assistantReferee2Id: 'Assistent 2 Ausweisnr',
                refereeArea: 'SpielleitungS chirigebiet',
                assistantReferee1Area: 'Assistent 1 Schirigebiet',
                assistantReferee2Area: 'Assistent 2 Schirigebiet',
                team_id: 'Team',
                player_id: 'Spieler',
                player: 'Spieler',
                name: 'Name',
                isHome: 'Heimspiel?',
                assists: 'Assists',
                goals: 'Tore',
                present: 'im Kader',
                yellow: 'Gelbe Karten',
                injured: 'Verletzt',
                ill: 'Krank',
                task: 'Aufgabe',
                starting: 'Startelf',
                substituted_out: 'ausg. (»)',
                substituted_in: 'eing. («)',
                time_played: 'Spielzeit',
                transport_needed: 'Bulli',
                rating: 'Bewertung',
                away: 'abgemeldet',
                comment: 'Notiz',
                min_rating: 'Bew. (min)',
                max_rating: 'Bew. (max)',
                avg_rating: 'Bew. (ø)',
                lastupdate: 'letzte Änderung',
                updatedby: 'Autor',
                source: 'Import-Quelle',
                gameTime: 'Spielzeit',
                meetingTime: 'Treffen',
                transportCapacity: 'Bulli-Kapazität',
                time_on_pitch: 'Time on Pitch (mins)',
                distance_km: 'Dist.',
                distance_km_normalized: 'Dist. (norm)',
                distance_km_expected: 'Dist. (exp)',
                distance_hi_km: 'Dist. HI',
                distance_hi_km_normalized: 'Dist. HI (norm)',
                distance_hi_km_expected: 'Dist. HI (exp)',
                sprints: 'Sprints',
                sprints_progressive: 'Prog. Sprints',
                top_speed: 'Top Speed ',
                avg_top_speed: 'Avg. Top Speed',
            },
            labels: {
                last_visited: 'Zeitraum',
                this_week: 'Diese Woche',
                last_week: 'Letzte Woche',
                this_month: 'Dieser Monat',
                last_month: 'Letzter Monat',
            },
            tabs: {
                matches_players: 'Kader',
                details: 'DFBNet-Infos',
                edit: 'Spiel ändern',
                matches_players_stats: 'Leistungsdaten',
            },
            notification: {
                player_added_error:
                    'Fehler bei der Aufnahme des Spielers in den Kaders!',
                player_added: 'Spieler wurde in den Kader aufgenommen!',
                squadplayers_added_error:
                    'Fehler bei der Übernahme des Kaders!',
                squadplayers_added: 'Kader wurde fürs Spiel übernommen!',
                squadplayers_deleted_error: 'Fehler beim Löschen des Kaders!',
                squadplayers_deleted: 'Kader wurde fürs Spiel gelöscht!',
                match_player_status_update:
                    'Spieler wurde in der Aufstellung aktualisiert!',
                match_player_status_update_error:
                    'Fehler bei der Aktualisierung des Kaders!',
                match_player_not_found:
                    'Spieler konnte nicht zugeordnet werden: %{name}',
            },
            venues: {
                hiltrup: 'Sportanlage TuS Hiltrup Pl. 2',
                stadium: 'Stadion an der Hammer Straße',
                profis: 'Profi-Platz',
                kr1: 'KR1 - Youngstars',
                kr2: 'KR2 - Youngstars',
                rp: 'RP - Youngstars',
            },
            source: {
                csv: 'DFBNet CSV Export',
                email: 'DFBNet E-Mail',
            },
            actions: {
                player_add: 'Spieler ergänzen',
                squadplayers_add: 'Kader ergänzen',
                squadplayers_delete: 'Kader löschen',
                update_value: 'ändern',
            },
            type: {
                past: 'bereits gespielt',
                upcoming: 'offene',
                all: 'alle',
            },
        },
        events: {
            name: 'Journal',
        },
        calendar: {
            name: 'Kalender',
            fields: {
                type: 'Typ',
                isHome: 'Heim/Auswärts',
                venue: 'Platz',
                search: 'Suche',
            },
            types: {
                team: 'Mannschafts-Training',
                keeper: 'TW-Training',
                athletic: 'Athletik-Training',
                meeting: 'Besprechung/Video-Analyse',
                Freundschaftsspiel: 'Freundschaftsspiel',
                Turnier: 'Turnier',
                Meisterschaft: 'Meisterschaft',
                Pokalspiel: 'Pokalspiel',
                support: 'Förder-Training',
            },
        },
        gameType: {
            '1': 'Heimspiel',
            '0': 'Auswärts',
        },
        squads: {
            name: 'Kader',
            search_name: 'Suche nach Kader',
            fields: {
                name: 'Name',
                ispro: 'Profi-Planung?',
                seasonsid: 'Saison',
                team: 'Mannschaft',
                currentSeason: 'aktuelle Saison?',
                currentPlanning: 'aktuelle Planung?',
                current: 'aktuell',
                color: 'Farbe',
                dfbnetTeamName: 'Mannschafts-Name im DFBNet',
                ageclass_id: 'Altersklasse',
            },
        },
        teams: {
            name: 'Teams',
            search_name: 'Suche nach Mannschaft',
            fields: {
                name: 'Name',
                short: 'Kürzel',
                stats: 'Statistik',
                ageclass: 'Alterklasse',
                club: 'Verein',
                season: 'Saison',
                currentSeason: 'aktuelle Saison?',
                currentPlanning: 'aktuelle Planung?',
                dfbnetTeamName: 'Mannschafts-Name im DFBNet',
                calendarWeek: 'Kalenderwoche',
                measure: 'Kennzahl',
            },
            measures: {
                teams_stats_weekly_rpe: 'RPE',
                teams_stats_weekly_sleep_duration: 'Schlafdauer',
                teams_stats_weekly_sleep_quality: 'Schlafqualität',
                teams_stats_weekly_exhaustion: 'Erschöpfung',
                teams_stats_weekly_mentally: 'Mentale Verfassung',
                teams_stats_weekly_sore_muscles: 'Muskelkater',
            },
            pages: {
                edit: 'Team anpassen',
            },
            tabs: {
                details: 'Details',
                match_stats: 'Spiele',
                training_stats: 'Trainings',
                weekly_stats: 'Wochen-Statistik',
            },
        },
        applications: {
            name: 'Bewerbungen',
            tabs: {
                player: 'Spieler',
                career: 'Sportlicher Werdegang',
                parents: 'Erziehungsberechtigte',
                school: 'Schulisches',
                self: 'Selbst-Einschätzung',
                club: 'Einverständniserklärung',
            },
            notification: {
                player_added: 'Spieler %{name} in die Datenbank aufgenommen',
                player_updated:
                    'Spieler %{name} in der Datenbank aktualisiert mit den Daten aus der Bewerbung',
                player_exists:
                    'Spieler wurde bereits in die Datenbank aufgenommen',
                not_worked_on: 'Bewerbung(en) zurückgesetzt',
                worked_on: 'Bewerbung(en) bearbeitet',
                error: 'Fehler bei der Bearbeitung',
            },
            actions: {
                add_player: 'in die Datenbank aufnehmen',
                worked_on: 'bearbeitet',
                not_worked_on: 'un-bearbeitet',
            },
            types: {
                application: 'Bewerbung',
                talentday_2021: 'Talent-Tag 2021',
                talentday_2022: 'Talent-Tag 2022',
                talentday_2023: 'Talent-Tag 2023',
                talentday_2024: 'Talent-Tag 2024',
            },
            fields: {
                type: 'Art der Bewerbung',
                createdate: 'Datum',
                agegroup_start: 'Jahrgang (Start)',
                agegroup_end: 'Jahrgang (Ende)',
                createdby: 'von',
                workedOn: 'bearbeitet',
                name: 'Name',
                address: 'Anschrift',
                postal: 'PLZ',
                city: 'Ort',
                birthdate: 'Geburtsdatum',
                placeOfBirth: 'Geburtsort',
                mobile: 'Handy-Nummer',
                emailPlayer: 'E-Mail',
                nationality: 'Nationalität',
                foot: 'Starker Fuß',
                height: 'Größe',
                weight: 'Gewicht',
                favPosition: 'Lieblings-Position',
                favPlayer: 'Fußballerisches Vorbild',
                currentClub: 'aktueller Verein',
                currentLeague: 'Spielklasse',
                clubSince: 'im aktuellen Verein seit',
                clubPosition: 'Position im Verein',
                prevClubs: 'vorherige Vereine',
                dfbLocation: 'DFB-Stützpunkt',
                parentsName: 'Name eines Erziehungsberechtigten',
                parentsPhone: 'Telefonnummer eines Erziehungsberechtigten',
                parentsEmail: 'Absage-E-Mail-Vorlage an Erziehungsberechtigten',
                parentsEmailAppoval:
                    'Zusage-E-Mail-Vorlage an Erziehungsberechtigten',
                parentsEmailRejection:
                    'Absage-E-Mail-Vorlage an Erziehungsberechtigten',
                schoolType: 'Schulart',
                schoolClass: 'Klasse / Abschlussjahrgang',
                schoolName: 'Name der Schule',
                schoolLocation: 'Ort der Schule',
                strength: 'Persönliche und sportliche Stärken',
                weakness: 'Persönliche und sportliche Schwächen',
                scpWhy: 'Warum willst du zum SC Preußen Münster?',
                clubRepName: 'Name eines Vereinsvertreters',
                clubRepFunction: 'Funktion des Vereinsvertreters',
                clubRepPhone: 'Telefonnummer des Vereinsvertreters',
                clubRepEmail: 'E-Mail des Vereinsvertreters',
            },
        },
        players: {
            name: 'Spieler',
            search_name: 'Suche nach Name',
            search_weight: 'Gewicht größer als',
            search_height: 'Körperlänge größer als',
            search_contract: 'Vertragsende',
            fields: {
                name: 'Name',
                ispro: 'Profi?',
                jerseyNo: 'RN',
                birthdate: 'Geburtsdatum',
                email: 'Email',
                memberSince: 'SCP seit',
                contractUntil: 'Vertrag bis',
                memberUntil: 'SCP bis',
                agegroup: 'Jahrgang',
                adress: 'Adresse',
                city: 'Wohnort',
                postal: 'PLZ',
                mobile: 'Handy',
                phone: 'Festnetz',
                height: 'Körperhöhe (stehend)',
                heightSitting: 'Körperhöhe (sitzend)',
                weight: 'Gewicht',
                shoesize: 'Schuh-Größe',
                strength: 'Stärken',
                weakness: 'Schwächen',
                rating: 'Bew.',
                physicaldate: 'Mess-Datum',
                memberRating: 'Einschätzung (intern)',
                scoutingRating: 'Einschätzung (extern)',
                talentday2022Rating: 'Talenttag 2022 (Score)',
                talentday2022Rating2: 'Talenttag 2022 (T.I.)',
                foot: 'Starker Fuß',
                positions: 'Positionen',
                teams: 'Mannschaften',
                previousClub: 'vorheriger Verein',
                currentClub: 'aktueller Verein',
                repTeams: 'Stützpunkt / Auswahl',
                reviews: 'Bewertung',
                contactedAt: 'Kontaktaufnahme',
                date: 'Datum',
                parents: 'Eltern',
                fatherName: 'Name (Vater)',
                fatherMobile: 'Handy (Vater)',
                motherName: 'Name (Mutter)',
                motherMobile: 'Handy (Mutter)',
                currentConsultancy: 'Berater',
                currentLeague: 'aktuelle Liga',
                applicationType: 'Quelle',
                comment: 'Notizen',
                scouting: '# Talenttag-Bewertungen',
                passNo: 'Pass-Nr',
                iban: 'IBAN',
                legal_representative: 'Gesetzlicher Vertreter',
                account_holder: 'Kontoinhaber',
                birthplace: 'Geburtsort',
                nationality: 'Nationalität',
                consultancy: 'Berater',
            },
            fieldGroups: {
                identity: 'Identität',
                adress: 'Adresse',
                footbal: 'Fußball',
                strengths: 'Stärken / Schwächen',
                human: 'Körper-Maße',
                football: 'Fußball',
                status: 'Status',
                comment: 'Notizen',
                contract: 'Vertrag',
                notes: 'Verlauf',
                history: 'Historie',
                parents: 'Erziehungsberechtigte',
            },
            page: {
                delete: 'Spieler löschen',
                add_sprintvalue: 'Sprintwerte hinzufügen',
                add_review: 'Bewertung hinzufügen',
                add_scouting: 'Sichtung hinzufügen',
                add_call: 'Gespräch hinzufügen',
                add_absence: 'Abwesenheit hinzufügen',
                note: 'Eintrag',
                add_note: 'speichern',
                edit_note: 'Eintrag ändern',
                delete_note: 'Eintrag löschen',
            },
            actions: {
                update: 'Spieler wurde aktualisiert',
                edit: 'Spieler editieren',
                add_note: 'Eintrag ergänzt!',
                edit_note: 'Eintrag geändert!',
                delete_note: 'Eintrag gelöscht!',
                iban: 'IBAN wurde aktualisiert',
            },
            tabs: {
                sprintvalues: 'Sprintwerte',
                review: 'Bewertungen',
                personal: 'Persönlich',
                scouting: 'Sichtungen',
                call: 'Gespräche',
                physical: 'Wachstum',
                stats: 'Stats',
                absences: 'Abwesenheiten',
                transfers: 'Transfers',
                relation: 'Verknüpfungen',
            },
            foot: {
                right: 'Rechts',
                left: 'Links',
                both: 'Beidfüßig',
            },
        },
        clubs: {
            name: 'Vereine',
            search_name: 'Suche nach Verein',
            fields: {
                name: 'Name',
                arrangements: 'Transfer-Vereinbarungen',
            },
            actions: {
                action: {
                    select_items:
                        'Delete one %{type} |||| Delete %{smart_count} %{type}s',
                },
            },
        },
        ageclasses: {
            name: 'Alterklassen',
            fields: {
                name: 'Name',
            },
        },
        positions: {
            name: 'Positionen',
            fields: {
                name: 'Name',
            },
        },
        physicals: {
            name: 'Körper-Maße',
            fields: {
                player: 'Spieler',
                weight: 'Gewicht',
                height: 'Körperhöhe (stehend)',
                heightSitting: 'Körperhöhe (sitzend)',
                date: 'Mess-Datum',
                bmi: 'BMI',
                age: 'Alter',
                aphv: 'APHV (Mirwald)',
                shoesize: 'Schuh-Größe',
            },
        },
        repteams: {
            name: 'Auswahlmannschaften',
            fields: {
                name: 'Name',
                slug: 'Kürzel',
            },
        },
        leagues: {
            name: 'Spielklassen',
            fields: {
                name: 'Name',
            },
        },
        consultancies: {
            name: 'Berater',
            search_name: 'Suche nach Berater',
            fields: {
                name: 'Name',
            },
        },
        seasons: {
            name: 'Spielzeiten',
            fields: {
                name: 'Name',
                isCurrent: 'aktuelle Saison?',
            },
            seasons: {
                s2020_2021: '2020/2021',
                s2021_2022: '2021/2022',
                s2022_2023: '2022/2023',
                s2023_2024: '2023/2024',
            },
        },
        absences: {
            name: 'Abwesenheiten',
            fields: {
                player: 'Spieler',
                name: 'Spieler',
                reason: 'Begründung',
                type: 'Art der Abwesenheit',
                startDate: 'Start-Datum',
                endDate: 'End-Datum',
            },
            types: {
                injured: 'Verletzung',
                away: 'Sonstiges',
            },
            notification: {
                training_update_title:
                    'Abwesenheit in Trainingseinheiten und Spielen überschreiben?',
                training_update_content:
                    'Es wurden %{trainings} Trainingseinheiten und %{matches} Spiele im Zeitraum der Abwesenheit gefunden.',
            },
        },
        sprintvalues: {
            name: 'Sprintwerte',
            fields: {
                player: 'Spieler',
                name: 'Name',
                m5: '5m Sprint',
                m10: '10m Sprint',
                m20: '20m Sprint',
                m30: '30m Sprint',
                date: 'Datum',
            },
            actions: {
                edit: 'Sprintwerte ändern',
            },
        },
        scoutings: {
            name: 'Sichtungen',
            actions: {
                create: 'Sichtung erstellen',
                edit: 'Sichtung ändern',
            },
            notification: {
                report_created: 'Sichtung für %{name} erstellt.',
                report_updated: 'Sichtung für %{name} aktualisiert.',
                report_already: 'Sichtung für %{name} existiert bereits.',
            },
            fields: {
                player: 'Spieler',
                createdby: 'Autor',
                matchLevel: 'Spiel-Niveau',
                scoutingReview: 'Einschätzung',
                report: 'Bericht',
                clubHome: 'Heim-Team',
                clubAway: 'Auswärts-Team',
                date: 'Datum',
                type: 'Art',
                createdate: 'geschrieben am',
                location: 'Ort',
            },
            types: {
                training: 'Probetraining',
                regularmatch: 'Pflicht-Spiel',
                testmatch: 'Test-Spiel',
                halltournament: 'Hallen-Turnier',
                fieldtournament: 'Feld-Turnier',
                dfbteam: 'Stützpunkt',
                repplayer: 'Westfalen-Auswahl',
                talentday2020: 'Talent-Tag 2020',
                talentday2021: 'Talent-Tag 2021',
                talentday2022: 'Talent-Tag 2022',
                talentday2023: 'Talent-Tag 2023',
                talentday2024: 'Talent-Tag 2024',
            },
        },
        treatments: {
            name: 'Behandlungen',
            resource: 'Behandlung |||| Behandlungen',
            fields: {
                player: 'Spieler',
                createdby: 'Autor',
                comments: 'Notizen',
                date: 'Datum',
            },
            actions: {
                edit: 'Behandlung ändern',
            },
        },
        calls: {
            name: 'Gespräche',
            resource: 'Gespräch||||Gespräche',
            fields: {
                player: 'Spieler',
                createdby: 'Autor',
                comments: 'Notizen',
                date: 'Datum',
                type: 'Art',
            },
            actions: {
                edit: 'Gespräch ändern',
            },
            types: {
                feedback: 'Feedback',
                prolongation: 'Übernahme',
                engagement: 'Verpflichtung',
                exchange: 'Austausch',
                info: 'Info',
                physio: 'Physio-Behandlung',
                sigskill: 'Signature-Skill',
            },
        },
        relations: {
            name: 'Verknüpfungen',
            resource: 'Verknüpfung||||Verknüpfungen',
            fields: {
                player: 'Spieler',
                team_id: 'Team',
                profile_id: 'App-Zugang',
                createdby: 'Autor',
            },
        },
        texts: {
            name: 'Konzepte',
            fields: {
                name: 'Titel',
                content: 'Inhalt',
                lastupdate: 'letzte Änderung',
                updatedby: 'von',
                category: 'Kategorie',
                search: 'Suche',
                access: 'Zugriff',
            },
            categories: {
                protocol: 'Protokoll',
                concept: 'Konzept',
            },
            access: {
                scpyoungstars_base: 'Alle',
                scpyoungstars_mgmt: 'Leitung',
            },
        },
        reviews: {
            name: 'Bewertungen',
            resource: 'Bewertung |||| Bewertungen',
            fields: {
                name: 'Spieler',
                player: 'Spieler',
                createdby: 'Autor',
                createdate: 'Datum',
                comments:
                    'Notizen (Begründung für Potential bzw. warum Spieler es evtl. noch nicht abrufen kann)',
                date: 'Datum',
                rating: 'Einschätzung',
                potential: 'Potential',
            },
            actions: {
                edit: 'Bewertung ändern',
            },
        },
        dfbnet_emails: {
            search: 'Suche',
            name: 'DFBNet-Emails',
            fields: {
                match_id: 'Spielkennung',
                sender: 'Absender',
                receiver: 'Empfänger',
                subject: 'Betreff',
                text: 'Text',
                doc: 'Verarbeitet',
                crud: 'Typ',
                success: 'erfolgreich?',
                createdby: 'Autor',
                createdate: 'Datum',
            },
        },

        coaches: {
            name: 'Trainer',
            fields: {
                createdby: 'Autor',
                comments: 'Notizen',
                mobile: 'Handy-Nr',
                name: 'Name',
                type: 'Typ',
                currentClub: 'aktueller Verein',
                license: 'Lizenz',
            },
            licenses: {
                Pro: 'Fußball-Lehrer',
                'A+': 'A+ Lizenz',
                A: 'A Lizenz',
                'B+': 'B+ Lizenz',
                B: 'B Lizenz',
                C: 'C Lizenz',
            },
            types: {
                coach: 'Trainer',
                scout: 'Scout',
                video: 'Videoanalyse',
                keeper: 'Torwarttrainer',
                athletic: 'Athletiktrainer',
                physio: 'Physiotherapeut',
                staff: 'Betreuer',
                official: 'Funktionär',
                coordinator: 'Koordinator',
                doc: 'Arzt',
            },
        },
        transfers: {
            name: 'Transfers',
            actions: {
                edit: 'Transfer ändern',
                create: 'Transfer anlegen',
            },
            fieldGroups: {
                arrangements: 'Club-Vereinabrungen',
                returnArrangements: 'individuelle Vereinabrungen',
            },
            teams: {
                U11: 'U11',
                U12: 'U12',
                U13: 'U13',
                U14: 'U14',
                U15: 'U15',
                U16: 'U16',
                U17: 'U17',
                U19: 'U19',
                U23: 'U23',
                VS: 'VS',
            },
            entry: {
                true: 'Ja',
                false: 'Nein',
            },
            fields: {
                createdby: 'Autor',
                createdate: 'eingetragen am',
                lastupdate: 'zuletzt geändert am',
                updatedby: 'Änderer',
                transferFee: 'Ablöse',
                entry: 'Neuzugang?',
                team: 'Team',
                returnArrangements: 'Rückkeher-Vereinbarungen',
                clubRegistration: 'Vereinsanmeldung?',
                playerPassportApplication: 'Spielberechtigungsantrag?',
                playerPassportPhoto: 'Pass-Foto?',
                confirmationOfCancellation: 'Bestätigung der Abmeldung?',
                consentGranted: 'Zustimmung des abgebenden Vereins?',
                transferFinished: 'Transfer vollzogen?',
                parentsInformed: 'Eltern informiert?',
                dfbnetApplicationSent:
                    'Antrag auf Vereinswechsel im DFBnet gesendet?',
                club_id: 'beteiligter Verein',
                team_id: 'Team',
                player_id: 'Spieler',
                season_id: 'Saison',
                comments: 'Notizen',
            },
        },
    },
    'ra-audit-log': {
        event: {
            create: 'hinzugefügt %{resource} %{name}',
            delete: 'gelöscht %{resource} %{name}',
            deleteMany: 'gelöscht %{resource} %{ids}',
            update: 'aktualisiert %{resource} %{name}',
            updateMany: 'aktualisiert %{resource} %{ids}',
        },
        inline_event: {
            create: '%{resource} hinzugefügt: %{name}',
            delete: '%{resource} gelöscht: %{name}',
            deleteMany: '%{resource} gelöscht: %{ids}',
            update: '%{resource} aktualisiert: %{name}',
            updateMany: '%{resource} aktualisiert: %{ids}',
        },
        record_event: {
            create: 'Resource hinzugefügt',
            delete: 'Resource gelöscht',
            update: 'Felder %{fields} aktualisiert |||| Aktualisierte Felder %{fields}',
        },
        record_many_fields_events: {
            create: 'Resource hinzugefügt',
            delete: 'Resource gelöscht',
            update: 'Feld aktualisiert %{fields} und ein weiteres |||| Felder aktualisiert %{fields} und %{smart_count} weitere',
        },
        author: '{fullName}',
        period_filters: {
            today: 'Heute',
            this_week: 'Diese Woche',
            this_month: 'Dieser Monat',
            this_quarter: 'Dieses Quartal',
        },
        filter: {
            author: 'Autor filtern',
            date: 'Datum filtern',
            resource: 'Resource filtern',
        },
        fields: {
            author: 'Autor',
            action: 'Aktion',
            date: 'Datum',
            resource: 'Resource',
        },
    },
};

const translations = { de };

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'de', // default locale
    [{ locale: 'de', name: 'Deutsch' }]
);
