import { FC } from "react";
import { FieldProps, useRecordContext, useTranslate } from "react-admin";
import get from "lodash/get";

export const TypeField: FC<FieldProps> = ({
  source = "type",
  resource = "calls",
}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const type = get(record, source);
  if (!type) return null;
  return (
    <span>{translate(`resources.${resource}.types.${type}`)}</span>
  );
};
