import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import get from 'lodash/get';
import { useMixedRatingStyles } from '../../types';
import { Fab, Tooltip } from '@mui/material';

type PlayerRatingIconProps = {
  rating: string;
};

export const PlayerRatingIcon: React.FC<PlayerRatingIconProps> = ({ rating }) => {
  const classes = useMixedRatingStyles();
  const translate = useTranslate();
  if (!rating) {
    return null;
  }
  const translated = translate(`resources.rating.${rating}`);
  const ratingColor = classes[rating];

  return (
    <Tooltip title={translated} aria-label={translated}>
      <Fab sx={ratingColor} size="small">
        {translated.slice(0, 1)}
      </Fab>
    </Tooltip>
  );
};

type PlayerRatingFieldProps = {
  source: string;
  label?: string;
};

export const PlayerRatingField: React.FC<PlayerRatingFieldProps> = ({ source = "rating" }) => {
  const record = useRecordContext();
  const rating = get(record, source);
  return <PlayerRatingIcon rating={rating} />;
};
