import { useAuthenticated } from "@s-graber/ra-rbac";
import React from "react";
import { Datagrid, List, TextField, EditButton, DateField } from "react-admin";

export const AgeclassList: React.FC = (props) => {
  useAuthenticated();
  return (
    <List {...props} sort={{ field: "name", order: "ASC" }}>
      <Datagrid>
        <TextField source="name" />
        <DateField source="lastupdate" label="pos.fields.lastupdate" />
        <TextField source="updatedby" label="pos.fields.updatedby" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
