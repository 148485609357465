import {
  Edit,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required,
  SimpleForm,
  SelectInput,
  useGetIdentity,
} from 'react-admin';
import React from 'react';
import { choicesCoachType, choiceCoachLicenses } from '../../types';
import { Box } from '@mui/material';

export const CoachEdit: React.FC = props => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if(isLoading || !allowedAccess) return null;
  return (
    <Edit {...props}>
      <SimpleForm>
        <Box display={{ sm: 'block', md: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput label="resources.coaches.fields.name" source="name" validate={required()} />
            <TextInput label="resources.coaches.fields.mobile" source="mobile" />
            <SelectInput label="resources.coaches.fields.type" source="type" choices={choicesCoachType} fullWidth />
            <SelectInput
              label="resources.coaches.fields.license"
              source="license"
              choices={choiceCoachLicenses}
            />
            <ReferenceInput
              label="resources.players.fields.currentClub"
              source="club_id"
              reference="clubs"
              filterToQuery={(searchText: any) => ({ q: searchText })}
              sort={{ field: 'name', order: 'ASC' }}
              fullWidth
              perPage={10}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </Box>
          <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput label="resources.coaches.fields.comments" multiline fullWidth source="comments" rows={10} />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};
