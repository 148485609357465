import React, { cloneElement, Fragment, useCallback } from "react";
import {
  Count,
  Filter,
  usePermissions,
  TextField,
  ReferenceArrayField,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FunctionField,
  List,
  SingleFieldList,
  ChipField,
  Identifier,
  ListContextProvider,
  useListContext,
  CreateButton,
  sanitizeListRestProps,
  TopToolbar,
  Pagination,
  DatagridConfigurable,
  ReferenceField,
  SelectColumnsButton,
  RaRecord,
} from "react-admin";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Tabs,
  Tab,
  Drawer,
  Theme,
  useMediaQuery,
} from "@mui/material";
import green from "@mui/material/colors/green";
import orange from "@mui/material/colors/orange";
import red from "@mui/material/colors/red";
import grey from "@mui/material/colors/grey";
import { SquadplayerAside } from "./SquadplayerAside";
import {
  choicesClubStatus,
  choicesFoot,
  choicesRatings,
  choicesPlayerStatus,
  convertBirthdateToAge,
} from "../../types";
import { BulkDelete } from "./buttons/BulkDelete";
import SquadplayerMobileGrid from "./SquadplayerMobileGrid";
import { SquadplayerStatusField } from "../../components/fields/SquadplayerStatusField";
import { PositionChipField } from "../../components/fields/PositionChipField";
import { AvatarField } from "../../components/fields/AvatarField";
import { NameField } from "../../components/fields/NameField";
import { styled } from "@mui/styles";
import { IfCanAccess } from "@s-graber/ra-rbac";
import { FootField } from "../../components/fields/FootField";
import { BulkSquadplayerStatus } from "./buttons/BulkSquadplayerStatus";
import { BulkTransferCreate } from "./buttons/BulkTransferCreate";

const PREFIX = "SquadplayerList";
const classes = {
  button: `${PREFIX}-button`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.button}`]: {
    marginTop: "1em",
  },
}));

const rowStyle = (selectedRow?: Identifier) => (record: RaRecord) => {
  let style = {};
  if (!record) {
    return style;
  }
  if (selectedRow && selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: grey,
    };
  }
  if (record && record.lineupstatus === "planned")
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  if (record && record.lineupstatus === "open")
    return {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  return {
    ...style,
    borderLeftColor: red[500],
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
  };
};

export const SquadplayerListDatagrid = ({ selectedRow }) => {
  // const [location] = useState(useLocation());
  // const rowClick = (id, basePath) => {
  //   if (!location) return null;
  //   return `${basePath}/${id}${location.search}`;
  // };
  return (
    <DatagridConfigurable
      bulkActionButtons={<PlayerBulkActionButtons />}
      rowStyle={rowStyle(selectedRow)}
      rowClick="edit"
      omit={["contractUntil", "height"]}
    >
      <AvatarField
        label="resources.players.fields.rating"
        source="name"
        recordSourceRating="rating"
        recordSourceAvatar="photo.src"
        sortBy="rating"
      />
      <NameField
        source="name"
        label="resources.players.fields.name"
        player_id="player_id"
        sortBy="lineupstatus"
      />
      <FunctionField
        sortBy="birthdate"
        label="resources.players.fields.birthdate"
        render={(record: any) =>
          convertBirthdateToAge(record, "birthdate", "agegroup")
        }
      />
      <ReferenceField
        label="resources.players.fields.currentClub"
        source="club_id"
        reference="clubs"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField
        label="resources.players.fields.height"
        source="height"
        emptyText="-"
      />
      <TextField
        label="resources.players.fields.city"
        source="city"
        emptyText="-"
      />
      <FootField source="foot" label="resources.players.fields.foot" />,
      <SquadplayerStatusField label="resources.squadplayers.fields.status" />
      <ReferenceArrayField
        label="resources.players.fields.positions"
        source="position_ids"
        reference="positions"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <PositionChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="resources.players.fields.repTeams"
        source="repteam_ids"
        reference="repteams"
      >
        <SingleFieldList>
          <ChipField
            clickable={false}
            color="secondary"
            variant="outlined"
            source="slug"
          />
        </SingleFieldList>
      </ReferenceArrayField>
      <IfCanAccess action="read" resource="players.contractUntil">
        <FunctionField
          label="resources.players.fields.contractUntil"
          source="contractUntil"
          sortBy="contractUntil"
          render={(record: any) => {
            if (record.contractUntil) {
              const contractUntilDate = new Date(record.contractUntil);
              const contractUntil = contractUntilDate.toLocaleDateString(
                "de-DE",
                {
                  year: "numeric",
                }
              );
              return `${contractUntil}`;
            }
            return "";
          }}
        />
      </IfCanAccess>
    </DatagridConfigurable>
  );
};

const SquadplayerFilter: React.FC = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="resources.squadplayers.fields.squad"
        source="team_id"
        reference="teams"
        sort={{ field: "name", order: "DESC" }}
        filter={{ current: true }}
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="resources.squadplayers.fields.player" source="name" />
      <SelectInput
        label="resources.squadplayers.fields.playerstatus"
        source="playerStatus"
        choices={choicesPlayerStatus}
        fullWidth
      />
      <SelectInput
        label="resources.squadplayers.fields.clubstatus"
        source="clubStatus"
        choices={choicesClubStatus}
        fullWidth
      />
      <ReferenceArrayInput
        label="resources.players.fields.positions"
        source="positionids"
        reference="positions"
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="resources.players.fields.repTeams"
        source="repteamids"
        reference="repteams"
      >
        <SelectArrayInput optionText="slug" />
      </ReferenceArrayInput>
      <SelectInput
        source="foot"
        label="resources.players.fields.foot"
        choices={choicesFoot}
        fullWidth
      />
      <SelectInput
        source="rating"
        label="resources.players.fields.rating"
        choices={choicesRatings}
        fullWidth
      />
    </Filter>
  );
};

const PlayerBulkActionButtons: React.FC<any> = (props) => (
  <Fragment>
    <BulkTransferCreate {...props} />
    <Divider orientation="vertical" flexItem />
    <BulkSquadplayerStatus field="lineupstatus" value="notplanned" {...props} />
    <BulkSquadplayerStatus field="lineupstatus" value="open" {...props} />
    <BulkSquadplayerStatus field="lineupstatus" value="planned" {...props} />
    <Divider orientation="vertical" flexItem />
    <BulkSquadplayerStatus field="scoutingstatus" value="member" {...props} />
    <BulkSquadplayerStatus field="scoutingstatus" value="scouted" {...props} />
    <BulkSquadplayerStatus field="scoutingstatus" value="open" {...props} />
    <Divider orientation="vertical" flexItem />
    <BulkDelete {...props} />
    <Divider orientation="vertical" flexItem />
  </Fragment>
);

const tabs = [
  { id: "member", name: "akt. Kader" },
  { id: "scouted", name: "Sichtung" },
  { id: "open", name: "nicht gesichtet" },
];

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, hasCreate, ...rest } =
    props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  const { isLoading } = usePermissions();
  const create =
    filterValues && filterValues.team_id
      ? {
        pathname: `/squadplayers/create`,
        state: { record: { team_id: filterValues.team_id } },
      }
      : { pathname: `/squadplayers/create` };
  // if (isLoading) return null;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <SelectColumnsButton />
      <CreateButton to={create} />
    </TopToolbar>
  );
};

const TabbedPlayerList = ({ selectedRow }) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, scoutingstatus: value },
          displayedFilters
        );
    },
    [displayedFilters, filterValues, setFilters]
  );
  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.scoutingstatus}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            value={choice.id}
            label={
              <span>
                {choice.name}
                {" ("}
                {
                  <Count
                    filter={{
                      ...filterValues,
                      scoutingstatus: choice.id,
                    }}
                    sx={{ lineHeight: "inherit" }}
                  />
                }
                {") "}
              </span>
            }
          />
        ))}
      </Tabs>
      <Divider />
      {isXSmall ? (
        <ListContextProvider value={{ ...listContext }}>
          <SquadplayerMobileGrid />
        </ListContextProvider>
      ) : (
        <Fragment>
          {filterValues.scoutingstatus === "member" && (
            <ListContextProvider value={{ ...listContext }}>
              <SquadplayerListDatagrid selectedRow={selectedRow} />
            </ListContextProvider>
          )}
          {filterValues.scoutingstatus === "open" && (
            <ListContextProvider value={{ ...listContext }}>
              <SquadplayerListDatagrid selectedRow={selectedRow} />
            </ListContextProvider>
          )}
          {filterValues.scoutingstatus === "scouted" && (
            <ListContextProvider value={{ ...listContext }}>
              <SquadplayerListDatagrid selectedRow={selectedRow} />
            </ListContextProvider>
          )}
        </Fragment>
      )}
      {/* <RatingLegend ratings={memberRatings} id="intern" />
      <RatingLegend ratings={scoutingRatings} id="extern" /> */}
    </Fragment>
  );
};

const SquadplayerPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

export const SquadplayerList = ({ ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate("/squadplayers");
  }, [navigate]);

  const storedProfile = localStorage.getItem("profile");
  const filterDefaultValues = { scoutingstatus: "member" };
  if (storedProfile) {
    let { team_id } = JSON.parse(storedProfile);
    filterDefaultValues["team_id"] = team_id;
  }
  const match = matchPath("/squadplayers/:id", location.pathname);
  return (
    <Box display="flex">
      <List
        {...props}
        actions={<ListActions />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? "400px" : 0,
        }}
        filterDefaultValues={filterDefaultValues}
        filters={<SquadplayerFilter />}
        sort={{ field: "lineupstatus", order: "DESC" }}
        pagination={<SquadplayerPagination />}
        perPage={50}
      >
        <TabbedPlayerList
          selectedRow={
            !!match ? parseInt((match as any).params.id, 10) : undefined
          }
          {...props}
        />
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {!!match && (
          <SquadplayerAside
            id={match.params.id}
            onCancel={handleClose}
            {...props}
          />
        )}
      </Drawer>
    </Box>
  );
};
