/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CallIcon from "@mui/icons-material/EventNote";
import { Button, useDataProvider } from "react-admin";
import { styled } from "@mui/styles";

const PREFIX = "AddPlayerButton";
const classes = {
  button: `${PREFIX}-button`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.button}`]: {
    marginTop: "1em",
  },
}));

export const AddPlayerButton: React.FC<{
  resourceName: "call" | "sprintvalue" | "scouting" | "review" | "absence";
}> = ({ resourceName }) => {
  const location = useLocation();
  const [player_id, setPlayerId] = useState<string | null>(null);
  const dataProvider = useDataProvider();
  const [, resource, pageId] = location.pathname.split("/");
  const getData = useCallback(
    async () => {
      const { data: squadPlayer } = await dataProvider.getOne("squadplayers", {
        id: pageId,
      });
      setPlayerId(squadPlayer.player_id);
    },
    [dataProvider, pageId] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(() => {
    if (resource === "squadplayers" && player_id !== pageId) {
      getData();
    } else if (resource === "players" && player_id !== pageId) {
      setPlayerId(pageId);
    }
  }, [resource, pageId]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!player_id) return null;
  return (
    <Button
      className={classes.button}
      component={Link}
      color="primary"
      to={{
        pathname: `/${resourceName}s/create`,
        state: { record: { player_id: player_id } },
      }}
      label={`resources.players.page.add_${resourceName}`}
    >
      <CallIcon />
    </Button>
  );
};
