import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Filter,
  EditButton,
  List,
  TextField,
  usePermissions,
  SearchInput,
  DateField,
  TopToolbar,
  useDataProvider,
  useNotify,
  ReferenceField,
  RaRecord,
  ListContextProvider,
  DatagridProps,
  useListContext,
  Link,
  SelectColumnsButton,
  DatagridConfigurable,
} from "react-admin";
import { ImportButton, ImportConfig } from "react-admin-import-csv";
import {
  CreateButton,
  FunctionField,
  ReferenceInput,
  SelectInput,
} from "ra-ui-materialui";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import { choicesGameType, getGameTime } from "../../types";
import Button from "@mui/material/Button";
import { Divider, Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DataGridContent = (props: DatagridProps) => {
  return (
    <DatagridConfigurable
      {...props}
      omit={["lastupdate", "updatedby", "venue"]}
      rowClick="edit"
    >
      <TextField label="resources.matches.fields.id" source="id" />
      <DateField
        label="resources.matches.fields.date"
        source="date"
        options={{
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "short",
        }}
      />
      <TextField
        label="resources.matches.fields.matchTime"
        source="matchTime"
      />
      <ReferenceField source="team_id" reference="teams">
        <FunctionField
          label="resources.matches.fields.team_id"
          source="name"
          render={(record: any) =>
            record.id ? (
              <Button
                size="small"
                color="primary"
                component={Link}
                to={{ pathname: `/teams/${record.id}/match_stats` }}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {/* <FormatListNumberedIcon />  */}
                {`${record.name}`}
              </Button>
            ) : (
              "-"
            )
          }
        />
      </ReferenceField>
      <TextField label="resources.matches.fields.homeTeam" source="homeTeam" />
      <TextField
        label="resources.matches.fields.guestTeam"
        source="guestTeam"
      />
      <TextField label="resources.matches.fields.league" source="league" />
      <FunctionField
        label="resources.matches.fields.isHome"
        render={(record: any) => (record.isHome ? <CheckIcon /> : null)}
      />
      <TextField label="resources.matches.fields.venue" source="venue" />
      <DateField
        label="resources.matches.fields.lastupdate"
        source="lastupdate"
        options={{
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }}
      />
      <TextField
        label="resources.matches.fields.updatedby"
        source="updatedby"
      />
      <EditButton label="" />
    </DatagridConfigurable>
  );
};

export const MatchFilter: React.FC = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <ReferenceInput
        label="resources.squadplayers.fields.squad"
        source="team_id"
        reference="teams"
        sort={{ field: "name", order: "ASC" }}
        filter={{ currentSeason: true }}
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="resources.matches.fields.isHome"
        source="isHome"
        choices={choicesGameType}
        alwaysOn
      />
    </Filter>
  );
};

const ListActions = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const lookupSeasonId = async (season) => {
    const { data: seasonData } = await dataProvider.getList("seasons", {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "name", order: "ASC" },
      filter: { name: season },
    });
    if (seasonData.length === 0) return false;
    return seasonData[0]["id"];
  };
  const lookupAgeclassId = async (teamType) => {
    const { data: ageclassData } = await dataProvider.getList("ageclasses", {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "name", order: "ASC" },
      filter: { name: teamType },
    });
    if (ageclassData.length === 0) return false;
    return ageclassData[0]["id"];
  };
  const getTeams = async (dfbnetTeamName, seasonId, ageclassId) => {
    const { data: teamData, total } = await dataProvider.getList("teams", {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "name", order: "DESC" },
      filter: {
        dfbnetTeamName: dfbnetTeamName,
        season_id: seasonId,
        ageclass_id: ageclassId,
      },
    });
    return total === 1 ? teamData : null;
  };

  const getTeamId = async (row) => {
    if (
      !row.homeTeam ||
      !row.guestTeam ||
      (row.venue && row.venue.startsWith("verlegt auf"))
    )
      return null;
    row.date = moment(`${row.matchDate} ${row.matchTime}`, "DD.MM.YYYY hh:mm");
    row.isHome = row.homeTeam.startsWith("SC Preußen Münster") ? 1 : 0;
    row.isOfficial = 0
    if (row.type === 'Pokal') {
      row.isOfficial = 2
    } else if (row.type === 'Meisterschaft') {
      row.isOfficial = 1
    }
    row.source = "csv";
    row.lastupdate = new Date();
    row.updatedby = "DFBNet CSV-Import";
    row.gameTime = getGameTime(row.teamType, row.series);
    let dfbnetTeamName = row.isHome ? row.homeTeam : row.guestTeam;
    dfbnetTeamName = dfbnetTeamName
      .replace('SC Preußen Münster II (U16) 2', 'SC Preußen Münster (U16)')
      .replace('SC Preußen Münster II (U16)', 'SC Preußen Münster (U16)')
      .replace('SC Preußen Münster U16', 'SC Preußen Münster (U16)')

    dfbnetTeamName = dfbnetTeamName
      .replace('SC Preußen Münster II (U14)', 'SC Preußen Münster (U14)')
      .replace('SC Preußen Münster U14', 'SC Preußen Münster (U14)')

    dfbnetTeamName = dfbnetTeamName
      .replace('SC Preußen Münster II (U12)', 'SC Preußen Münster (U12)')
      .replace('SC Preußen Münster U12', 'SC Preußen Münster (U12)')

    dfbnetTeamName = (dfbnetTeamName == 'SC Preußen Münster II' && row.teamType && row.teamType.indexOf('D-Junioren') !== -1)
      ? 'SC Preußen Münster (U12)' : dfbnetTeamName
    dfbnetTeamName = (dfbnetTeamName == 'SC Preußen Münster II' && row.teamType && row.teamType.indexOf('C-Junioren') !== -1)
      ? 'SC Preußen Münster (U14)' : dfbnetTeamName
    dfbnetTeamName = (dfbnetTeamName == 'SC Preußen Münster II' && row.teamType && row.teamType.indexOf('B-Junioren') !== -1)
      ? 'SC Preußen Münster (U16)' : dfbnetTeamName

    const season = row.season
      .split("/")
      .map((x) => `20${x}`)
      .join("/");
    const seasonId = await lookupSeasonId(season);
    const ageclassId = await lookupAgeclassId(row.teamType);
    if (dfbnetTeamName && ageclassId && seasonId) {
      if (
        row.teamType === "Herren" &&
        dfbnetTeamName === "SC Preußen Münster"
      ) {
        console.log("ignoring SC Preußen Münster - Herren", row);
        delete row.team_id;
      } else {
        console.log(
          `checking ${dfbnetTeamName}, ${row.teamType}=${ageclassId}, ${row.season}=${seasonId}`
        );
        const teamData = await getTeams(dfbnetTeamName, seasonId, ageclassId);
        console.log("teamData", teamData);
        if (teamData && teamData.length > 0) {
          row.team_id = teamData.pop().id ?? "";
          console.log("row.team_id", row.team_id);
        } else {
          console.log("failed row.team_id", row.team_id);
          notify(
            `failed to map team for ${dfbnetTeamName}, ${row.teamType}=${ageclassId}, ${row.season}=${seasonId}, `,
            { type: "warning" }
          );
        }
      }
    }
    const seasonMatchId = row.season
      ?.split('/')
      .flatMap(x => `${x}`)
      .join('');
    row.id = `${row.id}-${seasonMatchId}`;
    return row;
  };

  const config: ImportConfig = {
    logging: true,
    parseConfig: {
      // For all options see: https://www.papaparse.com/docs#config
      delimiter: "\t", // auto-detect
      dynamicTyping: true,
    },
    // validateRow: async (row) => {
    //   if (row && !row.id) {
    //     throw new Error("Spielkennung fehlt!");
    //   }
    // },
    preCommitCallback: async (action, values: any[]) => {
      const rows = await Promise.all(values.map(getTeamId));
      return rows.filter((n) => n);
    },
    // transformRows: async (csvRows: any[]) => {
    //   const rows = await Promise.all(csvRows.map(getTeamId));
    //   console.log('transformRows', rows);
    //   return rows.filter(n => n);
    // },
    disableCreateMany: true,
  };
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton />
      <SelectColumnsButton />
      <ImportButton {...props} {...config} />
    </TopToolbar>
  );
};

const tabs = [
  { id: "all", name: "all", label: "alle Spiele" },
  { id: "upcoming", name: "upcoming", label: "zukünftige Spiele" },
  { id: "past", name: "past", label: "vergangene Spiele" },
];

interface TabbedDatagridProps extends DatagridProps { }

const TabbedDatagrid = (props: TabbedDatagridProps) => {
  const listContext = useListContext();
  const navigate = useNavigate();
  const [tab, setTab] = useState("all");
  const { data, filterValues, displayedFilters } = listContext;
  const [upcoming, setUpcoming] = useState<RaRecord[]>([] as RaRecord[]);
  const [past, setPast] = useState<RaRecord[]>([] as RaRecord[]);
  useEffect(() => {
    if (tab && data) {
      if (tab === "upcoming" && !upcoming) {
        setUpcoming(data);
      } else if (tab === "past" && !past) {
        setPast(data);
      }
    }
  }, [data, tab, past, upcoming]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      if (value === "upcoming") {
        navigate({
          search: `displayedFilters=${encodeURI(
            JSON.stringify(displayedFilters)
          )}&filter=${encodeURI(
            JSON.stringify({
              ...filterValues,
              "date@gte": new Date(),
              "date@lte": undefined,
            })
          )}&order=ASC&sort=date`,
        });
        setTab(value);
      } else if (value === "past") {
        // setFilters({ ...filterValues, 'date@lte': new Date(), 'date@gte': undefined }, displayedFilters);
        // setSort('date', 'ASC');
        navigate({
          search: `displayedFilters=${encodeURI(
            JSON.stringify(displayedFilters)
          )}&filter=${encodeURI(
            JSON.stringify({
              ...filterValues,
              "date@lte": new Date(),
              "date@gte": undefined,
            })
          )}&order=DESC&sort=date`,
        });
        setTab(value);
      } else if (value === "all") {
        navigate({
          search: `displayedFilters=${encodeURI(
            JSON.stringify(displayedFilters)
          )}&filter=${encodeURI(
            JSON.stringify({
              ...filterValues,
              "date@lte": undefined,
              "date@gte": undefined,
            })
          )}&order=ASC&sort=id`,
        });
        setTab(value);
      }
    },
    [displayedFilters, filterValues]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={tab}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.label} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      <div>
        {/* {tab === "upcoming" && (
          <ListContextProvider value={{ ...listContext, data: data }}>
            <DataGridContent {...props} />
          </ListContextProvider>
        )}
        {tab === "past" && (
          <ListContextProvider value={{ ...listContext, data: data }}>
            <DataGridContent {...props} />
          </ListContextProvider>
        )}
        {tab === "all" && ( */}
        <ListContextProvider value={{ ...listContext, data: data }}>
          <DataGridContent {...props} />
        </ListContextProvider>
        {/* )} */}
      </div>
    </Fragment>
  );
};

export const MatchList: React.FC = (props) => {
  const { isLoading } = usePermissions();
  if (isLoading) return null;
  return (
    <List
      {...props}
      perPage={25}
      actions={<ListActions />}
      filters={<MatchFilter />}
      filterDefaultValues={{ "date@gte": new Date().toISOString() }}
      sort={{ field: "date", order: "ASC" }}
    >
      <TabbedDatagrid />
    </List>
  );
};
