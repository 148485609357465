import {
  BooleanInput,
  DateInput,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  required,
  SelectInput,
  useTranslate,
} from "react-admin";
import LinkedNameFromPracticeInput from "../../../components/inputs/LinkedNameFromPracticeInput";
import LinkedTimeInput from "../../../components/inputs/LinkedTimeInput";
import { validateTime } from "../../../types";

export const TrainingDetailsFormTab = (props) => {
  const translate = useTranslate();
  const getWeekdays = (choice) => {
    return choice && choice.daysOfWeek
      ? choice.daysOfWeek
        .map((c) =>
          translate(`resources.practices.daysOfWeek.${c}`).slice(0, 2)
        )
        .join(", ")
      : "";
  };
  return (
    <FormTab label="resources.trainings.tabs.details" path="details" {...props}>
      <ReferenceInput
        label="resources.trainings.fields.team_id"
        source="team_id"
        reference="teams"
        filter={{ currentSeason: true }}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData }) => {
          return formData.team_id ? (
            <ReferenceInput
              label="resources.trainings.fields.practice_id"
              source="practice_id"
              reference="practices"
              filter={{ team_id: formData.team_id }}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput
                optionText={(choice: any) =>
                  `${choice.name} (${getWeekdays(choice)})`
                }
              />
            </ReferenceInput>
          ) : null;
        }}
      </FormDataConsumer>
      <LinkedNameFromPracticeInput
        label="resources.trainings.fields.name"
        source="name"
        validate={required()}
      />
      <DateInput label="resources.trainings.fields.date" source="date" />
      <LinkedTimeInput
        label="resources.trainings.fields.meetingTime"
        source="meetingTime"
        validate={validateTime}
      />
      <LinkedTimeInput
        label="resources.trainings.fields.startTime"
        source="startTime"
        validate={validateTime}
      />
      <LinkedTimeInput
        label="resources.trainings.fields.endTime"
        source="endTime"
        validate={validateTime}
      />
      <BooleanInput label="resources.trainings.fields.linkedPlayers" source="linkedPlayers" />
    </FormTab>
  );
};
