import { useNotify, useUnselectAll } from 'ra-core';
import React, { useState } from 'react';
import { Button, useGetIdentity, useRefresh } from 'react-admin';
import { useAuthProvider } from 'ra-core';
import { useEffect } from 'react';
import IconContentAdd from '@mui/icons-material/Add';
import { fetchFromSupabseFunction, getBackendUrl } from '../../types';
import { useMsal } from '@azure/msal-react';


export const BulkConvertApplication: React.FC<{
  selectedIds: any;
}> = ({ selectedIds }) => {
  const unselect = useUnselectAll('applications');
  const notify = useNotify();
  const refresh = useRefresh();
  const authProvider = useAuthProvider();
  const [jwtToken, setJwtToken] = useState();
  const { identity } = useGetIdentity();
  const [loading, setLoading] = useState(false);
  const { instance: msalInstance } = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await authProvider.getAccessToken(msalInstance)
      setJwtToken(accessToken);
    };
    fetchData();
  }, [authProvider]);

  const handleSave = () => {
    setLoading(true);
    fetchFromSupabseFunction('applications-convert', { ids: selectedIds, identity }, jwtToken)
      .then(response => response.json())
      .then(data => {
        console.log('applications-convert', { data });
        for (const elem of data.names) {
          if (elem.type === 'update') {
            notify('resources.applications.notification.player_updated',  { type: 'info', messageArgs: { name: elem.name } }, );
          } else if (elem.type === 'create') {
            notify('resources.applications.notification.player_added', { type: 'info', messageArgs: { name: elem.name } });
          }
        }
      })
      .catch(e => {
        notify(e.message, { type: 'error' });
      })
      .finally(() => {
        setLoading(false);
        unselect();
        refresh();
      });
  };
  return (
    <Button label="resources.applications.actions.add_player" onClick={handleSave} disabled={loading}>
      <IconContentAdd />
    </Button>
  );
};
