import { get } from "lodash";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  TextInput,
  sanitizeInputRestProps,
  TextInputProps,
  useDataProvider,
} from "react-admin";

const LinkedNameFromPracticeInput = ({
  resource,
  source,
  ...rest
}: TextInputProps) => {
  const { getValues } = useFormContext();
  const { practice_id, ...values } = getValues();
  const dataProvider = useDataProvider();
  const getData = useCallback(async () => {
    const { data } = (await dataProvider.getOne("practices", {
      id: practice_id,
    })) || { data: null };
    const name = `${get(data, "name")}`;
    values[source] = name;
  }, [dataProvider, practice_id]);
  useEffect(() => {
    if (practice_id) {
      getData();
    }
  }, [practice_id]);
  return <TextInput source={source} {...sanitizeInputRestProps(rest)} />;
};

export default LinkedNameFromPracticeInput;
