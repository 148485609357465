/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  ResourceBreadcrumbItems,
} from "@s-graber/ra-navigation";
import { get } from "lodash";
import { linkToRecord, useDataProvider, useRecordContext } from "react-admin";
import { Box } from "@mui/material";

export const PlayerBreadcrumb: React.FC<{
  source?: string;
  player_id?: string;
}> = (props) => {
  const { source, player_id } = props;
  const record = useRecordContext(props) || get(props, "data");
  const [player, setPlayer] = useState(null);
  const dataProvider = useDataProvider();
  let id;
  if (player_id) {
    id = player_id;
  }
  if (record && source && !id) {
    id = get(record, source);
    if (source === "id" && !player) {
      setPlayer(record);
    }
  }
  if (record && !id) {
    id = get(record, "id");
    setPlayer(record);
  }
  const getData = useCallback(
    async () => {
      if (id) {
        await dataProvider.getOne("players", { id }).then((player) => {
          if (player) {
            const { data } = player;
            setPlayer(data);
          }
        });
      }
    },
    [dataProvider, id] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(() => {
    getData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!player) return null;
  return (
    <Box ml={{ sm: "0.5em" }} mb={{ sm: "0.5em" }}>
      <Breadcrumb hasDashboard={false}>
        <ResourceBreadcrumbItems resources={["otherResources"]} />

        <BreadcrumbItem
          name="players"
          to="/players"
          label="resources.players.name"
        >
          <BreadcrumbItem
            name="edit"
            label={`${get(player, "name")}`}
            to={`${linkToRecord("/players", get(player, "id"))}`}
          />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="sprintvalues"
          to={`/players/${player.id}/1`}
          label="resources.players.name"
        >
          <BreadcrumbItem name="create" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="create" label="resources.sprintvalues.name" />
          <BreadcrumbItem name="edit" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="edit" label="resources.sprintvalues.name" />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="scoutings"
          to={`/players/${player.id}/2`}
          label="resources.players.name"
        >
          <BreadcrumbItem name="create" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="create" label="resources.scoutings.name" />
          <BreadcrumbItem name="edit" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="edit" label="resources.scoutings.name" />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="calls"
          to={`/players/${player.id}/3`}
          label="resources.players.name"
        >
          <BreadcrumbItem name="create" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="create" label="resources.calls.name" />
          <BreadcrumbItem name="edit" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="edit" label="resources.calls.name" />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="reviews"
          to={`/players/${player.id}/4`}
          label="resources.players.name"
        >
          <BreadcrumbItem name="create" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="create" label="resources.reviews.name" />
          <BreadcrumbItem name="edit" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="edit" label="resources.reviews.name" />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="absences"
          to={`/players/${player.id}/7`}
          label="resources.players.name"
        >
          <BreadcrumbItem name="create" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="create" label="resources.absences.name" />
          <BreadcrumbItem name="edit" label={`${get(player, "name")}`} />
          <BreadcrumbItem name="edit" label="resources.absences.name" />
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
};
