import {
  Datagrid,
  List,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  BooleanInput,
  useGetIdentity,
} from 'react-admin';
import { DfbnetEmailShow } from './DfbnetEmailShow';
import { JsonField } from 'react-admin-json-view';

const DfbnetEmailFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput
      label="resources.dfbnet_emails.fields.search"
      source="text@ilike"
      alwaysOn
    />
    <BooleanInput label="resources.dfbnet_emails.fields.success" source="success" />
  </Filter>
);

export const DfbnetEmailList = props => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if(isLoading || !allowedAccess) return null;
  return (
    <List {...props} filters={<DfbnetEmailFilter />} sort={{ field: 'createdate', order: 'DESC' }}>
      <Datagrid rowClick="expand" expand={<DfbnetEmailShow />}>
        <ReferenceField label="resources.dfbnet_emails.fields.match_id" source="match_id" reference="matches">
          <TextField source="id" />
        </ReferenceField>
        <TextField source="subject" sortBy="subject" />
        <JsonField
          source="doc"
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        {/* <TextField source="receiver" sortBy="receiver" />
        <TextField source="sender" sortBy="sender" /> */}
        <TextField source="crud" sortBy="crud" />
        <BooleanField label="resources.dfbnet_emails.fields.success" source="success" />
        <DateField
          label="resources.dfbnet_emails.fields.createdate"
          source="createdate"
          options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
        />
        <TextField label="resources.dfbnet_emails.fields.createdby" source="createdby" />
      </Datagrid>
    </List>
  );
};
