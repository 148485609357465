import { Logout, useGetIdentity, UserMenu, useUserMenu } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RoleIcon from "@mui/icons-material/LockOpen";
import { forwardRef, ReactNode, useEffect, useState } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { msalConfig } from "../authConfig";
import Avatar from "@mui/material/Avatar";


const RolesMenu = (props, ref) => {
  const { onClose } = useUserMenu();
  const { isLoading, data } = useGetIdentity();
  if (isLoading) return null;
  const roles = data?.roles ?? [];
  return roles.map((role) => (
        <MenuItem {...props} key={role} onClick={onClose}>
          <ListItemIcon>
            <RoleIcon />
          </ListItemIcon>
          <ListItemText>{role}</ListItemText>
        </MenuItem>
      ));
};


const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const ScpUserMenu = () => {
  const { instance: msalInstance } = useMsal();
  const [avatar, setAvatar] = useState<ReactNode>();
  const callMsGraph = async (token, userId) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
      method: "GET",
      headers,
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error, status = ${response.status}`);
      }
      return response.blob()
    })
    .then(blobToBase64)
    .then(res => {
      const avatar = <Avatar src={`${res}`} sx={{ height: 30,width: 30 }} />
      setAvatar(avatar);
    });
  }

  const requestProfileData = async () => {
    const instance = await msalInstance.
      acquireTokenSilent({
        scopes: ["openid", "profile", "user.read"],
        authority: msalConfig.auth.authority,
      })
    if(instance?.accessToken){
      callMsGraph(instance.accessToken, instance.uniqueId).then((data) => {
      });
    }
  }

  useEffect(() => {
      requestProfileData();
  }, [msalInstance]);

  return (
  <UserMenu icon={avatar}>
    <RolesMenu />
    <Logout />
  </UserMenu>
);
}
