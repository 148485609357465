import {
    Edit,
    AutocompleteInput,
    ReferenceInput,
    TextInput,
    required,
    SelectInput,
    SimpleForm,
    BooleanInput,
    NumberInput,
    useGetOne,
    usePermissions,
    useGetIdentity,
    useTranslate,
    ReferenceField,
    TextField,
} from 'react-admin';
import { convertBirthdateToAge, choiceTeams } from '../../types';
import { get } from 'lodash';
import { Box, Typography } from '@mui/material';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { PlayerTabInfoToolbar } from '../players/PlayerTabInfoToolbar';
import { useLocation } from 'react-router-dom';

export const TransferEdit = props => {
    const location = useLocation();
    const translate = useTranslate();
    const [, , id] = location.pathname.split('/');
    const { isLoading, data } = useGetOne('transfers', { id });
    const {
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading || !allowedAccess) return null;
    return (
        <Edit
            title={`resources.transfers.actions.edit`}
            actions={<PlayerBreadcrumb player_id={data.player_id} {...props} />}
            redirect={`/players/${data.player_id}/6`}
        >
            <SimpleForm toolbar={<PlayerTabInfoToolbar record={data} />}>
                <Box display={{ sm: 'block', md: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <ReferenceInput
                            source="player_id"
                            reference="players"
                            validate={[required()]}
                            sort={{ field: 'name', order: 'ASC' }}
                        >
                            <AutocompleteInput
                                label="resources.transfers.fields.player_id"
                                optionValue="id"
                                shouldRenderSuggestions={(val: any) => {
                                    return val.trim().length > 2;
                                }}
                                optionText={choice => {
                                    const birthdate = convertBirthdateToAge(
                                        choice,
                                        'birthdate',
                                        'agegroup'
                                    );
                                    return `${get(
                                        choice,
                                        'name'
                                    )} - ${birthdate}`;
                                }}
                                filterToQuery={(searchText: any) => ({
                                    'name@ilike': `%${searchText}%`,
                                })}
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            label="resources.transfers.fields.season_id"
                            source="season_id"
                            reference="seasons"
                            sort={{ field: 'name', order: 'ASC' }}
                        >
                            <SelectInput
                                optionText="name"
                                translateChoice={false}
                            />
                        </ReferenceInput>
                        <SelectInput
                            label="resources.transfers.fields.team"
                            source="team"
                            choices={choiceTeams}
                        />
                        <ReferenceInput
                            source="club_id"
                            reference="clubs"
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={5}
                        >
                            <AutocompleteInput
                                label="resources.transfers.fields.club_id"
                                noOptionsText="-"
                                shouldRenderSuggestions={(val: any) => {
                                    return val.trim().length > 2;
                                }}
                                filterToQuery={(searchText: any) => ({
                                    'name@ilike': `%${searchText}%`,
                                })}
                                optionText="name"
                            />
                        </ReferenceInput>
                        <BooleanInput
                            label="resources.transfers.fields.entry"
                            source="entry"
                        />
                    </Box>
                    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                        <BooleanInput
                            label="resources.transfers.fields.parentsInformed"
                            source="parentsInformed"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.dfbnetApplicationSent"
                            source="dfbnetApplicationSent"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.clubRegistration"
                            source="clubRegistration"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.playerPassportApplication"
                            source="playerPassportApplication"
                        />
                        {/* <BooleanInput label="resources.transfers.fields.playerPassportPhoto" source="playerPassportPhoto" /> */}
                        <BooleanInput
                            label="resources.transfers.fields.confirmationOfCancellation"
                            source="confirmationOfCancellation"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.consentGranted"
                            source="consentGranted"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.transferFinished"
                            source="transferFinished"
                        />
                        <TextInput
                            label="resources.transfers.fields.comments"
                            source="comments"
                            multiline
                            fullWidth
                            rows={6}
                        />
                    </Box>
                    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                        {allowedAccess && (
                            <>
                            <Typography variant="h6" gutterBottom>
                              {translate('resources.transfers.fieldGroups.arrangements')}
                            </Typography>
                            <ReferenceField source="club_id" reference="clubs">
                              <TextField source="arrangements" label="resources.clubs.fields.arrangements" />
                            </ReferenceField>
            
                            <Typography variant="h6" gutterBottom>
                              {translate('resources.transfers.fieldGroups.returnArrangements')}
                            </Typography>
                                <TextInput
                                    label="resources.transfers.fields.returnArrangements"
                                    source="returnArrangements"
                                    multiline
                                    fullWidth
                                    rows={10}
                                />
                                <NumberInput
                                    label="resources.transfers.fields.transferFee"
                                    source="transferFee"
                                />
                            </>
                        )}
                        <Box mt={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.transfers.fields.createdate"
                                source="createdate"
                                disabled={true}
                                format={v =>
                                    new Date(v).toLocaleDateString('de-DE', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    })
                                }
                            />
                            <TextInput
                                label="resources.transfers.fields.createdby"
                                source="createdby"
                                disabled={true}
                            />
                            <TextInput
                                label="resources.transfers.fields.lastupdate"
                                source="lastupdate"
                                disabled={true}
                                format={v =>
                                    new Date(v).toLocaleDateString('de-DE', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    })
                                }
                            />
                            <TextInput
                                label="resources.transfers.fields.updatedby"
                                source="updatedby"
                                disabled={true}
                            />
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    );
};
