import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import DeleteSquadplayersIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router";
import {
  useTranslate,
  useNotify,
  useDataProvider,
  useRefresh,
} from "react-admin";

export const DeleteTrainingPlayersButton: React.FC = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const [, , training_id] = location.pathname.split("/");
  const dataProvider = useDataProvider();
  const handleSubmit = useCallback(
    async () => {
      setSubmitting(true);
      const { data: trainings_players, total } = await dataProvider.getList(
        "trainings_players",
        {
          pagination: { page: 1, perPage: 25 },
          sort: { field: "name", order: "ASC" },
          filter: {
            training_id: training_id,
          },
        }
      );
      console.log(`found ${total} trainings_players`);
      await dataProvider.update("trainings", {
        id: training_id,
        data: { linkedPlayers: false },
        previousData: null,
      });
      const ids = trainings_players.flatMap((x) => x.id);
      await dataProvider.deleteMany("trainings_players", { ids: ids });
      refresh();
      notify("resources.trainings.notification.deleted", {
        type: "info",
        undoable: false,
      });
    },
    [dataProvider] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Button onClick={handleSubmit} disabled={submitting}>
      <DeleteSquadplayersIcon />
      {translate("resources.trainings.actions.delete")}
    </Button>
  );
};
