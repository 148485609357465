import {
  DateField,
  Datagrid,
  List,
  EditButton,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
  required,
} from 'react-admin';
import { FullNameField } from '../../components/fields/FullNameField';
import { TreatmentShow } from './TreatmentShow';
import { convertBirthdateToAge } from '../../types';
import { get } from 'lodash';

const TreatmentFilter: React.FC = props => (
<Filter {...props}>
  <ReferenceInput
        source="player_id"
        reference="players"
        validate={[required()]}
        alwaysOn
        // options={{ disabled: true }}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          label="resources.treatments.fields.player"
          // disabled={true}
          optionValue="id"
          optionText={choice => {
            const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
            return `${get(choice, 'name')} - ${birthdate}`;
          }}
          filterToQuery={(searchText: any) => ({
            "name@ilike": `%${searchText}%`,
          })}
        />
    </ReferenceInput>
    <TextInput label="resources.treatments.fields.comments" source="q" />
  </Filter>
);

export const TreatmentList = props => {
  const { isLoading } = usePermissions();
  if(isLoading) return null;
  return (
    <List {...props} filters={<TreatmentFilter />} sort={{ field: 'date', order: 'DESC' }}>
      <Datagrid rowClick="expand" expand={<TreatmentShow />}>
        <ReferenceField label="resources.treatments.fields.player" source="player_id" reference="players">
          <FullNameField source="name" playerid="id" sourceRating="rating" sourceAvatar="photo.src" />
        </ReferenceField>
        <DateField label="resources.treatments.fields.date" source="date" sortBy="date" />
        {/* <TypeField label="resources.treatments.fields.type" source="type" resource='treatments' /> */}
        <TextField label="resources.treatments.fields.createdby" source="createdby" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
