import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  useDeleteMany,
  useNotify,
  useUnselectAll,
  useRefresh,
  useListContext,
  Identifier,
} from "react-admin";

const noSelection: Identifier[] = [];

export const BulkDelete = () => {
  const { selectedIds = noSelection } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("squadplayers");
  const [deleteMany, { isLoading }] = useDeleteMany();
  const handleOnClick = () => {
    deleteMany(
      "teams_players",
      {
        ids: selectedIds,
      },
      {
        mutationMode: "pessimistic",
        onSettled: () => {
          notify("resources.squadplayers.notification.deleted", {
            type: "info",
          });
          unselectAll();
          refresh();
        },
        onError: () => {
          notify("resources.squadplayers.notification.error", {
            type: "warning",
          });
        },
      }
    );
  };
  return (
    <Button
      label="resources.squadplayers.actions.delete"
      onClick={() => {
        handleOnClick();
      }}
      disabled={isLoading}
    >
      <DeleteIcon />
    </Button>
  );
};
