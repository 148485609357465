import {
  Edit,
  TextInput,
  SimpleForm,
  BooleanInput,
  required,
} from "react-admin";
import React from "react";
import { useAuthenticated } from "@s-graber/ra-rbac";

export const SeasonEdit: React.FC = (props) => {
  useAuthenticated();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <BooleanInput source="isCurrent" />
      </SimpleForm>
    </Edit>
  );
};
