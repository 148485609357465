/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import {
  FormTab,
  ReferenceManyField,
  Datagrid,
  DateField,
  NumberField,
  EditButton,
  DeleteButton,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { AddPlayerButton } from "../buttons/AddPlayerButton";

export const SprintvalueFormTab: React.FC = (props) => {
  const { pathname } = useLocation();
  return (
    <FormTab
      {...props}
      label="resources.players.tabs.sprintvalues"
      path="sprintvalues"
    >
      <ReferenceManyField
        sort={{ field: "date", order: "DESC" }}
        reference="sprintvalues"
        source="id"
        target="player_id"
      >
        <Datagrid>
          <DateField label="resources.sprintvalues.fields.date" source="date" />
          <NumberField
            label="resources.sprintvalues.fields.m5"
            locales="de-DE"
            options={{ maximumFractionDigits: 2 }}
            source="m5"
          />
          <NumberField
            label="resources.sprintvalues.fields.m10"
            locales="de-DE"
            options={{ maximumFractionDigits: 2 }}
            source="m10"
          />
          <NumberField
            label="resources.sprintvalues.fields.m20"
            locales="de-DE"
            options={{ maximumFractionDigits: 2 }}
            source="m20"
          />
          <NumberField
            label="resources.sprintvalues.fields.m30"
            locales="de-DE"
            options={{ maximumFractionDigits: 2 }}
            source="m30"
          />
          <EditButton label="" />
          <DeleteButton label="" redirect={pathname} />
        </Datagrid>
      </ReferenceManyField>
      <AddPlayerButton resourceName="sprintvalue" />
    </FormTab>
  );
};
