import { Card, CardHeader, CardContent, styled } from "@mui/material";
import {
  EditButton,
  TextField,
  useTranslate,
  ChipField,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
} from "react-admin";
import { FootField } from "../../components/fields/FootField";
import { FullNameField } from "../../components/fields/FullNameField";
import { convertBirthdateToAge } from "../../types";
import { PositionChipField } from "../../components/fields/PositionChipField";
import { get } from "lodash";

const PREFIX = "PlayerMobileGrid";
const classes = {
  card: `${PREFIX}-card`,
  cardTitleContent: `${PREFIX}-cardTitleContent`,
  cardContentRow: `${PREFIX}-cardContentRow`,
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.card}`]: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",
  },
  [`&.${classes.cardTitleContent}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`&.${classes.cardContentRow}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0.5rem 0",
  },
}));

const PlayerMobileGrid = ({ data }) => {
  const translate = useTranslate();
  return data.map((record, id) => (
    <Card key={id} className={classes.card}>
      <CardHeader
        title={
          <div className={classes.cardTitleContent}>
            <span>
              <FullNameField
                source="name"
                label="resources.players.fields.name"
                playerid="players_id"
                sortBy="lineupstatus"
                sourceRating="players.rating"
                sourceAvatar="photo.src"
              />
            </span>
            <EditButton resource="players" />
            {/* FIXME:
              <TalentdayScoutingDialog
              basepath={`${pathname}${search}`}
              player_id={id}
            /> */}
          </div>
        }
      />
      <CardContent>
        <span className={classes.cardContentRow}>
          {translate("resources.players.fields.birthdate")}:&nbsp;
          <FunctionField
            sortBy="birthdate"
            label="resources.players.fields.birthdate"
            render={(record: any) =>
              convertBirthdateToAge(record, "birthdate", "agegroup")
            }
          />
        </span>
        <span className={classes.cardContentRow}>
          {translate("resources.players.fields.currentClub")}
          :&nbsp;
          <ReferenceField
            label="resources.players.fields.currentClub"
            source="club_id"
            sortBy="clubs.name"
            reference="clubs"
          >
            <TextField source="name" />
          </ReferenceField>
        </span>
        <span className={classes.cardContentRow}>
          {translate("resources.players.fields.city")}
          :&nbsp;
          <TextField
            label="resources.players.fields.city"
            source="city"
            emptyText="-"
          />
        </span>
        {get(record, "foot") && (
          <span className={classes.cardContentRow}>
            {translate("resources.players.fields.foot")}
            :&nbsp;
            <FootField source="foot" label="resources.players.fields.foot" />
          </span>
        )}
        {get(record, "positionids") && (
          <span className={classes.cardContentRow}>
            {translate("resources.players.fields.positions")}
            :&nbsp;
            <ReferenceArrayField
              label="resources.players.fields.positions"
              source="position_ids"
              reference="positions"
              sortable={false}
            >
              <SingleFieldList>
                <PositionChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </span>
        )}
        {get(record, "repteamids") && (
          <span className={classes.cardContentRow}>
            {translate("resources.players.fields.repTeams")}
            :&nbsp;
            <ReferenceArrayField
              label="resources.players.fields.repTeams"
              source="repteam_ids"
              reference="repteams"
            >
              <SingleFieldList>
                <ChipField
                  clickable={false}
                  color="secondary"
                  variant="outlined"
                  source="slug"
                />
              </SingleFieldList>
            </ReferenceArrayField>
          </span>
        )}
      </CardContent>
    </Card>
  ));
};

export default PlayerMobileGrid;
