import { Edit, TextInput, SimpleForm, required } from "react-admin";
import React from "react";
import { useAuthenticated } from "@s-graber/ra-rbac";

export const RepteamEdit: React.FC = (props) => {
  useAuthenticated();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="slug" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
