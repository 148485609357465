import { SaveButton, Toolbar, useGetOne } from "react-admin";
import { useCallback, useRef } from "react";

export const PlayerTabInfoToolbar = (props) => {
  const playerRef = useRef();
  const {
    record: { player_id },
  } = props;
  const { isLoading, data: player } = useGetOne("players", { id: player_id });
  if (!isLoading) {
    playerRef.current = player.name;
  }
  const transform = useCallback((data) => {
    return {
      ...data,
      name: `${playerRef.current}`,
    };
  }, []);
  return (
    <Toolbar {...props}>
      <SaveButton label="ra.action.save" transform={transform} />
    </Toolbar>
  );
};
