import { get } from "lodash";
import { RaBox, BoxedShowLayout } from "ra-compact-ui";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React from "react";
import {
  DateField,
  FormTab,
  FunctionField,
  Link,
  TextField,
} from "react-admin";

export const DetailsFormTab: React.FC = (props) => {
  return (
    <FormTab
      {...props}
      sx={{
        "& .RaTextField-root": {
          width: "300px",
        },
        "& .RaFunctionField-root": {
          width: "300px",
          color: "primary",
        },
      }}
      label="resources.matches.tabs.details"
      path="details"
    >
      <BoxedShowLayout>
        <RaBox display="flex">
          <RaBox flex="33%">
            <TextField label="resources.matches.fields.id" source="id" />
            <DateField
              label="resources.matches.fields.matchDate"
              source="date"
              options={{
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              }}
            />
            <TextField
              label="resources.matches.fields.matchTime"
              source="matchTime"
            />
            <TextField
              label="resources.matches.fields.homeTeam"
              source="homeTeam"
            />
            <TextField
              label="resources.matches.fields.guestTeam"
              source="guestTeam"
            />
            <TextField
              label="resources.matches.fields.referee"
              source="referee"
            />
            <TextField
              label="resources.matches.fields.assistantReferee1"
              source="assistantReferee1"
            />
            <TextField
              label="resources.matches.fields.assistantReferee2"
              source="assistantReferee2"
            />
          </RaBox>
          <RaBox flex="33%">
            <FunctionField
              label="resources.matches.fields.venue"
              source="venue"
              render={(record) => (
                <Link
                  target="_blank"
                  to={{
                    search: `api=1&query=${encodeURIComponent(
                      get(record, "venue") +
                        "," +
                        get(record, "street") +
                        "," +
                        get(record, "postal") +
                        "," +
                        get(record, "city")
                    )}`,
                    pathname: `https://www.google.com/maps/search/`,
                  }}
                >
                  {get(record, "venue")}
                  <ExitToAppIcon
                    style={{ verticalAlign: "bottom" }}
                    fontSize="small"
                  />
                </Link>
              )}
            />
            <TextField
              label="resources.matches.fields.street"
              source="street"
            />
            <TextField
              label="resources.matches.fields.postal"
              source="postal"
            />
            <TextField label="resources.matches.fields.city" source="city" />
            <TextField
              label="resources.matches.fields.placeType"
              source="placeType"
            />
            <TextField label="resources.matches.fields.area" source="area" />
            <TextField label="resources.matches.fields.type" source="type" />
            <DateField
              label="resources.matches.fields.lastupdate"
              source="lastupdate"
              options={{
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }}
            />
          </RaBox>
          <RaBox flex="33%">
            <TextField
              label="resources.matches.fields.association"
              source="association"
            />
            <TextField
              label="resources.matches.fields.season"
              source="season"
            />
            <TextField
              label="resources.matches.fields.teamType"
              source="teamType"
            />
            <TextField
              label="resources.matches.fields.league"
              source="league"
            />
            <TextField
              label="resources.matches.fields.series"
              source="series"
            />
            <TextField
              label="resources.matches.fields.gameday"
              source="gameday"
            />
            <TextField
              label="resources.matches.fields.updatedby"
              source="updatedby"
            />
            <TextField
              label="resources.matches.fields.source"
              source="source"
            />
          </RaBox>
        </RaBox>
      </BoxedShowLayout>
    </FormTab>
  );
};
