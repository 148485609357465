
import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router";
import {
  useTranslate,
  useNotify,
  useDataProvider,
  useUpdate,
  useRefresh,
  RaRecord,
} from "react-admin";
import { useDelete } from "react-supabase";

export const DeleteTrainingsButton: React.FC<{
  type: "upcoming" | "past" | "all";
  record: RaRecord;
}> = (props) => {
  const { type, record } = props;
  const [, execute] = useDelete("trainings_players");
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(
    record && !record.linkedTrainings
  );
  const location = useLocation();
  const [, , practice_id] = location.pathname.split("/");
  const dataProvider = useDataProvider();
  const [update] = useUpdate();
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    let filter = {};
    if (type === "past") {
      filter = {
        practice_id,
        "date@lte": new Date().toISOString(),
      };
    } else if (type === "upcoming") {
      filter = {
        practice_id,
        "date@gte": new Date().toISOString(),
      };
    } else if (type === "all") {
      filter = {
        practice_id,
      };
    }
    const { data: trainings, total } = await dataProvider.getList("trainings", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter,
    });
    console.log(`found ${total} trainings`);
    const ids = trainings.flatMap((x) => x.id);
    console.log(`deleting trainings_players`, ids);
    execute((query) => query.in("training_id", ids));
    const { data: deletedTrainings } = await dataProvider.deleteMany(
      "trainings",
      { ids: ids }
    );
    console.log(`deletedTrainings`, deletedTrainings);
    update(
      "practices",
      {
        id: record.id,
        data: {
          linkedTrainings: false,
        },
      },
      { mutationMode: "optimistic" }
    );
    refresh();
    notify("resources.practices.notification.trainings_deleted", {
      type: "info",
      messageArgs: { count: total },
    });
  }, [dataProvider, record, update, notify, execute, type, practice_id]);

  return (
    <Button onClick={handleSubmit} disabled={submitting}>
      <DeleteIcon />
      {translate("resources.practices.actions.trainings_deleted")}
    </Button>
  );
};
