import React from 'react';
import {
    Filter,
    Datagrid,
    EditButton,
    List,
    TextField,
    ReferenceInput,
    SelectInput,
    TextInput,
    ReferenceField,
} from 'react-admin';
import SquadField from '../../components/fields/SquadField';

const LineupFilter: React.FC = props => (
    <Filter {...props}>
        <ReferenceInput
            label="resources.squadplayers.fields.squad"
            source="team_id"
            reference="teams"
            filter={{ currentSeason: true }}
            alwaysOn
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput label="resources.lineups.search_name" source="name" />
    </Filter>
);

export const LineupList: React.FC = props => (
    <List
        {...props}
        filters={<LineupFilter />}
        sort={{ field: 'name', order: 'ASC' }}
    >
        <Datagrid>
            <TextField source="name" label="resources.lineups.fields.name" />
            <ReferenceField
                sortable={false}
                label="resources.lineups.fields.squad"
                source="team_id"
                reference="teams"
            >
                <SquadField label="resources.lineups.fields.name" />
            </ReferenceField>
            <ReferenceField
                sortable={false}
                label="resources.lineups.fields.scout_id"
                source="scout_id"
                reference="teams"
            >
                <SquadField label="resources.lineups.fields.name" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);
