import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
    useTranslate,
    SimpleForm,
    SelectInput,
    FunctionField,
    useEditController,
    BooleanInput,
    DeleteButton,
    SaveButton,
    EditContextProvider,
    useGetManyReference,
    Link,
    useUpdate,
    useNotify,
    useRedirect,
    useRefresh,
    RaRecord,
    usePermissions,
    useGetList,
    useRecordContext,
    useGetOne,
    useDataProvider,
} from 'react-admin';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles, styled } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { Card, CardHeader, Box, FormLabel } from '@mui/material';
import { RatingIcon } from '../../components/fields/RatingField';
import { choicesPlayerStatus, choicesClubStatus } from '../../types';
import MuiToolbar from '@mui/material/Toolbar';
import { useLocation, useParams } from 'react-router-dom';
import { PlayerEditDialog } from './PlayerEditDialog';
import VCardButtonLocal from '../../components/VCardButtonLocal';
import { IfCanAccess } from '@s-graber/ra-rbac';
import { NotesList, Timeline } from '../players/PlayerEditAside';

const useStyles = {
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
};

interface IPlayerDetail {
    onCancel: any;
    id?: any;
    mutationMode?: string;
}

export const ScoutingList: React.FC<{
    record: RaRecord;
}> = ({ record }) => {
    const translate = useTranslate();
    const {
        data: calls,
        isLoading: callLoaded,
        total: callTotal,
    } = useGetManyReference('calls', {
        target: 'player_id',
        id: record.player_id,
        pagination: { page: 1, perPage: 2 },
        sort: { field: 'date', order: 'DESC' },
        filter: {},
    });

    const {
        data: scoutings,
        isLoading: scoutingLoaded,
        total: scoutingTotal,
    } = useGetManyReference('scoutings', {
        target: 'player_id',
        id: record.player_id,
        pagination: { page: 1, perPage: 2 },
        sort: { field: 'date', order: 'DESC' },
        filter: {},
    });

    let call = !callLoaded && (
        <>
            {calls.length > 0 ? (
                <Box
                    sx={{
                        margin: '0',
                        transform: 'translate(0, 1.5px) scale(0.5)',
                        transformOrigin: 'top left',
                    }}
                >
                    <FormLabel>
                        {translate('resources.calls.name')} ({callTotal})
                    </FormLabel>
                </Box>
            ) : null}
            {calls.map(record => (
                <CallReport record={record} key={`call_${record.id}`} />
            ))}
        </>
    );
    let scouting = !scoutingLoaded && (
        <>
            {scoutings.length > 0 && (
                <Box
                    sx={{
                        margin: '0',
                        transform: 'translate(0, 1.5px) scale(0.75)',
                        transformOrigin: 'top left',
                    }}
                >
                    <FormLabel>
                        {translate('resources.scoutings.name')} ({scoutingTotal}
                        )
                    </FormLabel>
                </Box>
            )}
            {scoutings.map(record => (
                <ScoutingReport record={record} key={`scouting_${record.id}`} />
            ))}
        </>
    );

    return (
        <>
            {scouting}
            {call}
        </>
    );
};

const ScoutingReport = ({ record }) => {
    const translate = useTranslate();
    const getType = (type, location, translated) => {
        if (type === '') return '';
        else if (type === 'regularmatch' || type === 'testmatch')
            return `${translated}`;
        else if (type !== '')
            return location && location !== 'undefined'
                ? `${translated} - ${location}`
                : `${translated}`;
    };
    const translatedType = translate(`resources.scoutings.type.${record.type}`);

    return (
        <Card sx={{ margin: '0 2em 1em 0' }}>
            <CardHeader
                sx={{
                    alignItems: 'flex-start',
                }}
                avatar={
                    record.scoutingReview && (
                        <RatingIcon rating={record.scoutingReview} />
                    )
                }
                title={
                    <Link to={`/players/${record.player_id}/2`}>
                        {getType(record.type, record?.location, translatedType)}
                    </Link>
                }
                subheader={
                    <>
                        <Typography variant="body2">
                            {record.date &&
                                new Date(record.date).toLocaleDateString(
                                    'de-DE',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }
                                )}
                        </Typography>
                        <Typography
                            variant="inherit"
                            sx={{
                                display: '-webkit-box',
                                '-webkit-line-clamp': 3,
                                '-webkit-box-orient': 'vertical',
                                overflow: 'hidden',
                            }}
                        >
                            {record.report &&
                                record.report
                                    ?.split('\n')
                                    .map(function (item, idx) {
                                        return (
                                            <span key={idx}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })}
                        </Typography>
                    </>
                }
            />
        </Card>
    );
};

const CallReport = ({ record }) => {
    const translate = useTranslate();
    const translatedType = translate(`resources.calls.type.${record.type}`);
    return (
        <Card sx={{ margin: '0 2em 1em 0' }}>
            <CardHeader
                sx={{
                    alignItems: 'flex-start',
                }}
                title={null}
                subheader={
                    <>
                        <Typography variant="body2">
                            <Link to={`/players/${record.player_id}/3`}>
                                {translatedType}
                            </Link>
                            <span> - </span>
                            {record.date &&
                                new Date(record.date).toLocaleDateString(
                                    'de-DE',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }
                                )}
                        </Typography>
                        <Typography
                            variant="inherit"
                            sx={{
                                display: '-webkit-box',
                                '-webkit-line-clamp': 3,
                                '-webkit-box-orient': 'vertical',
                                overflow: 'hidden',
                            }}
                        >
                            {record.comments &&
                                record.comments
                                    ?.split('\n')
                                    .map((item, idx) => {
                                        return (
                                            <span key={idx}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })}
                        </Typography>
                    </>
                }
            />
        </Card>
    );
};

const ScoutingListAside = ({ record }) => {
    return (
        <Box
            sx={{
                width: 400,
                display: {
                    md: 'none',
                },
            }}
        >
            {record && <ScoutingList record={record} />}
        </Box>
    );
};

const SquadplayerAsideToolbar = ({ record }) => {
    const { getValues } = useFormContext();
    const { isLoading } = usePermissions();
    const [update] = useUpdate();
    const redirectTo = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleSave = e => {
        e.preventDefault(); // necessary to prevent default SaveButton submit logic
        const { id, ...values } = getValues();
        update(
            'teams_players',
            {
                id,
                data: {
                    clubstatus: values.clubstatus,
                    playerstatus: values.playerstatus,
                    contacted: values.contacted,
                    contractOffered: values.contractOffered,
                    hasContract: values.hasContract,
                    appEnabled: values.appEnabled,
                    clubInformed: values.clubInformed,
                    agentInformed: values.agentInformed,
                    trialexercise: values.trialexercise,
                },
                previousData: record,
            },
            {
                onSuccess: () => {
                    redirectTo(
                        `/squadplayers?order=DESC&sort=name&filter=${encodeURI(
                            JSON.stringify({
                                team_id: values.team_id,
                                scoutingstatus: values.scoutingstatus,
                            })
                        )}`
                    );
                    notify('resources.squadplayers.notification.updated', {
                        type: 'info',
                        messageArgs: { smart_count: 1 },
                    });
                    refresh();
                },
            }
        );
    };

    if (isLoading) return null;
    return (
        <MuiToolbar>
            <Fragment>
                <SaveButton type="button" onClick={handleSave} />
                <IfCanAccess action="delete" resource="squadplayers">
                    <DeleteButton />
                </IfCanAccess>
            </Fragment>
        </MuiToolbar>
    );
};

export const PlayerNotesList = ({ player_id }) => {
    const dataProvider = useDataProvider();
    const [notes, setNotes] = useState<RaRecord[]>();

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await dataProvider.getList('all_notes',
            {
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'date', order: 'DESC' },
                filter: { player_id: player_id },
            })
            setNotes(data);
        };
        if (player_id) {
            fetchData();
        }
    }, [dataProvider, player_id]);

    if(!player_id) return null;
    return (
        <Box ml={2} width={400}>
            {notes && <Timeline notes={notes} />}
        </Box>
    );
};

export const SquadplayerAside: React.FC<IPlayerDetail> = ({
    mutationMode = 'pessimistic',
    onCancel,
    ...props
}) => {
    const resource = 'teams_players';
    const dataProvider = useDataProvider();
    const controllerProps = useEditController({
        resource,
        ...props,
    });
    const { record, isLoading } = controllerProps;
    const translate = useTranslate();
    const [player, setPlayer] = useState<RaRecord>();
    const { player_id } = record || {};

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await dataProvider.getOne('players', {
                id: player_id
            })
            setPlayer(data);
        };
        if (player_id) {
            fetchData();
        }
    }, [dataProvider, player_id]);

    if (isLoading || !player) {
        return null;
    }
    return (
        <Box sx={{ paddingTop: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'space-between',
                    margin: '1em',
                }}
            >
                <Link
                    to={{
                        pathname: `/players/${player_id}`,
                    }}
                >
                    <Typography variant="h6" style={{ marginTop: '0.5em' }}>
                        {`${player.name}`}
                    </Typography>
                </Link>
                {/* <PlayerEditDialog
          basepath={`${pathname}${search}`}
          id={`${get(controllerProps.record, "player_id")}`}
        /> */}
                {/* <VCardButtonLocal
          playerData={get(controllerProps.record, "players")}
          type="player"
        /> */}
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <EditContextProvider value={controllerProps}>
                <SimpleForm
                    sx={{
                        form: {
                            width: {
                                xs: 100,
                                sm: 400,
                            },
                            marginTop: {
                                xs: -30,
                            },
                        },
                    }}
                    record={record}
                    resource="squadplayers"
                    toolbar={<SquadplayerAsideToolbar record={record} />}
                >
                    <Fragment>
                        <Box
                            sx={{
                                margin: '0',
                                transform: 'translate(0, 1.5px) scale(0.75)',
                                transformOrigin: 'top left',
                            }}
                        >
                            <FormLabel>Status</FormLabel>
                        </Box>
                    </Fragment>
                    <BooleanInput
                        label="resources.squadplayers.fields.clubInformed"
                        source="clubInformed"
                        helperText=""
                    />
                    <BooleanInput
                        label="resources.squadplayers.fields.agentInformed"
                        source="agentInformed"
                        helperText=""
                    />
                    <BooleanInput
                        label="resources.squadplayers.fields.contacted"
                        source="contacted"
                        helperText=""
                    />
                    <BooleanInput
                        label="resources.squadplayers.fields.trialexercise"
                        source="trialexercise"
                        helperText=""
                    />
                    <BooleanInput
                        label="resources.squadplayers.fields.contractOffered"
                        source="contractOffered"
                        helperText=""
                    />
                    <BooleanInput
                        label="resources.squadplayers.fields.hasContract"
                        source="hasContract"
                        helperText=""
                    />
                    <BooleanInput
                        label="resources.squadplayers.fields.appEnabled"
                        source="appEnabled"
                        helperText=""
                    />
                    <Box
                        display={{ xs: 'block', sm: 'flex' }}
                        alignContent="flex-start"
                    >
                        <Box mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="resources.squadplayers.fields.clubstatus"
                                source="clubstatus"
                                choices={choicesClubStatus}
                                helperText=""
                            />
                        </Box>
                        <Box mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="resources.squadplayers.fields.playerstatus"
                                source="playerstatus"
                                choices={choicesPlayerStatus}
                                helperText=""
                            />
                        </Box>
                    </Box>
                </SimpleForm>
                <PlayerNotesList player_id={player_id} />
            </EditContextProvider>
        </Box>
    );
};
