/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslate } from 'ra-core';
import { TextInput, TextInputProps, useAuthProvider, useNotify } from 'react-admin';
import { isValidIBAN, electronicFormatIBAN } from 'ibantools';
import { useLocation } from 'react-router-dom';
import { fetchFromSupabseFunction } from '../../types';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormContext } from "react-hook-form";
import { useMsal } from '@azure/msal-react';

export interface IbanInputProps extends TextInputProps {
  initiallyVisible?: boolean;
}

const IbanInput = (props: IbanInputProps) => {
  const location = useLocation();
  const notify = useNotify();
  const [, , playerId] = location.pathname.split('/');
  const { initiallyVisible = false, ...rest } = props;
  const [visible, setVisible] = useState(initiallyVisible);
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  const [jwtToken, setJwtToken] = useState<string>();
  const { setValue } = useFormContext();
  const { instance: msalInstance } = useMsal();
  const [player] = useState(playerId);
  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await authProvider.getAccessToken(msalInstance)
      setJwtToken(accessToken);
    };
    fetchData();
  }, [authProvider]);

  const handleClick = () => {
    fetchFromSupabseFunction('players-iban', { player_id: player }, jwtToken)
      .then(response => response.json())
      .then(data => {
        setValue('iban_decr', data.iban);
        setVisible(!visible);
      })
      .catch(e => {
        notify(e.message, { type: 'error' });
      })
      .finally(() => {});
  };

  const validateIBAN = input => {
    if (!input) return undefined;
    const iban = electronicFormatIBAN(input);
    if (!isValidIBAN(iban)) {
      return 'IBAN ungültig!';
    }
    return undefined;
  };

  return (
    <TextInput
      validate={validateIBAN}
      fullWidth
      {...rest}
      type={visible ? 'text' : 'text'}
      sx={{
          '& .MuiFilledInput-input': {
                  background: '#ffe5e3',
              },
      }}
      InputProps={{
        autoComplete: 'off',
        placeholder: 'IBAN anzeigen',
        endAdornment: (
          <InputAdornment position="end"
          sx={{
                      background: '#ffe5e3',
          }}>
            <IconButton 
              aria-label={translate(visible ? 'ra.input.password.toggle_visible' : 'ra.input.password.toggle_hidden')}
              onClick={handleClick}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default IbanInput;
