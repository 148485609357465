import { useAuthenticated } from "@s-graber/ra-rbac";
import React from "react";
import {
  Datagrid,
  List,
  BooleanField,
  TextField,
  EditButton,
  DateField,
} from "react-admin";

export const SeasonList: React.FC = (props) => {
  useAuthenticated();
  return (
    <List {...props} sort={{ field: "name", order: "DESC" }}>
      <Datagrid>
        <TextField source="name" />
        <BooleanField source="isCurrent" />
        <DateField source="lastupdate" label="pos.fields.lastupdate" />
        <TextField source="updatedby" label="pos.fields.updatedby" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
