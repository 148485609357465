import { Card, CardContent } from '@mui/material';
import { useShowController } from 'react-admin';

export const ReviewShow = (props: any) => {
  const { record } = useShowController(props);
  if (!record || !record.comments) return null;
  return (
    <Card sx={{ width: 'auto', margin: 'auto' }}>
      <CardContent>
        {record.comments.split('\n').map((item, idx) => {
          return (
            <span key={idx}>
              {item}
              <br />
            </span>
          );
        })}
      </CardContent>
    </Card>
  );
};
