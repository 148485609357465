import {
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
  DeleteButton,
  TabbedForm,
  Pagination,
  usePermissions,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { AddPlayerButton } from "../buttons/AddPlayerButton";
import { TypeField } from "../../../components/fields/TypeField";

export const AbsenceFormTab = (props) => {
  const { isLoading } = usePermissions();
  const { pathname } = useLocation();
  if (isLoading) return null;
  return (
    <TabbedForm.Tab label="resources.players.tabs.absences" path="absences" id="absences" {...props}>
      <ReferenceManyField reference="absences" target="player_id" pagination={<Pagination />} sort={{ field: "startDate", order: "DESC" }}
      >
        <Datagrid>
          <DateField
            label="resources.absences.fields.startDate"
            source="startDate"
            options={{ year: "numeric", month: "numeric", day: "numeric" }}
          />
          <DateField
            label="resources.absences.fields.endDate"
            source="endDate"
            options={{ year: "numeric", month: "numeric", day: "numeric" }}
          />
          <TypeField label="resources.absences.fields.type" source="type" resource='absences' />
          <TextField label="resources.absences.fields.reason" source="reason" />
          <TextField
            label="resources.calls.fields.createdby"
            source="createdby"
          />
          <DeleteButton label="" redirect={pathname} />
        </Datagrid>
      </ReferenceManyField>
      <AddPlayerButton resourceName="absence" />
    </TabbedForm.Tab>
  );
};
