import * as React from "react";

import { ReactQueryDevtools } from "react-query/devtools";
import { Layout, Sidebar } from "react-admin";
import { ScpAppBar } from "./ScpAppBar";
import { CssBaseline } from "@mui/material";
import { ScpMenu } from "./ScpMenu";
import { AppLocationContext } from "@s-graber/ra-navigation";

const ScpSidebar = (props: any) => <Sidebar
  sx={{
      "& .RaSidebar-paper": {
          // width: "150px",
      },
  }}
  {...props}
/>

export default (props: any) => (
  <>
    <CssBaseline />
    <AppLocationContext>
      <Layout
        {...props}
        sx={{ "& .RaLayout-content": { flexGrow: 1 } }}
        appBar={ScpAppBar}
        menu={ScpMenu}
        sidebar={ScpSidebar}
      />
    </AppLocationContext>
    <ReactQueryDevtools
      initialIsOpen={false}
      toggleButtonProps={{ style: { width: 20, height: 30 } }}
    />
  </>
);
