import React from 'react';
import {
    FormTab,
    ReferenceManyField,
    Datagrid,
    ReferenceField,
    TextField,
    EditButton,
    BooleanField,
    NumberField,
    useGetIdentity,
} from 'react-admin';

export const TransferFormTab: React.FC = props => {
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading) return null;
    return (
        <FormTab
            {...props}
            label="resources.players.tabs.transfers"
            path="transfers"
        >
            <ReferenceManyField
                sort={{ field: 'lastupdate', order: 'DESC' }}
                reference="transfers"
                source="id"
                target="player_id"
            >
                <Datagrid rowClick="show">
                    <ReferenceField
                        label="resources.transfers.fields.season_id"
                        source="season_id"
                        reference="seasons"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        label="resources.transfers.fields.club_id"
                        source="club_id"
                        reference="clubs"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <BooleanField
                        label="resources.transfers.fields.entry"
                        source="entry"
                    />
                    {/* <BooleanField
                        label="resources.transfers.fields.parentsInformed"
                        source="parentsInformed"
                    />
                    <BooleanField
                        label="resources.transfers.fields.dfbnetApplicationSent"
                        source="dfbnetApplicationSent"
                    />
                    <BooleanField
                        label="resources.transfers.fields.clubRegistration"
                        source="clubRegistration"
                    />
                    <BooleanField
                        label="resources.transfers.fields.playerPassportApplication"
                        source="playerPassportApplication"
                    />
                    <BooleanField
                        label="resources.transfers.fields.playerPassportPhoto"
                        source="playerPassportPhoto"
                    />
                    <BooleanField
                        label="resources.transfers.fields.confirmationOfCancellation"
                        source="confirmationOfCancellation"
                    /> */}
                    <BooleanField
                        label="resources.transfers.fields.consentGranted"
                        source="consentGranted"
                    />
                    <BooleanField
                        label="resources.transfers.fields.transferFinished"
                        source="transferFinished"
                    />
                    {allowedAccess && (
                        <NumberField
                            label="resources.transfers.fields.transferFee"
                            locales="de-DE"
                            options={{ style: 'currency', currency: 'EUR' }}
                            source="transferFee"
                        />
                    )}
                    <EditButton label="" />
                </Datagrid>
            </ReferenceManyField>
        </FormTab>
    );
};
