import { IfCanAccess, ListActions } from '@s-graber/ra-rbac';
import React from 'react';
import {
    Datagrid,
    List,
    Filter,
    TextInput,
    TextField,
    EditButton,
    BulkDeleteButton,
} from 'react-admin';

const BulkActionButtons = (props) => {
    return (
      <IfCanAccess action="delete">
        <BulkDeleteButton {...props} />
      </IfCanAccess>
    );
  };
  
export const ConsultancyList: React.FC = () => {
    return (
        <List
          actions={<ListActions />}
          bulkActionButtons={<BulkActionButtons />}
          filters={<Filter>
            <TextInput
                label="resources.consultancies.search_name"
                source="name@ilike"
                alwaysOn
            />
        </Filter>}
          sort={{ field: "name", order: "ASC" }}
        >
          <Datagrid>
            <TextField source="name" />
            <EditButton />
          </Datagrid>
        </List>
    );
};
