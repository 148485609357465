import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  SelectInput,
} from "react-admin";
import { MarkdownInput } from "@s-graber/ra-markdown";
import { choicesTextCategories, choicesTextAccess } from "../../types";
import { useAuthenticated } from "@s-graber/ra-rbac";

export const TextCreate: React.FC = (props) => {
  useAuthenticated();
  return (
    <Create redirect="list" {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <SelectInput
          label="resources.texts.fields.category"
          source="category"
          choices={choicesTextCategories}
          validate={required()}
        />
        <SelectInput
          label="resources.texts.fields.access"
          source="access"
          choices={choicesTextAccess}
          validate={required()}
        />
        <MarkdownInput source="content" />
      </SimpleForm>
    </Create>
  );
};
