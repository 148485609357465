import React, { useCallback, useState } from "react";
import get from "lodash/get";
import { calculateTrainingPlayerStatusValues } from "../../../types";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "ra-core";
import { Button } from "ra-ui-materialui";
import { FieldProps } from "react-admin";

export const TrainingPlayerStatusIcon: React.FC<{
  value: string;
  source: string;
}> = ({ value, source }) => {
  const valueColors = {
    green: {
      0: {
        color: "grey",
      },
      1: {
        color: "#006534",
      },
    },
    red: {
      0: {
        color: "grey",
      },
      1: {
        color: "#f44336",
      },
    },
  };
  if (source === "present") {
    return <ThumbUpIcon sx={valueColors.green[value]} />;
  } else if (source === "ill") {
    return <LocalHospitalIcon sx={valueColors.red[value]} />;
  } else {
    return null;
  }
};

export const TrainingPlayerStatusField: React.FC<FieldProps> = ({ source }) => {
  const notify = useNotify();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const record = useRecordContext();
  const [submitting, setSubmitting] = useState(false);
  const dataProvider = useDataProvider();
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const newValues = calculateTrainingPlayerStatusValues(
      record,
      source,
      record,
      notify
    );
    update(
      "trainings_players",
      { id: record.id, data: newValues, previousData: record },
      {
        mutationMode: "optimistic",
      }
    );
    setSubmitting(false);
    notify("resources.trainings.notification.status_update", {
      type: "info",
    });
    refresh();
  }, [dataProvider, record, source]);
  return (
    <Button onClick={handleSubmit}>
      <TrainingPlayerStatusIcon value={get(record, source)} source={source} />
    </Button>
  );
};
