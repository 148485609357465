import {
  DateField,
  Datagrid,
  List,
  EditButton,
  usePermissions,
  Filter,
  SelectInput,
  TextInput,
  TextField,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import { TypeField } from '../../components/fields/TypeField';
import { FullNameField } from '../../components/fields/FullNameField';
import { choicesCallType } from '../../types';
import { CallShow } from './CallShow';

const CallFilter: React.FC = props => (
  <Filter {...props}>
    <ReferenceInput
      reference="players"
      source="player_id"
      label="resources.calls.fields.player"
      filterToQuery={searchText => ({ name: searchText })}
      alwaysOn
    >
      <AutocompleteInput
        fullWidth
        noOptionsText="-"
        shouldRenderSuggestions={(val: any) => {
          return val.trim().length > 2;
        }}
        optionText="name"
      />
    </ReferenceInput>
    <TextInput label="resources.calls.fields.comments" source="q" />
    <SelectInput label="resources.calls.fields.type" source="type" fullWidth choices={choicesCallType} />
  </Filter>
);

export const CallList = props => {
  const { isLoading } = usePermissions();
  if(isLoading) return null;
  return (
    <List {...props} filters={<CallFilter />} sort={{ field: 'date', order: 'DESC' }}>
      <Datagrid rowClick="expand" expand={<CallShow />}>
        <ReferenceField label="resources.calls.fields.player" source="player_id" reference="players">
          <FullNameField source="name" playerid="id" sourceRating="rating" sourceAvatar="photo.src" />
        </ReferenceField>
        <DateField label="resources.calls.fields.date" source="date" sortBy="date" />
        <TypeField label="resources.calls.fields.type" source="type" resource='calls' />
        <TextField label="resources.calls.fields.createdby" source="createdby" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
