import React from 'react';
import { DateField, Datagrid, List, Filter, TextInput, EditButton, FunctionField, ReferenceField } from 'react-admin';
import { FullNameField } from '../../components/fields/FullNameField';

const SprintvalueFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput label="resources.sprintvalues.fields.player" source="q" alwaysOn />
  </Filter>
);

export const SprintvalueList: React.FC = props => {
  return (
    <List {...props} filters={<SprintvalueFilter />} sort={{ field: 'date', order: 'DESC' }}>
      <Datagrid>
        <ReferenceField label="resources.sprintvalues.fields.name" source="player_id" reference="players" link={false}>
          <FullNameField source="name" playerid="id" sourceRating="rating" sourceAvatar="photo.src" />
        </ReferenceField>
        <DateField source="date" />
        <FunctionField
          source="m5"
          render={(record: any) => (record.m5 ? `${record.m5}`.replace('.', ',').replace('undefined', '-') : '-')}
          emptyText="-"
        />
        <FunctionField
          source="m10"
          render={(record: any) => (record.m10 ? `${record.m10}`.replace('.', ',').replace('undefined', '-') : '-')}
          emptyText="-"
        />
        <FunctionField
          source="m20"
          render={(record: any) => (record.m20 ? `${record.m20}`.replace('.', ',').replace('undefined', '-') : '-')}
          emptyText="-"
        />
        <FunctionField
          source="m30"
          render={(record: any) => (record.m30 ? `${record.m30}`.replace('.', ',').replace('undefined', '-') : '-')}
          emptyText="-"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
