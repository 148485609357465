import { get } from "lodash";
import React from "react";
import {
  Filter,
  EditButton,
  ReferenceInput,
  SelectInput,
  TextField,
  BooleanField,
  ReferenceField,
  SearchInput,
  CreateButton,
  useTranslate,
  TopToolbar,
  ExportButton,
  FunctionField,
  RaRecord,
} from "react-admin";
import { ImportConfig, ImportButton } from "react-admin-import-csv";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { Datagrid, List } from "@s-graber/ra-rbac";
import { SquadplayerIcon } from "../squadplayers";
import { Button } from "@mui/material";

const TeamFilter: React.FC = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="name@ilike" alwaysOn />
      <ReferenceInput
        source="ageclass_id"
        reference="ageclasses"
        alwaysOn
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          optionText="name"
          label="resources.teams.fields.ageclass"
        />
      </ReferenceInput>
      <ReferenceInput
        sort={{ field: "name", order: "ASC" }}
        source="season_id"
        reference="seasons"
        alwaysOn
      >
        <SelectInput label="resources.teams.fields.season" optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

const TeamListActions = (props) => {
  const teamImportConfig: ImportConfig = {
    logging: true,
    parseConfig: {
      // For all options see: https://www.papaparse.com/docs#config
      delimiter: ",", // auto-detect
      dynamicTyping: true,
    },
    // validateRow: async (row) => {
    //   if (row && !row.id) {
    //     throw new Error("Spielkennung fehlt!");
    //   }
    // },
    // transformRows: async (csvRows: any[]) => {
    //   const rows = await Promise.all(csvRows.map(getSquadId));
    //   return rows.filter(n => n);
    // },
  };
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton />
      <ExportButton />
      <ImportButton {...props} {...teamImportConfig} />
    </TopToolbar>
  );
};

export const TeamList: React.FC = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<TeamFilter />}
      actions={<TeamListActions />}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid>
        <FunctionField
          label="resources.teams.fields.name"
          render={(record: RaRecord) =>
            record.id ? (
              <Button disableElevation variant="text" startIcon={<SquadplayerIcon />} href={`/squadplayers?filter=${encodeURI(JSON.stringify({team_id: record.id,scoutingstatus: "member",}))}&order=DESC&sort=rating`}>
              {record.name}
              </Button>
            ) : (
              "-"
            )
          }
        />
        <FunctionField
          label="resources.teams.fields.stats"
          render={(record: RaRecord) =>
            record.id ? (
              <Button disableElevation variant="text" startIcon={<FormatListNumberedIcon />} href={`/teams/${record.id}/match_stats`}>
              {`${translate("resources.teams.fields.stats")}`}
              </Button>
            ) : (
              "-"
            )
          }
        />
        <TextField label="resources.teams.fields.short" source="short" />
        <BooleanField
          label="resources.teams.fields.currentSeason"
          source="currentSeason"
        />
        <BooleanField
          label="resources.teams.fields.currentPlanning"
          source="currentPlanning"
        />
        <TextField
          label="resources.teams.fields.dfbnetTeamName"
          source="dfbnetTeamName"
        />
        <ReferenceField
          label="resources.teams.fields.ageclass"
          source="ageclass_id"
          reference="ageclasses"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="resources.matches.fields.season"
          source="season_id"
          reference="seasons"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
