import React from 'react';
import { Create, TextInput, SimpleForm, required } from 'react-admin';

export const ConsultancyCreate: React.FC = props => (
    <Create redirect="list" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
        </SimpleForm>
    </Create>
);
