import * as React from "react";
import { AppBar } from "react-admin";
import {
  Box,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import Logo from "./Logo";
import { ScpUserMenu } from "./ScpUserMenu";

export const ScpAppBar = (props) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("sm")
  );
  return (
    <AppBar
      userMenu={<ScpUserMenu />}
      elevation={1}
      sx={{
        "& .RaAppBar-title": {
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      }}
      {...props}
    >
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        id="react-admin-title"
      />
      {isLargeEnough && <Logo />}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};
