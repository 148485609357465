/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import IconButton from "@mui/material/IconButton";
import { usePermissions } from "react-admin";
import downloadVCard from "./downloadVCard";
import createVCard from "./createVCardContent";

type VCardButtonProps = {
  playerData: any;
  type: "father" | "mother" | "player";
};

const VCardButtonLocal: React.FC<VCardButtonProps> = ({ playerData, type }) => {
  const { isLoading } = usePermissions();
  const downloadVCardClick = () => {
    console.log("downloadVCardClick", playerData);
    const res = createVCard(playerData, type);
    downloadVCard(res, "contact");
  };
  if (isLoading) {
    return null;
  }
  return (
    <IconButton onClick={downloadVCardClick}>
      <ContactPhoneIcon />
    </IconButton>
  );
};

export default VCardButtonLocal;
