import { FC } from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import SquadIcon from '@mui/icons-material/PeopleOutline';
import { FieldProps, useRecordContext } from 'react-admin';
import { Button } from '@mui/material';

const SquadField: FC<FieldProps> = () => {
    const record = useRecordContext();
    return record ? (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/squadplayers',
                search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'lineupstatus',
                    order: 'DESC',
                    filter: JSON.stringify({
                        team_id: record.id,
                        scoutingstatus: 'member',
                    }),
                }),
            }}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
            }}
        >
            <SquadIcon sx={{ paddingRight: '0.5em' }} />
            {record.name}
        </Button>
    ) : null;
};

export default SquadField;
