import React, { Fragment } from 'react';
import {
    EditButton,
    TextField,
    useCreatePath,
    useGetIdentity,
    useGetRecordId,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import { MarkdownField } from '@s-graber/ra-markdown';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Breadcrumb, BreadcrumbItem } from '@s-graber/ra-navigation';
import { get } from 'lodash';
import { IfCanAccess, Show, SimpleShowLayout } from '@s-graber/ra-rbac';

const TextTitle = ({ record }) => {
    return <span>Konzept {record ? `"${record.name}"` : ''}</span>;
};

const TextBreadcrumb: React.FC = () => {
    const createPath = useCreatePath();
    const id = useGetRecordId();
    const record = useRecordContext();
    const path = createPath({
        type: 'show',
        resource: 'texts',
        id: id,
    });
    return (
        <Breadcrumb hasDashboard={false}>
            <BreadcrumbItem
                name="texts"
                to={`/texts`}
                label="resources.texts.name"
            >
                <BreadcrumbItem
                    name="show"
                    path={path}
                    label={`${get(record, 'name')}`}
                />
            </BreadcrumbItem>
        </Breadcrumb>
    );
};

export const TextContent: React.FC = () => {
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const record = useRecordContext();
    const access = get(record, 'access');
    const name = get(record, 'name');
    const allowedAccess =
        roles.includes(access) || roles.includes(`scpyoungstars_${access}`);
    console.log('allowedAccess', { allowedAccess, access, roles });
    if (isLoading) {
        return null;
    }
    return access === 'scpyoungstars_base' ||
        access === 'all' ||
        allowedAccess ? (
        <Fragment>
            <Card
                sx={{
                    margin: 1,
                }}
            >
                <CardContent>
                    <Typography variant="h5" component="p">
                        {name}
                    </Typography>
                    <MarkdownField source="content" label="" />
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <IfCanAccess action="edit" resource="texts">
                        <EditButton />
                    </IfCanAccess>
                </CardActions>
            </Card>
        </Fragment>
    ) : null;
};

export const TextShow = props => {
    return (
        <Show
            title={<TextTitle {...props} />}
            actions={
                <Box ml={{ sm: '0.5em' }} mb={{ sm: '0.5em' }}>
                    <TextBreadcrumb {...props} />
                </Box>
            }
        >
            <SimpleShowLayout>
                <TextContent />
            </SimpleShowLayout>
        </Show>
    );
};
