import {
    TextField,
    Datagrid,
    List,
    EditButton,
    usePermissions,
    Filter,
    AutocompleteInput,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    BooleanField,
    NumberField,
    DeleteButton,
    TopToolbar,
    ExportButton,
    CreateButton,
    ReferenceArrayInput,
    SelectArrayInput,
    useGetIdentity,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { FullNameField } from '../../components/fields/FullNameField';
import { choiceTeams, choicesIsEntry } from '../../types';

const TransferFilter: React.FC = props => (
    <Filter {...props}>
        <ReferenceArrayInput
            label="resources.transfers.fields.season_id"
            source="season_id"
            reference="seasons"
            alwaysOn
            sort={{ field: 'name', order: 'DESC' }}
        >
            <SelectArrayInput optionText="name" translateChoice={false} />
        </ReferenceArrayInput>
        <ReferenceInput reference="players" source="player_id" alwaysOn>
            <AutocompleteInput
                optionText="name"
                label="resources.transfers.fields.player_id"
                noOptionsText="-"
                shouldRenderSuggestions={(val: any) => {
                    return val.trim().length > 2;
                }}
                filterToQuery={(searchText: any) => ({
                    'name@ilike': `%${searchText}%`,
                })}
            />
        </ReferenceInput>
        <SelectInput
            label="resources.transfers.fields.team"
            source="team"
            choices={choiceTeams}
            alwaysOn
        />
        <ReferenceInput reference="clubs" source="club_id" alwaysOn>
            <AutocompleteInput
                optionText="name"
                label="resources.transfers.fields.club_id"
                filterToQuery={(searchText: any) => ({
                    'name@ilike': `%${searchText}%`,
                })}
            />
        </ReferenceInput>
        <SelectInput
            label="resources.transfers.fields.entry"
            source="entry"
            choices={choicesIsEntry}
            alwaysOn
        />
    </Filter>
);

const TransferListActions = props => {
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading) return null;
    const { className } = props;
    return (
        <TopToolbar className={className}>
            {allowedAccess && <CreateButton />}
            {allowedAccess && <ExportButton />}
        </TopToolbar>
    );
};

export const TransferList = props => {
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    const { pathname } = useLocation();
    if (isLoading) return null;
    return (
        <List
            actions={<TransferListActions />}
            filters={<TransferFilter />}
            sort={{ field: 'createdate', order: 'DESC' }}
            {...props}
        >
            <Datagrid rowClick={'show'}>
                <ReferenceField
                    label="resources.transfers.fields.season_id"
                    source="season_id"
                    reference="seasons"
                >
                    <TextField source="name" />
                </ReferenceField>
                <TextField
                    label="resources.transfers.fields.team"
                    source="team"
                />
                <ReferenceField
                    label="resources.transfers.fields.player_id"
                    source="player_id"
                    reference="players"
                >
                    <FullNameField
                        source="name"
                        playerid="id"
                        sourceRating="rating"
                        sourceAvatar="photo.src"
                    />
                </ReferenceField>
                <ReferenceField
                    label="resources.transfers.fields.club_id"
                    source="club_id"
                    reference="clubs"
                >
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField
                    label="resources.transfers.fields.entry"
                    source="entry"
                />
                <BooleanField
                    label="resources.transfers.fields.parentsInformed"
                    source="parentsInformed"
                />
                <BooleanField
                    label="resources.transfers.fields.dfbnetApplicationSent"
                    source="dfbnetApplicationSent"
                />
                <BooleanField
                    label="resources.transfers.fields.clubRegistration"
                    source="clubRegistration"
                />
                <BooleanField
                    label="resources.transfers.fields.consentGranted"
                    source="consentGranted"
                />
                <BooleanField
                    label="resources.transfers.fields.transferFinished"
                    source="transferFinished"
                />
                {allowedAccess && (
                    <NumberField
                        label="resources.transfers.fields.transferFee"
                        locales="de-DE"
                        options={{ style: 'currency', currency: 'EUR' }}
                        source="transferFee"
                    />
                )}
                <EditButton label="" />
                {allowedAccess && <DeleteButton label="" redirect={pathname} />}
            </Datagrid>
        </List>
    );
};
