import { useAuthenticated } from "@s-graber/ra-rbac";
import React from "react";
import { Create, TextInput, SimpleForm, required } from "react-admin";

export const AgeclassCreate: React.FC = (props) => {
  useAuthenticated();
  return (
    <Create redirect="list" {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
