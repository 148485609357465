/* eslint-disable @typescript-eslint/no-use-before-define */
import { get } from "lodash";
import React, { useCallback, useEffect } from "react";
import {
  TextInput,
  TextInputProps,
  sanitizeInputRestProps,
  useTranslate,
  useDataProvider,
} from "react-admin";

import { useFormContext } from "react-hook-form";

export const LinkedNameFromPracticeTypeInput = ({
  source,
  ...rest
}: TextInputProps) => {
  const translate = useTranslate();
  const { getValues } = useFormContext();
  const { team_id, type, ...values } = getValues();
  const dataProvider = useDataProvider();
  const getData = useCallback(
    async () => {
      const { data } =
        (await dataProvider.getOne("teams", { id: team_id })) || {};
      const typeValue =
        type !== undefined
          ? translate(`resources.practices.types.${type}`)
          : "";
      const value = `${get(data, "short")} ${typeValue}`;
      values[source] = value;
    },
    [dataProvider, team_id, type] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(() => {
    if (team_id) {
      getData();
    }
  }, [team_id, type]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!team_id) return null;
  return <TextInput source={source} {...sanitizeInputRestProps(rest)} />;
};

export default LinkedNameFromPracticeTypeInput;
