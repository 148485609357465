import React from "react";
import {
  Create,
  SimpleForm,
  DateInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { LinkedNameFromPracticeTypeInput } from "../../components/inputs/LinkedNameFromPracticeTypeInput";
import {
  choicesDaysOfWeek,
  choicesPracticeTypes,
  validateTime,
  sanitizeRestListProps,
  choiceShareOfSpace,
  choiceVenues,
} from "../../types";

export const PracticeCreate: React.FC = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" {...sanitizeRestListProps(props)}>
      <ReferenceInput
        label="resources.practices.fields.team_id"
        source="team_id"
        reference="teams"
        filter={{ currentSeason: true }}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="resources.practices.fields.type"
        source="type"
        choices={choicesPracticeTypes}
      />
      <LinkedNameFromPracticeTypeInput
        label="resources.practices.fields.name"
        source="name"
        record={{}}
        {...sanitizeRestListProps(props)}
      />
      <TextInput
        label="resources.practices.fields.meetingTime"
        source="meetingTime"
        validate={validateTime}
      />
      <TextInput
        label="resources.practices.fields.startTime"
        source="startTime"
        validate={validateTime}
      />
      <TextInput
        label="resources.practices.fields.endTime"
        source="endTime"
        validate={validateTime}
      />
      <DateInput
        label="resources.practices.fields.startRecur"
        source="startRecur"
      />
      <DateInput
        label="resources.practices.fields.endRecur"
        source="endRecur"
      />
      <SelectArrayInput
        label="resources.practices.fields.daysOfWeek"
        source="daysOfWeek"
        choices={choicesDaysOfWeek}
      />
      <SelectInput
        label="resources.practices.fields.venue"
        source="venue"
        choices={choiceVenues}
      />
      <SelectInput
        label="resources.practices.fields.shareOfSpace"
        source="shareOfSpace"
        choices={choiceShareOfSpace}
      />
    </SimpleForm>
  </Create>
);
