import * as React from 'react';
import { TextField, MenuItem, Box } from '@mui/material';
import { callType, resolveNoteTypeAttributes, scoutingType } from '../../types';
import { useTranslate } from 'react-admin';

export const NoteType = ({ type }: { type: string }) => {
    const { bgColor } = resolveNoteTypeAttributes(type);
    return (
    <Box
        marginLeft={0.5}
        width={10}
        height={10}
        display="inline-block"
        borderRadius="8px"
        bgcolor={bgColor}
        component="span"
    />
)};

export const TyepSelectorField = ({ setType, setShowRating, sx }: any) => {
    const translate = useTranslate();
    const menuItems = {
        calls: callType,
        reviews: ['review'],
        treatments: ['treatment'],
        scoutings: scoutingType,
    }
    const excludeItems = ['talentday2020','talentday2021','talentday2022','talentday2023']
    return (
    <TextField
        select
        defaultValue="info"
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const type = String(event.target.value);
            const typeReturned = resolveNoteTypeAttributes(type);
            setShowRating(typeReturned['rating']);
            setType(type);
        }}
        variant="filled"
        label={false}
        margin="none"
        size="small"
        sx={sx}
    >
        { Object.keys(menuItems).map( (resource) => {
            return menuItems[resource].map((type) => {
                const defaultLabel = translate(`resources.${resource}.resource`, { smart_count: 1 });
                const label = translate(`resources.${resource}.types.${type}`, { _: defaultLabel, });
                return !excludeItems.includes(type) ? (
                    <MenuItem value={type}>
                    {label} <NoteType type={type} />
                    </MenuItem>) : null;
            })
        }) }
    </TextField>
);
}
