import { useCallback, useRef } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  BooleanInput,
  SaveButton,
  Toolbar,
  useNotify,
  useRedirect,
  useUpdate,
  useCreate,
  useGetOne,
  RaRecord,
} from 'react-admin';
import { convertBirthdateToAge, choicesRatings, sanitizeRestListProps } from '../../types';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { Box } from '@mui/material';
import { useFormContext } from "react-hook-form";

const ReviewCreateSaveButton = () => {
  const [create] = useCreate();
  const [update] = useUpdate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const handleSave = useCallback(
    async (redirect) => {
      const { values } = getValues();
      update('players', { id: values.player_id, data: { rating: values.rating } });
      create('reviews', values, {
        onSuccess: ({ data: newRecord }) => {
          notify('ra.notification.created', { type: 'info', messageArgs: { smart_count: 1 } });
          redirectTo(`/players/${values.player_id}/3`);
        },
      });
    },
    [create, update, notify, redirectTo]
  );
  return  <SaveButton type="button" onClick={handleSave} />;
};

export const ReviewCreate = props => {
  const location = useLocation();
  const player_id = get(location.state, 'record.player_id');
  const initialValues = {
    player_id,
  };
  const { isLoading, data: player } = useGetOne('players',{ id: player_id });
  const playerRef = useRef();
  if (!isLoading && player && playerRef) {
    playerRef.current = player.name;
  }
  const transform = useCallback((data: RaRecord) => {
    return {
      ...data,
      name: `${playerRef.current}`,
    };
  }, []);
  return (
    <Create transform={transform} actions={<PlayerBreadcrumb player_id={player_id} />} redirect={`/players/${player_id}/3`} {...props}>
      <SimpleForm
        toolbar={
          <Toolbar {...sanitizeRestListProps(props)}>
            <ReviewCreateSaveButton />
          </Toolbar>
        }
        defaultValues={initialValues}
      >
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput
              label="resources.reviews.fields.player"
              source="player_id"
              reference="players"
              validate={[required()]}
              filterToQuery={(searchText: any) => ({ q: searchText })}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput
                optionValue="id"
                noOptionsText="-"
                shouldRenderSuggestions={(val: any) => {
                  return val.trim().length > 2;
                }}
                optionText={choice => {
                  const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                  return `${get(choice, 'name')} - ${birthdate}`;
                }}
              />
            </ReferenceInput>
            <SelectInput
              label="resources.reviews.fields.rating"
              source="rating"
              choices={choicesRatings}
              fullWidth
              validate={[required()]}
            />
            <BooleanInput label="resources.reviews.fields.potential" source="potential" />
          </Box>
          <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput label="resources.reviews.fields.comments" multiline fullWidth source="comments" rows={10} />
          </Box>
      </SimpleForm>
    </Create>
  );
};
