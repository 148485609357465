import { Configuration, AccountInfo } from '@azure/msal-browser';
import { Permission } from '@s-graber/ra-rbac';
import { UserIdentity } from 'react-admin';

export const msalConfig: Configuration = {
    auth: {
        // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
        clientId: import.meta.env.VITE_MSAL_CLIENT_ID,
        // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
        authority: import.meta.env.VITE_MSAL_AUTHORITY,
        // Full redirect URL, in form of http://localhost:8080/auth-callback
        redirectUri: `${import.meta.env.VITE_APP_BASE_URI}/auth-callback`,
        // We need to disable this feature because it is already handled by react-admin, and would otherwise conflict
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
    scopes: ['User.Read'],
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

/**
 * Customize this map to match your own roles and permissions
 */
const rolesPermissionMap: Record<string, Permission[]> = {
    scpyoungstars_mgmt: [
        {
            action: '*',
            resource: '*',
        },
    ],
    scpyoungstars_base: [
        { action: '*', resource: 'players' },
        { action: '*', resource: 'practices' },
        { action: '*', resource: 'trainings' },
        { action: '*', resource: 'matches' },
        { action: '*', resource: 'lineups' },
        { action: '*', resource: 'calendar' },
        { action: '*', resource: 'applications' },
        { action: '*', resource: 'squadplayers' },
        { action: '*', resource: 'sprintvalues' },
        { action: '*', resource: 'scoutings' },
        { action: '*', resource: 'calls' },
        { action: '*', resource: 'reviews' },
        { action: '*', resource: 'treatments' },
        { action: '*', resource: 'absences' },
        { action: '*', resource: 'clubs' },
        { action: '*', resource: 'home' },
        { action: '*', resource: 'teams' },
        { action: '*', resource: 'teams.*' },
    ],
};

/**
 * Custom function to map roles to permissions, using the rolesPermissionMap above.
 * Alternatively, you can use the MS Graph API to get more information about the user's roles and groups.
 */
export const getPermissionsFromAccount = async (account: AccountInfo) => {
    const roles = account?.idTokenClaims?.roles ?? [];
    const permissions = roles.flatMap(role => rolesPermissionMap[role]);
    return permissions;
};

export interface ScpEntraIdentity extends UserIdentity {
    roles?: string[];
    homeAccountId?: string;
    environment?: string;
    tenantId?: string;
    username?: string;
    localAccountId?: string;
    name?: string;
    idToken?: string;
    idTokenClaims?: {
        [key: string]:
            | string
            | number
            | string[]
            | object
            | undefined
            | unknown;
    };
    nativeAccountId?: string;
}

export const getIdentityFromAccount = async (
    account: AccountInfo
): Promise<ScpEntraIdentity> => {
    const identity: ScpEntraIdentity = {
        ...account,
        id: account?.localAccountId,
        fullName: account.name,
        roles: account?.idTokenClaims?.roles,
    };
    return identity;
};
