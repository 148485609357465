import {
  DateField,
  Datagrid,
  List,
  EditButton,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
} from 'react-admin';
import { FullNameField } from '../../components/fields/FullNameField';
import { choicesMemberRatings, choicesScoutingRatings } from '../../types';

const ScoutingFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput label="resources.scoutings.fields.player" source="playersName" alwaysOn />
    <SelectInput
      source="scoutingRating"
      label="resources.players.fields.scoutingRating"
      choices={choicesScoutingRatings}
      fullWidth
    />
    <SelectInput
      source="memberRating"
      label="resources.players.fields.memberRating"
      choices={choicesMemberRatings}
      fullWidth
    />
    <ReferenceInput
      label="resources.scoutings.fields.clubHome"
      reference="clubs"
      source="clubHome"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="resources.scoutings.fields.clubAway"
      reference="clubs"
      source="clubAway"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ScoutingList = props => {
  const { isLoading } = usePermissions();
  if (isLoading) return null;
  return (
    <List {...props} filters={<ScoutingFilter />} sort={{ field: 'createdate', order: 'DESC' }}>
      <Datagrid>
        <ReferenceField label="resources.players.fields.name" source="player_id" reference="players">
          <FullNameField source="name" playerid="id" sourceRating="rating" sourceAvatar="photo.src" />
        </ReferenceField>
        <DateField label="resources.scoutings.fields.createdate" source="createdate" sortBy="createdate" />
        <TextField label="resources.scoutings.fields.createdby" source="createdby" />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};
