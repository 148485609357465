/* eslint-disable react/jsx-props-no-spreading */
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  SelectInput,
  useRecordContext,
  Toolbar,
  DeleteButton,
  SaveButton,
  useGetIdentity,
} from "react-admin";
import React from "react";
import { MarkdownInput } from "@s-graber/ra-markdown";
import { choicesTextCategories, choicesTextAccess } from "../../types";
import { get } from "lodash";
import { useAuthenticated } from "@s-graber/ra-rbac";

const TextEditToolbar = () => (
  <Toolbar>
    <SaveButton type="button" />
    <DeleteButton />
  </Toolbar>
);

const TextEditForm: React.FC = (props) => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const record = useRecordContext(props);
  const access = get(record, "access");
  if (isLoading) {
    return null;
  }
  const allowedAccess =
    roles.includes(access) || roles.includes(`scpyoungstars_${access}`);
  console.log("allowedAccess", { allowedAccess, access, roles });
  return access === "scpyoungstars_base" ||
    access === "all" ||
    allowedAccess ? (
    <SimpleForm toolbar={<TextEditToolbar />}>
      <TextInput source="name" validate={required()} />
      <SelectInput
        label="resources.texts.fields.category"
        source="category"
        choices={choicesTextCategories}
        validate={required()}
      />
      <SelectInput
        label="resources.texts.fields.access"
        source="access"
        choices={choicesTextAccess}
        validate={required()}
      />
      <MarkdownInput source="content" />
    </SimpleForm>
  ) : null;
};

export const TextEdit: React.FC = (props) => {
  useAuthenticated();
  return (
    <Edit {...props}>
      <TextEditForm />
    </Edit>
  );
};
