import { useAuthenticated } from "@s-graber/ra-rbac";
import React from "react";
import { Datagrid, List, EditButton, DateField, TextField } from "react-admin";
import { PositionChipField } from "../../components/fields/PositionChipField";

export const PositionList: React.FC = (props) => {
  useAuthenticated();
  return (
    <List sort={{ field: "id", order: "ASC" }} {...props}>
      <Datagrid {...props}>
        <PositionChipField source="name" />
        <DateField source="lastupdate" label="pos.fields.lastupdate" />
        <TextField source="updatedby" label="pos.fields.updatedby" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
