export default (content, filename) => {
  const fakeLink = document.createElement("a");
  fakeLink.style.display = "none";
  document.body.appendChild(fakeLink);
  const blob = new Blob([content], {
    type: `text/vcard; name="${filename}.vcf"`,
  });
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   // Manage IE11+ & Edge
  //   window.navigator.msSaveOrOpenBlob(blob, `${filename}.vcf`);
  // } else {
  fakeLink.setAttribute("href", URL.createObjectURL(blob));
  fakeLink.setAttribute("download", `${filename}.vcf`);
  fakeLink.click();
  // }
};
