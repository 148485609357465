/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { SVGProps } from "react";

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 1200 1200" {...props}>
    {" "}
    {/*  */}
    {/* <g
      aria-label="~SCPWeb~"
      //   style={{
      //     lineHeight: 1.25,
      //   }}
      //   fontWeight={400}
      //   fontSize={7.056}
      //   fontFamily="Permanent Marker"
      //   letterSpacing={0}
      //   wordSpacing={0}
      //   strokeWidth={0.265}
      //   fill="#fff"
    // > */}
    <path
      fillRule="evenodd"
      d="M1170.52,348.62l-2.3,15.52c-6.36,44.92-14.37,89.28-26.11,133.14
		c-18.78,70.17-45.68,137.9-79.66,202.11c-105.13,198.76-224.89,355.89-419.25,474.27L600.01,1200l-43.18-26.34
		c-194.36-118.4-314.14-275.51-419.29-474.29c-33.97-64.19-60.87-131.94-79.67-202.11c-11.75-43.88-19.76-88.24-26.11-133.14
		l-2.26-15.56c-6.32-40.08-7.28-81.45-7.63-121.95c-0.47-54.73,0.97-109.53,4.18-164.13L29.65,0H1170.3l3.63,62.36
		c3.28,54.66,4.67,109.48,4.2,164.23C1177.79,267.13,1176.83,308.52,1170.52,348.62"
    />
    <path
      fillRule="evenodd"
      fill="#FFFFFF"
      d="M1146.04,344.88l-2.31,15.71c-6.28,44.35-13.9,86.89-25.51,130.29
		c-18.32,68.41-44.5,134.33-77.63,196.94c-104.43,197.41-221.22,349.55-410.26,464.7l-30.31,18.5l-30.31-18.48
		c-189.05-115.19-305.86-267.31-410.28-464.75c-33.11-62.59-59.31-128.49-77.65-196.93c-11.62-43.4-19.26-85.95-25.53-130.28
		l-2.28-15.73c-6.04-38.16-7.02-79.84-7.35-118.46c-0.45-53.87,0.98-108.73,4.14-162.51L53,24.74h1093.95l2.28,39.09
		c3.21,53.76,4.61,108.74,4.15,162.56C1153.06,265.01,1152.07,306.71,1146.04,344.88"
    />
    <path
      fill="#080000"
      d="M473.78,938.06l19.23-5.19v76.84c0,2.49,0.21,4.39,0.64,5.69c0.4,1.31,1.51,3.11,3.28,5.4h-20.49
		c1.63-1.96,2.74-3.73,3.34-5.24c0.59-1.51,0.89-3.39,0.89-5.7v-5.32v-54.92c0-2.61-0.11-4.45-0.3-5.55
		c-0.21-1.12-0.71-2.17-1.49-3.2C478.09,939.82,476.38,938.89,473.78,938.06"
    />
    <path
      fill="#080000"
      d="M524.12,1020.65c3.33-0.94,6.03-1.89,8.05-2.88c2.04-1,4.15-2.38,6.34-4.13
		c12.93-10.83,19.38-24.65,19.38-41.53c0-9.88-1.63-17.77-4.9-23.65c-3.27-5.86-7.7-8.81-13.31-8.81c-4.46,0-8.01,1.9-10.66,5.71
		c-2.65,3.81-3.97,9-3.97,15.56c0,14.71,6.01,22.05,18.04,22.05c1.77,0,3.03-0.09,3.81-0.24c0.78-0.19,2.14-0.68,4.11-1.49
		c-3.74,5.64-9.02,8.45-15.79,8.45c-6.77,0-12.33-2.32-16.65-6.96c-4.33-4.66-6.49-10.6-6.49-17.81c0-8.47,2.77-15.64,8.28-21.5
		c5.53-5.85,12.35-8.77,20.49-8.77c9.07,0,16.35,3.3,21.82,9.92c5.47,6.63,8.22,15.41,8.22,26.35c0,14.69-4.91,27.36-14.7,38
		c-8.35,8.98-16.79,13.45-25.34,13.45c-1.66,0-2.84-0.12-3.51-0.3C526.66,1021.86,525.58,1021.39,524.12,1020.65"
    />
    <path
      fill="#080000"
      d="M601.31,951.7c-1.05,2.91-1.89,6.79-2.51,11.63c-0.63,4.86-0.94,9.83-0.94,14.94
		c0,4.7,0.32,9.41,0.94,14.17c0.63,4.73,1.51,8.97,2.66,12.74c1.15,3.74,3.3,6.87,6.44,9.37c3.14,2.49,6.7,3.76,10.67,3.76
		c3.88,0,7.4-1.24,10.6-3.68c3.18-2.45,5.46-5.78,6.83-9.94c2.19-6.77,3.28-15.58,3.28-26.42c0-5.41-0.25-10.32-0.77-14.69
		c-0.53-4.4-1.42-8.47-2.68-12.27c-1.25-3.81-3.49-7.01-6.67-9.55c-3.2-2.54-6.73-3.83-10.6-3.83c-3.87,0-7.38,1.24-10.52,3.75
		C604.91,944.19,602.66,947.53,601.31,951.7 M584.88,978.27c0-5.64,0.72-11.33,2.19-17.12c1.45-5.79,3.38-10.65,5.78-14.62
		c5.83-9.27,14.44-13.92,25.8-13.92c11.67,0,20.26,4.64,25.74,13.92c5.47,9.29,8.2,19.87,8.2,31.75c0,12-2.75,22.54-8.28,31.66
		c-5.53,9.13-14.07,13.7-25.65,13.7c-11.36,0-19.97-4.66-25.8-13.92c-2.4-3.96-4.33-8.77-5.78-14.39
		C585.6,989.68,584.88,984.01,584.88,978.27"
    />
    <path
      fill="#080000"
      d="M714.17,936.21c-3.54,0.93-6.28,1.87-8.2,2.81c-1.93,0.94-4.04,2.36-6.32,4.25
		c-12.84,10.95-19.26,24.85-19.26,41.66c0,9.83,1.67,17.69,4.99,23.6c3.32,5.88,7.73,8.84,13.23,8.84c4.35,0,7.88-1.94,10.58-5.81
		c2.7-3.86,4.05-8.99,4.05-15.37c0-14.75-6.11-22.14-18.34-22.14c-1.97,0-4.51,0.47-7.62,1.39c3.96-5.41,9.22-8.13,15.79-8.13
		c6.67,0,12.2,2.3,16.59,6.89c4.38,4.59,6.57,10.49,6.57,17.69c0,8.53-2.77,15.75-8.3,21.64c-5.53,5.9-12.35,8.84-20.49,8.84
		c-9.06,0-16.33-3.3-21.82-9.94c-5.47-6.6-8.2-15.45-8.2-26.48c0-14.71,4.9-27.42,14.7-38.17c8.13-8.76,16.58-13.13,25.34-13.13
		c1.66,0,2.76,0.06,3.29,0.15C711.24,934.91,712.39,935.37,714.17,936.21"
    />
    <path
      fill="#191915"
      d="M909.16,318.99c0.91,0,7.23,3.46,10.89,5.33c33.57,17.43,99.01,55.41,138.93,55.41
		c29.13,0,32.57-10.22,63.65-45.32h-11.46c-12.17-0.05-37.45-7.27-46.09-10.06c-14.77-4.76-29.3-11.25-43.89-16.83
		c-4.35-1.65-9.09-3.84-13.53-5.4c-17.47-6.08-34.78-12.44-51.97-18.52c-1.46-0.5-33.9-10.98-33.9-21.33c0-0.13,0.41-8.41,4.76-8.41
		c0.91,0,41.06,13.07,61.6,19.25c7.17,2.15,14.65,3.89,21.97,5.49c5.76,1.28,12.09,2.06,17.94,2.8c2.52,0.32,7.2,1,8.11,1
		c0,0,13.91,1.18,23.33,0c5.56-0.69,15.53-3.12,17.31-3.8c4.76-1.97,9.76-4.67,14.2-9.56c9.51-10.61,12.84-23.53,16.37-34.32
		c1.28-3.98,2.84-7.69,3.89-11.89c-11.93,2.65-13.26,3.6-22.28,3.6l-10.78-0.36c-53.32-5.76-107.1-13.89-159.83-35.98
		c-0.64-0.28-1.74-0.87-2.09-1.52c5.24-8.55,12.48-10.07,18.84-10.07l45.73,4.76h14.23c13.03,0,43.53-7.58,48.98-24.64
		c0.14-0.45,2.38-6.51,3.2-11.08c2.8-11.51,0-17.23,0-17.23c-4-2.81-8.36-3.39-12.06-3.39c-6.08,0-16.24,3.3-22.32,3.3l-18.46-0.74
		c-52.32-4.53-104.64-21.36-156.96-25.61c0,0-27.21-1.97-43.55-1.1c-12.73,0.73-34.58,3.34-34.58,22.13
		c0,30.19,27.8,15.64,27.8,47.75c0,10.7-12.56,55.46-13.3,57.56c-1.88,5.26-4.65,12.59-7.67,17.9
		c-5.12,9.02-13.51,19.91-21.33,28.51c-5.54,6.08-25.11,23.35-33.62,23.35c-27.62,0-52.59-39.38-53.04-62.39
		c-9.46-3.81-18.97-7.11-28.24-10.78L600,268.5l-32.15-40.24c-7.08,3.31-14.39,6.6-21.31,9.94c-7.17,23.71-26.02,63.96-55.57,63.96
		c-24.65,0-44.98-31.34-59.03-53.7c-3.2-5.12-5.6-11.21-7.29-16.79c-4.16-13.72-6.21-29.38-8.27-43.14
		c-0.46-3.3-0.96-8.94-0.96-9.86c0-23.46,27.81-28.54,27.81-50.67c0-15.19-19.87-18.84-30.16-20.62c-2.93-0.51-8.31-1.33-9.22-1.33
		c0,0-17.84-1.85-34.66,0c-0.91,0.1-9.63,1.1-13.97,1.69c-25.53,3.48-52.84,9.2-78.32,15c-22.95,5.26-47.64,9.52-70.58,11.85
		l-10.97,0.36c-0.91,0-26.34-2.56-27.26-2.56c-4.48,0-7.92-0.39-12.79,2.65c0,0-1.81,5.87,0,18.06c1.32,7.85,6.47,17.3,12.88,21.92
		c14.5,10.38,30.74,12.97,45.66,12.97l54.88-4.75c5.99,0,14.04,0.8,15.38,10.34c-2.49,1.39-4.97,2.65-7.21,3.43
		c-42.54,14.81-97.56,24.72-149.23,32.32c-15.3,2.25-42.21-1.75-42.21-1.75c20.57,45.64,34.91,59.92,67.84,59.92
		c33.93,0,91.61-21.09,119.92-28.92c1.74,4.29,3.94,8.31,5.4,12.2c-62.74,24.01-126.03,63.15-188.27,67.95l-12.96,0.47
		c22.62,31.29,39.82,45.26,64.56,45.26c37.54,0,141.82-60.73,150.56-60.73c4.48,0,4,6.13,5.49,8.57
		c-5.06,4.65-9.81,9.18-14.94,13.65c-15.27,13.36-30.59,27.07-45,39.51c-12.08,10.47-26.26,22.26-38.41,32.16
		c-5.3,4.32-10.56,8.56-15.73,12.7c-8.42,6.77-17.19,13.49-25.97,19.76c-8.73,6.22-17.4,14.26-26.32,18.78
		c-5.26,2.69-12.45,3.36-18,3.5c6.63,12.75,26.52,29.52,44.77,34.42c1.37,0.37,6.29,1.48,9.49,1.27
		c22.16-1.48,44.98-15.56,65.29-30.47c12.48-9.19,25.03-21.46,36.96-31.98c9.14-8.05,22.43-21.15,32.49-31.02
		c8.05-7.88,16.13-15.63,24.06-22.44c3.03,7.44,10.01,15.58,15.96,22.5c-0.74,0.76-1.48,1.51-2.22,2.28h-0.01
		c-52.62,53.64-105.1,94.8-157.96,136.11c-10.74,8.42-21.95,15.87-32.99,24.04c11.6,15.59,46.69,25.83,66.04,25.83
		c70.98,0,154.63-128.43,176.72-128.43c6.13,0,8.2,2.83,14.82,8.61c-3.62,4.26-6.96,8.33-10.12,12.31
		c-23.27,29.55-85.22,92.73-132.06,133.25c-15.13,13.08-30.68,26.88-45.82,39.14c-3.75,3.03-7.33,6.14-10.32,9.21
		c16.76,14.58,34.18,24.96,50.28,27.71c3.66,0.59,14.57,3.58,27.42,2.11c12.09-1.39,20.33-5.13,24.99-6.87
		c15.96-5.99,46.22-29.14,65.42-49.53c19.9-21.13,39.11-45.15,55.63-66.24c13.44-17.14,26.4-37.05,45.83-50.22
		c1.56-1.05,6.94-4.22,9.23-4.22c5.85,0,6.22,10.11,6.22,12.81c0,42.08-75.58,124.73-108.66,139.13h82.76l20.22,47.11
		c7.8-13.55,13.9-27.55,19.43-40.36c11.34-26.12,21.55-53.27,48.95-78.19c0.61,1.72,0.6,3.51,1.14,5.33
		c1.96,18.05,1.74,27.74,0,45.33c-7.13,52.31-28.69,122.67-64.54,176.85c30.91-13.72,70.22-41.09,92.36-76.26
		c9.38-14.86,14.84-31.04,16.36-45.67c0.14-1.42,1.24-11.33,1.24-11.33c0-1.52,0.85-3.33,2.69-3.5c0.91-0.09,2.16,0.46,2.43,1.33
		c1.51,4.61,2.11,9.54,3.3,14.26c2.65,10.47,6.92,22.86,12.31,33.33c7,13.58,16.82,28.44,28.94,40.88
		c7.46,7.64,17.12,15.94,24.17,21.25c14.04,10.52,30.04,17.13,44.43,23.16c-10.64-17.32-18.96-36.34-27.92-53.17
		c-5.4-10.2-10.7-21.12-15.37-31.54c-2.6-5.85-5.75-11.6-7.44-17.54c-4.48-15.6-8.41-31.46-11.7-46.82
		c-1.51-7.18-7.34-35.24-8.61-51.44c0,0-1.45-8.88-0.85-16.29c0.43-5.24,2.2-7.24,2.6-7.78c18.89,24.08,29.62,51.2,40.54,75.63
		c5.95,13.31,12.4,27.55,20.22,41.45l19.49-49.79l82.38-1.19c-49.5-37.88-105.36-90.75-105.36-139.45c0-4.85-0.05-12.99,8.23-12.99
		c0.91,0,2.7-0.05,3.53,0.77c18.16,17.75,30.95,36.45,45.81,54.64c41.85,51.18,101.44,122.77,155,122.77
		c20.12,0,52.92-9.39,69.51-30.57C924.24,613.43,850.4,551.85,793,480.53c5.07-5.46,11.57-11.16,16.65-11.16
		c7.27,0,22.45,17.41,26.67,21.76c30.69,31.78,67.28,73.21,101.31,90.63c9.38,4.8,18.32,9.43,27.47,12.09
		c2.48,0.74,13.71,3.44,19.13,3.94c8.23,0.77,14.18,0.13,14.18,0.13c13.72,0,47.87-11.58,54.37-28.55
		c-34.43-18.21-98.47-66.75-144.2-107.42c-4.82-4.27-36.04-31.17-45.74-45.01h-0.01c-1.81-2.57-2.85-4.68-2.85-6.12
		c0-5.76,10.91-16.88,15.56-21.22c5.01,3.62,10.34,7.73,14.87,11.75c5.35,4.76,16.41,15.59,16.41,15.59
		c22.58,20.49,78.86,86.45,127.68,86.45c16.19,0,45.16-12.73,54.49-33.18c-15.38-5.59-31.39-14.95-45.79-24.74
		c-12.04-8.2-24.57-18.09-37.16-28.53h-0.01c-19.23-15.92-90.95-72.52-100.14-81.35c-2.52-2.38-4.21-3.66-4.21-5.99
		c0-0.91,0.31-2.34,0.81-3.39C903.24,324.75,907.97,318.99,909.16,318.99"
    />
    <path
      fill="#191915"
      d="M438.56,687.73c-3.73,6.01-7.38,11.9-10.95,17.07c-4.94,7.18-27.38,45.56-44.03,45.56
		c-11.35,0-46.33-21.22-54.7-21.22c-10.47,0-22.76,20.52-24.96,26.46c-2.42,6.54-5.95,18.91-5.95,26.23c0,4.75,0.75,9.04,6.08,13.53
		c6.71-17.86,17.51-30.73,32.88-30.73c7.46,0,14.24,2.68,22.24,6.66c2.56,1.28,3.56,5.59,3.56,7.25c0,11.57-13.54,27.21-13.54,48.66
		c0,0.91,0.74,7.16,1.35,10.51l0.65,0.47c6.9-2.99,16.21-7.33,22.33-14.33c7.28-8.28,10.9-18.96,16.71-27.83
		c3.07-4.71,7.44-7.59,11.64-7.59c0.91,0,5.7,0.99,8.54,2.05c15.18,5.57,23.29,15.56,23.29,27.95c0,4.43-1.03,9.29-3.61,14.2
		c12.46-4.71,32.52-25.41,32.52-37.63c0-13.58-38.78-21.59-38.78-28.36c0-0.91,13.69-20.34,20.68-30.63
		c6.96-10.25,15.43-21.82,26.51-31.16l-7.4-17.12H438.56z"
    />
    <path
      fill="#191915"
      d="M612.7,740.6c-2.15-5.4-4.11-11.32-5.76-16.94c-2.13,10.3-5.55,22.05-11.4,32.29
		c-2.97,5.21-6.84,12.6-10.95,17.54c-1.06,1.24-3.43,3.96-3.43,4.88c0,0.91,3.17,6.96,4.27,9.94c4.75,13.07,7.46,26.46,15.01,39.54
		c1.01,1.74,6.51,8.88,9.26,8.88c0.91,0,2.3-1.87,2.99-3.01c9.57-15.92,13.1-32.64,17.94-47.97c0.83-2.61,2.84-7.01,2.84-7.92
		c0-0.92-4.96-7.19-7.25-10.9C620.87,758.22,616.17,749.42,612.7,740.6"
    />
    <path
      fill="#191915"
      d="M873.68,729.14c-8.24,0-43.64,21.22-54.33,21.22c-0.91,0-2.4,0.24-3.41-0.12
		c-20.58-7.96-38.74-42.66-54.49-66.4h-25.07l-6.29,16.61c5.9,6.31,11.14,12.47,15.99,18.73c3.88,5.03,7.9,10.6,11.33,15.72
		c7.13,10.57,21.33,31,21.33,31.91c0,6.22-39.15,15.01-39.15,27.81c0,12.35,19.83,32.55,32.88,37.99
		c-1.94-3.86-3.98-10.14-3.98-14.39c0-12.71,9.34-22.33,24.76-28.14c2.52-0.91,6.71-1.68,7.62-1.68c5.9,0,10.36,5.03,14.2,11.62
		c2.38,4.03,5.29,7.79,6.71,11.68c4.17,11.07,18.86,22.85,30.03,26.45c1.37-3.62,1.2-7.81,1.2-10.78
		c0-19.57-13.17-39.93-13.17-48.84c0-1.79,0.21-6.01,3.91-7.61c9.33-3.98,13.51-6.29,21.88-6.29c15.92,0,25.08,13.29,32.88,30.72
		c4.13-2.67,6.09-9.14,6.09-13.16c0-9.15-4.23-23.32-8.58-31.73C892.59,743.87,882.18,729.14,873.68,729.14"
    />
    <path
      fill="#191915"
      d="M510.91,127.28c5.07,0,9.98,2.62,12.54,9.76c0.37,1,1,2.31,1,3.23c0,1.32-1.05,3.39-2.42,3.7
		c-2.84,0.64-6.01,0.55-8.43,1.33c-5.95,2.01-10.28,3.92-15.86,3.92c-0.91,0-6.39-0.73-9.48-1.04c1.11,3.83,10.52,8.72,13.36,8.72
		c0,0,7.52,1.51,13.68-0.16c0.87-0.24,1.32,0.5,0.38,1.73c-4.37,4.92-7.61,6.48-11.54,6.48c-0.91,0-9.84-2.19-14.75-3.25
		c0.71,10.72,21.75,16.44,28.84,16.43c10.16-0.03,18.57-9.15,27.44-9.15c8.04,0,9.51,5.53,9.51,12.07c0,10.34-8.41,25.5-11.97,34.87
		c-2.51,6.59-5.21,13.53-8.1,20.32l34.85-15.68l30.05,38.06l27.85-39.52l41.05,16.05c-2.75-6.33-13.12-23.68-13.12-31.77
		c0-4.53,2.49-13.36,4.64-17.58c2.74,1.19,7.35,2.74,10.18,2.94c2.76,0.19,3.61-0.87,4.57-1.61c-9.65-10.36-15.6-22.14-16.47-32.25
		c-0.41-5.08-0.95-5.72-0.41-10.85c0.14-1.51,1.23-4.52,1.37-5.58c4.95-12.5,17.05-18.87,23.18-24.61c0,0-16.07,0.68-20.28,0.25
		c-6.06-0.4-11.89-1.69-17.15-3.56c-4.98-1.74-10.42-4.13-15.31-7.88c-14.37-11.02-29-17.87-42.54-19.43
		c-3.65-0.41-7.32-1.05-10.98-1.32c-5.85-0.41-8.42-0.37-9.34-0.37c-0.91,0-4.95,0.37-7.15,0.46c-8.6,0.36-19.24,8.7-27.56,17.75
		c-1.84,1.97-3.83,1.24-6.1,1.18c-6.03,0.06-17.56,0.73-17.56,0.73c-16.65,1.24-30.91,11.43-30.91,26.9c0,4.84,1.16,8.08,3.43,12.98
		C491.35,134.19,498.11,127.28,510.91,127.28 M546.99,108.26c-0.23,7.91,6.95,11.34,12.03,11.34c5.03,0,9.25-2.21,11.6-9.12
		c4.41,0.1,10.32-0.02,14.79-1.85c0.9,1.13,1.24,2.46,1.24,4.02c0,10.61-16.79,12.07-20.68,12.07c-4.39,0-20.3-1.5-20.3-11.89
		C545.67,111.91,546.06,109.82,546.99,108.26"
    />
    <path
      fill="#FFFFFF"
      d="M503.28,486.86c-7.05,8.51-16.55,18.7-25.38,24.42c-14.96,9.69-19.85,11.14-33.02,11.14
		c-14.32,0-25.45-3.92-41.53-16.28c26.45-23.88,57.19-53.05,70.99-79.35l-16.41-9.84l-12.65-7.59c-2.14,2.61-4.3,5.13-6.47,7.57
		h-0.02c-19.2,21.39-39.57,35.24-60.32,35.24c-10.92,0-30.27-2.85-38.78-13c7.51-7.37,15.37-14.72,23.34-22.21h0.02
		c14.22-13.34,28.82-27.1,42.5-42.18l-21.43-25.43c-15.11,9.03-37.84,23.79-53.93,23.79c-9.6,0-30.35-14.65-34.73-25.16
		c4.16-0.45,8.77-0.4,12.52-2.04c7.27-3.24,13.32-9.13,19.73-13.66c10.52-7.4,20.85-15.09,31.39-22.67l-8.47-16.98
		c-13.48,8.3-26.11,16.11-40.01,16.11c-16.24,0-24.87-7.88-42.25-25.85c7.15-1.27,15.26-1.85,22.81-4.28
		c5.44-1.74,11.1-3.7,16.45-5.85c8.37-3.38,29.52-12.9,29.52-14.51c0-0.91-4.02-12.21-5.44-18c0,0,0.32-0.3-0.6-0.3
		c-2.33,0-15,6.96-30,6.96c-18.34,0-26.41-9.37-41.51-33.55c4.16-0.84,7.96-0.91,11.99-1.28l10.84-1.02
		c12.7-2.42,27.07-6.44,39.74-11.62c-2.04-10.22-3.5-21.62-4.4-32.4c-0.33-0.31-0.93-0.26-4.59-0.26
		c-9.02-0.37-25.1-3.93-28.85-16.97c-0.51-1.82-0.93-3.69-1.1-5.52c-0.18-1.83-0.96-5.14-0.96-6.06c0-4.94,12.94-5.85,14.5-5.85
		h9.82l57.8-9.12h2.72v34.87c0.05,6.63,2.65,19.17,3.57,24.53c3.57,19.99,9.09,41.68,17.41,61.79
		c7.37,17.74,18.04,37.51,31.45,55.21c16.92,22.32,43.53,45.91,64.98,57.85c6.35,3.52,12.8,7.59,19.28,10.95v82.77
		c0.46,6.94,1.25,12.1,2.12,17.9c0.55,3.66,2.47,10.64,2.47,11.56C508.35,481.63,504.88,484.95,503.28,486.86"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="0.204"
      strokeMiterlimit="10"
      d="M503.28,486.86
		c-7.05,8.51-16.55,18.7-25.38,24.42c-14.96,9.69-19.85,11.14-33.02,11.14c-14.32,0-25.45-3.92-41.53-16.28
		c26.45-23.88,57.19-53.05,70.99-79.35l-16.41-9.84l-12.65-7.59c-2.14,2.61-4.3,5.13-6.47,7.57h-0.02
		c-19.2,21.39-39.57,35.24-60.32,35.24c-10.92,0-30.27-2.85-38.78-13c7.51-7.37,15.37-14.72,23.34-22.21h0.02
		c14.22-13.34,28.82-27.1,42.5-42.18l-21.43-25.43c-15.11,9.03-37.84,23.79-53.93,23.79c-9.6,0-30.35-14.65-34.73-25.16
		c4.16-0.45,8.77-0.4,12.52-2.04c7.27-3.24,13.32-9.13,19.73-13.66c10.52-7.4,20.85-15.09,31.39-22.67l-8.47-16.98
		c-13.48,8.3-26.11,16.11-40.01,16.11c-16.24,0-24.87-7.88-42.25-25.85c7.15-1.27,15.26-1.85,22.81-4.28
		c5.44-1.74,11.1-3.7,16.45-5.85c8.37-3.38,29.52-12.9,29.52-14.51c0-0.91-4.02-12.21-5.44-18c0,0,0.32-0.3-0.6-0.3
		c-2.33,0-15,6.96-30,6.96c-18.34,0-26.41-9.37-41.51-33.55c4.16-0.84,7.96-0.91,11.99-1.28l10.84-1.02
		c12.7-2.42,27.07-6.44,39.74-11.62c-2.04-10.22-3.5-21.62-4.4-32.4c-0.33-0.31-0.93-0.26-4.59-0.26
		c-9.02-0.37-25.1-3.93-28.85-16.97c-0.51-1.82-0.93-3.69-1.1-5.52c-0.18-1.83-0.96-5.14-0.96-6.06c0-4.94,12.94-5.85,14.5-5.85
		h9.82l57.8-9.12h2.72v34.87c0.05,6.63,2.65,19.17,3.57,24.53c3.57,19.99,9.09,41.68,17.41,61.79
		c7.37,17.74,18.04,37.51,31.45,55.21c16.92,22.32,43.53,45.91,64.98,57.85c6.35,3.52,12.8,7.59,19.28,10.95v82.77
		c0.46,6.94,1.25,12.1,2.12,17.9c0.55,3.66,2.47,10.64,2.47,11.56C508.35,481.63,504.88,484.95,503.28,486.86z"
    />
    <path
      fill="#FFFFFF"
      d="M891.7,345.95c3.52,2.07,8.34,1.58,12.5,2.03c-4.29,10.51-25.27,25.16-34.55,25.16
		c-16.29,0-39.2-14.76-53.76-23.78l-21.8,25.42c13.85,13.81,58.32,57.05,65.86,64.02c-8.41,10.6-27.98,13.37-38.59,13.37
		c-20.91,0-40.67-13.77-60.3-35.23h-0.01c-2.23-2.43-4.45-4.96-6.67-7.58L725.3,426.8c13.75,26.3,44.49,55.47,70.99,79.35
		c-16.59,11.76-26.07,16.27-41.53,16.27c-19.48,0-32.88-8.7-53.69-30.79c-3.07-3.29-9.79-9.82-9.79-10.74
		c0-1.65,1.84-5.29,2.07-6.62c1.46-7.27,1.96-14.98,2.69-22.66l0.32-34.66l0.45-48.85c35.15-15.61,78.74-55.24,98.5-91.46
		c1.6-2.93,3.66-6.22,5.49-9.42c10.25-17.7,17.04-36.36,22.07-54.57c0.18-0.63,9.64-41.38,9.69-53.69v-35.67
		c0,0,56.93,8.78,60.52,9.11c3.56,0.52,10.55,0,10.55,0c4.3,0,10.77,1.41,14.48,4.5c-0.35,2.65-1.23,5.71-1.23,8.27
		c0.18,15.32-19.84,21.61-29.72,21.59h-4.95c0,0-4.62,32.93-4.27,33.15c12.47,4.43,26.59,9,39.66,11.14l10.28,1.26
		c4.03,0.37,8.03,0.21,12.19,1.04c-13.22,22.99-22.76,33.55-41.51,33.55c-10.57,0-18.36-2.71-29.93-6.97
		c-2.11,6.12-4.29,12.69-6.13,18.79c22.28,11.52,45.67,22.44,68.79,24.15c-16.27,17.98-26.56,25.86-42.24,25.86
		c-13.26,0-26.11-7.8-39.83-16.1l-8.65,16.61C857,321.96,873.9,335.44,891.7,345.95"
    />
    <path
      fill="#191915"
      d="M922.15,286.19c-34.08-5.47-68.46-27.4-101.96-46.21c-2.39,6.97-6.14,15.5-9.62,22.54
		c15.87,8.66,57.71,41.84,78.66,41.84C900.47,304.36,910.53,299.18,922.15,286.19"
    />
    <path
      fill="#191915"
      d="M801.78,278.96c10.71,9.94,22.48,18.73,33.89,26.86l7.81-16.7l-34.98-22.81L801.78,278.96z"
    />
    <path
      fill="#191915"
      d="M777.63,312.6c20.69,17.69,65.54,55.79,91.65,55.79c7.36,0,22.05-10.09,27.24-16.72
		c-3.08-0.48-6.65-1-9.2-2.78c-30.33-20.94-58.81-40.92-88.31-65.75C792.34,292.96,785.82,303.23,777.63,312.6"
    />
    <path
      fill="#191915"
      d="M912.36,143.58l0.63-4.88c-1.59-1.47-4.42-1.52-5.33-1.54c-3.35-0.06-6.12,0.58-12.01,0l-56.4-8.37v29.17
		l45.04,4.08C894.57,162.03,910.26,160.28,912.36,143.58"
    />
    <path
      fill="#191915"
      d="M862.5,237.51l-35.73-16.19c-1.56,4.87-3.02,9.61-4.86,14.49l35,19.87L862.5,237.51z"
    />
    <polygon
      fill="#191915"
      points="875.64,166.13 838.43,162.8 835.46,183.16 871.98,197.83 	"
    />
    <path
      fill="#191915"
      d="M932.75,216.66c-1.59-0.48-6.46-0.06-12.58-0.85c-28.5-3.79-57.35-16.45-85.36-28.16l-6.66,29.15
		c15.87,6.95,50.84,25.72,70.42,25.72C913.23,242.53,921.39,235.63,932.75,216.66"
    />
    <path
      fill="#191915"
      d="M722.38,361.24c8.27,8.21,24.43,34.29,44.72,55.71h0.01c16.01,16.87,34.58,30.83,53.87,30.83
		c9.24,0,25.68-2.51,32.75-8.6c-7.65-7.2-64.46-57.94-99.32-102.63C744.21,346.26,731.61,353.79,722.38,361.24"
    />
    <path
      fill="#191915"
      d="M714.92,416.95c-4.46-8.71-8.23-17.45-11.4-26.24l-0.55,26.24l-0.71,34.67
		c-0.73,7.67-1.27,15.38-2.69,23.02c-0.37,2.19-1.69,5.71-1.69,6.62c0,1.23,20.79,21.45,21.29,21.82c1.78,1.37,3.53,3.11,5.49,4.53
		c5.95,4.25,14.03,8.49,20.2,9.54c3.21,0.55,9,0.88,9.91,0.88c11.94,0,21.69-3.47,34.2-12.62c-5.17-4.91-9.8-9.53-14.83-13.96
		C746.62,467.11,727.85,442.2,714.92,416.95"
    />
    <path
      fill="#191915"
      d="M703.12,372.12c-0.13,0.43-0.13,0.66-0.13,1.58c0,3.75,8.16,24.4,8.89,26.04
		c2.48,5.65,5.43,11.48,8.26,17.21v0.01c0.67,1.38,1.34,2.74,2,4.1c2.61-1.1,5.37-2.5,8.18-4.1h0.03
		c6.66-3.75,13.65-8.47,19.84-12.51l-31.98-41.19C712.96,366.58,707.85,369.5,703.12,372.12"
    />
    <polygon
      fill="#191915"
      points="757.87,333.44 789.89,370.22 810.93,345.5 774.51,316.07 	"
    />
    <path
      fill="#191915"
      d="M497.74,451.61l-0.71-34.67l-0.54-26.23c-3.07,8.78-6.83,17.52-11.34,26.23h-0.01
		c-13.12,25.3-32.57,50.22-59.69,74.51c-5.35,4.8-10.34,9.8-14.79,14.33c12.61,8.73,22.18,12.24,34.39,12.24
		c10.7,0,20.1-3.52,31.39-11.66c4.94-3.57,13.19-11.08,17.58-15.48c2.93-2.93,8.11-8.73,8.11-9.64c0-0.92-1.57-6.27-2.11-9.18
		C498.6,464.4,498.25,459.95,497.74,451.61"
    />
    <path
      fill="#191915"
      d="M400.62,283.14c-17.39,15.6-35.81,27.48-53.46,40.74c-11.66,8.78-23.9,18.01-36.3,25.74
		c-2.28,1.42-5.05,1.58-7.76,2.06c5.2,6.63,19.88,16.71,27.24,16.71c26.57,0,74.13-39.98,91.65-56.16
		C413.41,302.45,406.29,291.73,400.62,283.14"
    />
    <polygon
      fill="#191915"
      points="364.8,305.8 398.22,278.97 391.5,266.31 356.15,289.49 363.9,305.8 	"
    />
    <path
      fill="#191915"
      d="M328.06,197.5l36.11-14.34c-1.08-6.7-2.17-13.64-2.6-20.35l-37.83,3.54L328.06,197.5z"
    />
    <polygon
      fill="#191915"
      points="342.72,255.68 378.09,235.46 373.23,221.32 337.14,237.51 	"
    />
    <path
      fill="#191915"
      d="M379.81,239.97c-33.4,18.95-68.24,40.47-101.58,46.21c11.79,12.9,20.71,18.17,32.19,18.17
		c22.36,0,63.38-33.78,79.02-41.83L379.81,239.97z"
    />
    <path
      fill="#191915"
      d="M371.88,216.37c0-0.92-1.56-5.95-2.34-8.74c-1.74-6.27-3.15-13.26-4.71-19.97
		c-17.36,7.96-34.63,13.99-51.73,19.84c-10.56,3.61-22.73,7.45-33.25,8.31c-7.63,0.59-10.26,0.37-12.96,0.84
		c11.95,18.56,20.11,25.87,34.2,25.87c9.51,0,20.65-3.62,30.07-7.47c2.19-0.86,4.29-2.01,6.21-2.69
		C346.88,228.89,371.88,217.55,371.88,216.37"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="0.204"
      strokeMiterlimit="10"
      d="M371.88,216.37c0-0.92-1.56-5.95-2.34-8.74
		c-1.74-6.27-3.15-13.26-4.71-19.97c-17.36,7.96-34.63,13.99-51.73,19.84c-10.56,3.61-22.73,7.45-33.25,8.31
		c-7.63,0.59-10.26,0.37-12.96,0.84c11.95,18.56,20.11,25.87,34.2,25.87c9.51,0,20.65-3.62,30.07-7.47
		c2.19-0.86,4.29-2.01,6.21-2.69C346.88,228.89,371.88,217.55,371.88,216.37z"
    />
    <path
      fill="#191915"
      d="M360.62,128.78l-56.25,8.37h-11.66c-0.91,0-4.88,0.31-5.7,1.54c-0.25,1.93,0.32,4.57,0.93,6.96
		c3.95,15.46,18.12,16.38,27.59,16.38l45.09-4.08V128.78z"
    />
    <path
      fill="#191915"
      d="M477.98,360.87c-10.98-6.27-22.33-14.71-32.76-24.32c-22.06,26.73-48.59,54.73-75.18,80.38h-0.02
		c-7.95,7.66-15.91,15.11-23.76,22.25c5.95,6.08,24.24,8.6,32.56,8.6c19.04,0,37.12-13.26,53.75-30.83h0.01
		c15-15.87,28.82-35.24,41.06-51.56C474.79,363.83,476.45,362.13,477.98,360.87"
    />
    <path
      fill="#191915"
      d="M481.33,414.02c5.77-11.98,15.68-33.56,15.68-40.33c0-0.91-0.07-1.61-0.45-1.89
		c-4.77-2.66-9.88-5.59-14.77-8.53l-32.34,41.19l17.2,10.56l2.87,1.75l0.28,0.17l7.75,4.76c0.27-0.53,1.33-2.7,2.35-4.77h0.01
		c0.41-0.85,0.87-1.8,1.36-2.8C481.28,414.1,481.3,414.05,481.33,414.02"
    />
    <path
      fill="#191915"
      d="M441.76,333.43l-16.63-17.37l-36.05,29.44l20.67,24.71C420.36,358.5,433.03,345.17,441.76,333.43"
    />
    <path
      fill="#FFFFFF"
      d="M675.16,416.95v28.77c-0.28,15.28-4.87,30.88-5.24,32.29c-1.19,4.4-3.2,9.06-4.52,13.54
		c-6.91,22.87-33.82,55.18-58.75,64.24c-2.19,0.78-5.73,2.48-6.64,2.48c-5.07,0-22.6-11.01-24.98-12.6
		c-2.93-1.97-6.06-4.54-8.8-6.97c-5.3-4.71-11.73-11.6-15.67-16.86c-15.28-20.26-21.8-40.89-24.46-61.66
		c-0.64-5.08-1.52-11.23-1.65-16.56V321.97h150.71V416.95L675.16,416.95z"
    />
    <path
      fill="#191915"
      d="M622.33,416.95L622.33,416.95c-3.11,4.84-7.77,8.83-14.59,10.48c-1.84,0.46-5.04,0.6-5.95,0.57h-25.33
		v-65.07h22.77c0.91-0.03,3.01,0.03,4.42,0.17c19.95,2.15,24.35,19.69,24.35,32.55C628,401.69,626.68,410.15,622.33,416.95
		 M657.62,387.64c-0.03-0.91-1.2-8.19-2.11-11.8c-0.82-3.25-2.16-6.94-3.67-10.27c-7.86-17.43-27.79-24.99-40.92-24.97h-64.47v164.2
		h30.07v-54.49h34.4c0.92,0.03,8.16-0.86,12.14-2.18c1.46-0.46,5.02-1.76,7.13-2.8c12.29-5.72,20.69-18.05,24.33-28.39v-0.01
		c0.82-2.35,1.39-4.59,1.72-6.63c0.62-3.93,0.97-5.69,1.21-9.19C657.72,397.12,657.81,392.81,657.62,387.64"
    />
    {/*  </g> */}
  </svg>
);

export default Logo;
