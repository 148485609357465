import { Box } from "@mui/material";
import { Datagrid, FormTab, ReferenceField, ReferenceManyField, SelectInput, TextField } from "react-admin"
import { useFormContext, useWatch } from "react-hook-form";

const getCurrentCalendarWeek = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((currentDate.getDay() + 1 + days) / 7);
    return weekNumber;
};

const getDayOfGivenWeek = (dayNo, weekNumber, year) => {
    const firstDayOfYear = new Date(year, 0, 1);
    const daysOffset = (weekNumber - 1) * 7;
    const firstDayOfWeek = new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() + daysOffset));
    const dayOfWeek = firstDayOfWeek.getDay();
    const firstDay = firstDayOfWeek.getDate() - dayOfWeek + dayNo; // Adjust if week starts on Monday
    return new Date(firstDayOfWeek.setDate(firstDay));
};

export const TeamsDetailsWeeklyStatsTab = (props) => {
    // const { getValues } = useFormContext();
    // var { calendarWeek } = getValues();
    const calendarWeekDefaultValue = getCurrentCalendarWeek();
    const calendarWeek = useWatch({
        name: "calendarWeek",
        defaultValue: calendarWeekDefaultValue,
        exact: false,
    });
    const measure = useWatch({
        name: "measure",
        defaultValue: "teams_stats_weekly_rpe",
        exact: false,
    });
    const calendarWeeksChoices = [];
    for (let i = 1; i <= 52; i += 1) {
        const first = getDayOfGivenWeek(1, i, new Date().getFullYear());
        const last = getDayOfGivenWeek(7, i, new Date().getFullYear());
        calendarWeeksChoices.push({
            id: `${i}`,
            name: `KW ${i} (${first.toLocaleDateString()}-${last.toLocaleDateString()})`,
            value: i,
        });
    }
    const measures = [
        'teams_stats_weekly_rpe',
        'teams_stats_weekly_sleep_duration',
        'teams_stats_weekly_sleep_quality',
        'teams_stats_weekly_sore_muscles',
        'teams_stats_weekly_exhaustion',
        'teams_stats_weekly_mentally'
    ];
    const measuresChoices = measures.map((measure) => ({
        id: measure,
        name: `resources.teams.measures.${measure}`,
        value: measure,
    }));
    return (
        <FormTab
            {...props}
            label="resources.teams.tabs.weekly_stats"
            path="weekly_stats"
        >
            <Box display="flex" flexDirection="row">
                <SelectInput
                    label="resources.teams.fields.calendarWeek"
                    source="calendarWeek"
                    choices={calendarWeeksChoices}
                    defaultValue={calendarWeekDefaultValue}
                />
                <SelectInput
                    label="resources.teams.fields.measure"
                    source="measure"
                    choices={measuresChoices}
                    sx={{ marginLeft: 1 }}
                    defaultValue={measures[0]}
                />
            </Box>
            <ReferenceManyField
                sort={{ field: "id", order: "ASC" }}
                perPage={50}
                reference={measure}
                source="id"
                target="team_id"
                filter={{ week: calendarWeek }}
            >
                <Datagrid>
                    <ReferenceField
                        label="resources.matches.fields.player_id"
                        source="player_id"
                        reference="players"
                        link={(record, reference) => `/${reference}/${record.player_id}/2`}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="week" label="Kalenderwoche" />
                    <TextField source="monday" label="Montag" />
                    <TextField source="tuesday" label="Dienstag" />
                    <TextField source="wednesday" label="Mittwoch" />
                    <TextField source="thursday" label="Donnerstag" />
                    <TextField source="friday" label="Freitag" />
                    <TextField source="saturday" label="Samstag" />
                    <TextField source="sunday" label="Sonntag" />
                </Datagrid>
            </ReferenceManyField>
        </FormTab>
    )
}